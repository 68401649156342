import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Input, Space, Subhead, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";

function ButtonSnippet({ thumbnail = false }) {
  const [width, setWidth] = useState('hug');
  const [variant, setVariant] = useState('Primary');
  const [text, setText] = useState('Action');
  const [showText, setShowText] = useState(true);
  const [loading, setLoading] = useState(false);
  const [small, setSmall] = useState(false);
  const [iconLayout, setIconLayout] = useState('leading');
  const [showIcon, setShowIcon] = useState(true);
  const [stacked, setStacked] = useState(false);
  const [fun, setFun] = useState('function');
  const [target, setTarget] = useState('Default');
  const [children, setChildren] = useState('');
  const [iconColorCustom, setIconColorCustom] = useState(undefined);
  const [ariaLabel, setAriaLabel] = useState(undefined);
  const [arialoadingLabel, setAriaLoadingLabel] = useState(undefined);

  const Design = (
    <>
      <Selector
        label='Variant'
        textColor={null}
        prop={variant} setProp={setVariant}
        options={['Primary', 'Accent', 'Secondary', 'Tertiary', 'Destructive', 'Read Only']} />
      <Divider />
      <SwitchRow
        label='Small'
        textColor={null}
        prop={small} setProp={setSmall} />
      <Divider />
      <ChipRow
        label='Width'
        textColor={null}
        prop={width} setProp={setWidth}
        options={['Hug', 'Fill']} />
    </>
  );

  const Content = (
    <>
      <SwitchRow
        label='Text'
        textColor={null}
        prop={showText} setProp={setShowText}
        showDivider={false}
      />
      {showText &&
        <Container variant="fill">
          <Input placeholder='Enter text' onChange={(e) => setText(e.target.value)} value={text} />
        </Container>
      }
      <Divider />
      <SwitchRow
        textColor={null}
        label='Icon'
        showDivider={false}
        prop={showIcon} setProp={setShowIcon} />
      <Divider />
      {showIcon && showText &&
        <>
          <ChipRow
            label='Placement'
            prop={iconLayout} setProp={setIconLayout}
            showDivider={false}
            options={['Leading', 'Trailing']}
          />
          <Divider />
          <SwitchRow
            label='Stacked'
            showDivider={false}
            prop={stacked} setProp={setStacked} />
        </>
      }
    </>
  );


  const Technical = (
    <>
      <TextInput
        label='Custom Aria Label'
        placeholder={text}
        setProp={(e) => setAriaLabel(e.target.value)}
        prop={ariaLabel}
      />
      <Divider />
      <ChipRow
        label='Click Behavior'
        prop={fun}
        setProp={setFun}
        options={["Function", "Link", "Upload File"]}
      />
      {fun === 'link' &&
        <Selector
          label='Link Behavior'
          prop={target}
          setProp={setTarget}
          options={["Default", "_blank", "_top", "_parent"]}
        />
      }
      <Divider />
      <SwitchRow
        label='Loading'
        showDivider={false}
        prop={loading} setProp={setLoading} />
      <TextInput
        label='Custom Aria Loading Label'
        placeholder='Loading Content'
        setProp={(e) => setAriaLoadingLabel(e.target.value)}
        prop={arialoadingLabel}
      />
      <Divider />
      <HStack>
        <TextInput
          placeholder='<>Component</>'
          setProp={(e) => setChildren(e.target.value)}
          prop={children}
          label='Children'
        />
        <Space.Content />

      </HStack>
      <Space.Content />
      <Divider />
      <TextInput
        label='Custom Icon Color'
        placeholder='ex: "dodgerblue", "rgb(var(--color-primary))"'
        setProp={(e) => setIconColorCustom(e.target.value)}
        prop={iconColorCustom}
      />
      <Divider />
      <Space.Content />
      <Subhead> In addition to these props, we also offer: </Subhead>
      <Space.Content />
      <HStack wrap>
        <PropCallout propName='className' propType='string' />
        <PropCallout propName='key' propType='number' />
        <PropCallout propName='ref' propType='RefObject' />
        <PropCallout propName='type' propType='"submit" | "button" | "reset"' />
      </HStack>
    </>
  );

  const ButtonComponent = (
    <Button
      width={width}
      variant={toCamel(variant)}
      text1={showText && text}
      loading={loading}
      small={small}
      icon1={iconLayout === 'leading' && showIcon && 'Placeholder'}
      icon2={iconLayout === 'trailing' && showIcon && 'Placeholder'}
      stacked={stacked}
      url={fun === 'link' && 'https://www.google.com'}
      target={fun === 'link' && target.toLowerCase() !== 'default' && target}
      onClick={fun === 'function' && (() => alert('onClick event'))}
      onFileUpload={fun === 'upload file' && ((e) => alert(e))}
      children={children}
      icon1ColorCustom={iconColorCustom}
      ariaLabel={ariaLabel}
      ariaLoadingLabel={arialoadingLabel}
    />

  );

  return (
    <>
      {thumbnail ? ButtonComponent :
        <TemplateVertical
          component={ButtonComponent}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
            {
              name: 'Content',
              controls: Content
            },
            {
              name: 'Technical',
              controls: Technical
            }
          ]}
        />
      }
    </>
  );
}

export default ButtonSnippet;

