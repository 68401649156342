import React, { useEffect, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { Logo } from '../../modules/Logo';
import { Container, Body, Button, Input, Media, Section, Sheet, Space, Title2, VStack, Label, Footnote, HStack, Headline, Subhead } from '@themeable/react-components';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  // Retrieve query parameters from the URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userName = searchParams.get('userName');
  const userId = searchParams.get('token');
  const userImg = searchParams.get('img');

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);

      let updateData = {};

      // Only add fields to the updateData object if they're truthy
      if (email) updateData.email = email;
      if (userName) updateData.name = userName;
      if (userId) updateData.figmaId = userId;
      if (userImg) updateData.img = userImg;

      // Now, update specific fields of the document without overwriting the entire document
      await updateDoc(doc(db, 'users', auth.currentUser.uid), updateData);

      navigate('/');
    } catch (error) {
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        setErrorMessage('Invalid email or password. Please try again.');
      } else {
        setErrorMessage('Something went wrong. Please try again later.');
      }
    }
  };


  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      setGreeting('Good morning');
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting('Good afternoon');
    } else {
      setGreeting('Good evening');
    }
  }, []);


  return (<>

    <main className='v-stack center middle' style={{ height: '100vh', width: '100%', backgroundImage: 'url("../assets/themeable-components.jpg")', backgroundPosition: 'center center', backgroundSize: 'cover' }}>

      <HStack align='left middle' width='fill'>
        <Section>
          <Container style={{
            maxWidth: '540px'
          }}>
            <Section>
              <Logo width='64px' />
              <Space.NewSection />
              <Title2>{greeting} 👋</Title2>
              <Body>Welcome back! Please enter your credentials.</Body>
              <Space.Content />
              {userName ? (
                <>
                  <Container variant='fill'>
                    <HStack align='middle'>
                      <Media url={userImg} profile size='small' />
                      <Headline>{userName}</Headline>
                    </HStack>
                  </Container><Space.Content />
                </>) : ''}

              <Space.Content />
              <form className='v-stack width-fill'>
                <Label label='Email'>
                  <Container variant='outline'>
                    <Input placeholder='Your email' type='email' onChange={(e) => setEmail(e.target.value)} />
                  </Container>
                </Label>
                <Label label='Email'
                  error={errorMessage !== null}
                  onErrorResolve={() => setErrorMessage(null)}
                  helperText={errorMessage}
                >
                  <Container variant='outline'>
                    <Input placeholder='Your password' type='password' onChange={(e) => setPassword(e.target.value)} />
                  </Container>
                </Label>
                <Space.Content />
                <Button type="submit" onClick={onLogin} variant="primary" text1='Log in' />
              </form>
              <Space.Content />

              <Body>

                <p className="content-view text-sm text-white text-center" style={{ marginLeft: 'var(--space-external)', marginBottom: '16px' }}>
                  No account yet?{' '}
                  <NavLink
                    to={userId ? `/signup?userName=${encodeURIComponent(userName)}&token=${encodeURIComponent(userId)}&img=${encodeURIComponent(userImg)}` : '/signup'}
                  >
                    <span style={{ color: 'rgb(var(--color-primary))' }}>
                      Sign Up
                    </span>
                  </NavLink>
                </p>
              </Body>

            </Section>
          </Container>
        </Section>
      </HStack>
    </main>
  </>);
};

export default Login;
