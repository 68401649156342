
import { Body, Button, Caption1, CodeSnippet, Container, Divider, Footnote, Headline, HStack, RichText, Section, Space, Subhead, Tab, Title1, Title2, Title3, VStack } from "@themeable/react-components";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContextValue } from "../../AppContext";

// Snippets
import ButtonSnippet from "./componentSnippets/Button";
import TabSnippet from "./componentSnippets/Tab";
import ContainerSnippet from "./componentSnippets/Container";
import InputSnippet from "./componentSnippets/Input";
import SectionSnippet from "./componentSnippets/Section";
import IconSnippet from "./componentSnippets/Icon";
import MediaSnippet from "./componentSnippets/Media";
import TextSnippet from "./componentSnippets/Text";
import SwitchSnippet from "./componentSnippets/Switch";
import StackSnippet from "./componentSnippets/Stack";
import LabelSnippet from "./componentSnippets/Label";
import ThemeableProviderSnippet from "./componentSnippets/ThemeableProvider";

// Eventually do:
import SheetSnippet from "./componentSnippets/Sheet";


function Overview({ components }) {

  const navigate = useNavigate();
  const { context } = useContextValue();
  const [subTab, setSubTab] = useState('components');

  return (
    <VStack>
      <Body style={{ maxWidth: '840px' }} color='text-secondary'>
        Welcome to Themeable components, where we offer accessible components fully integrated with your design system. These components not only adapt to different environments but also respond to varied design system foundations and viewport sizes, impacting text size, spacing, radius, and even light or dark modes.
      </Body>
      <Body style={{ maxWidth: '840px' }} color='text-secondary'>Here, you can explore exactly how each component functions. The difference with other systems, here <span className="text-primary weight-bold">you can make this system your own by editing and writing your own documentation</span> so that Themeable works well with your business’ needs.

      </Body>

      <Space.NewSection />
      <VStack>
        <HStack align="group">
          <Tab small text1="Components" indicator="none" active={subTab === 'components'} onClick={() => setSubTab('components')} />
          <Tab small text1="Installation" indicator="none" active={subTab === 'installation'} onClick={() => setSubTab('installation')} />
        </HStack>

        {subTab === 'installation' &&
          <Container>
            <Section>
              <VStack>
                <Title3>Installation</Title3>
                <Body> To install Themeable components, first make sure you have npm installed and have a react project started via vite or your chosen framework. </Body>
                <Space.Content />
                <Body>In your project, open a terminal and install the package: </Body>
                <Container variant="fill">
                  <CodeSnippet containerVariant="fill" language='bash' code='npm install @themeable/react' />
                </Container>

                <Space.NewSection />
                <Divider />
                <Space.NewSection />
                <Title3>Adding Themeable Provider</Title3>
                <Body>After installing Themeable, wrap your application in the ThemeableProvider and pass in your system's ID</Body>
                <Container variant="fill">
                  <CodeSnippet containerVariant="fill" language='jsx' code={`import { ThemeableProvider, Button } from "@themeable/react";

<ThemeableProvider systemId="${context.selectedSystem}">
  <Button variant="primary" text1="Hello World" />
</ThemeableProvider>`} />
                </Container>
                <Body>Component styles should all be in sync with the Themeable portal displaying all of your fonts and colors. If done incorrectly, components will display as black and white.</Body>
                <Space.NewSection />
                <Divider />

                <Space.NewSection />
                <Title3>
                  Language support
                </Title3>
                <Body>
                  Alternative languages/platforms is in development currently. Plan is to support:
                  <Container variant="blank">
                    <ul className="group">
                      <li> React Native </li>
                      <li> Svelte </li>
                      <li> Vue </li>
                      <li> Angular </li>
                      <li> Web Components </li>
                    </ul>
                  </Container>
                </Body>
              </VStack>
            </Section>
          </Container>
        }


        {subTab === 'components' &&
          <HStack
            gridExpanded={[1, 1, 1, 1, 1, 1]}
            gridMedium={[1, 1, 1, 1]}
            gridCompact={[1, 1]}
          >
            {components?.map((component) => {
              return <>
                <Button
                  onClick={() => { navigate(`../systemid=${context.selectedSystem}&page=components&component=${component.name.trim().replaceAll(' ', '-')}`); }}>
                  <Container
                    style={{
                      position: 'relative',
                      overflow: 'hidden'
                    }}>

                    {/* Wall to prevent click events for buttons etc. */}
                    <div
                      style={{
                        background: 'red',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        zIndex: '3',
                        opacity: '0'
                      }}
                    />

                    <VStack
                      align="center middle alt-theme"
                      height="fill"
                      style={{
                        minHeight: '150px',
                        scale: '.8',
                        margin: '0px'
                      }}>
                      {/* {c.snippet} */}
                      {component.name === 'Button' && <ButtonSnippet thumbnail />}
                      {component.name === 'Tab' && <TabSnippet thumbnail />}
                      {component.name === 'Input' && <InputSnippet thumbnail />}
                      {component.name === 'Container' && <ContainerSnippet thumbnail />}
                      {component.name === 'Section' && <SectionSnippet thumbnail />}
                      {component.name === 'Icon' && <IconSnippet thumbnail />}
                      {component.name === 'Media' && <MediaSnippet thumbnail />}
                      {component.name === 'Text' && <TextSnippet thumbnail />}
                      {component.name === 'Switch' && <SwitchSnippet thumbnail />}
                      {component.name === 'Stack' && <StackSnippet thumbnail />}
                      {component.name === 'Label' && <LabelSnippet thumbnail />}
                      {component.name === 'Provider' && <ThemeableProviderSnippet thumbnail />}

                      {/* Eventually do: */}
                      {/* {component.name === 'Sheet' && <SheetSnippet thumbnail />} */}

                      {component.snippet}
                    </VStack>
                    <VStack align="group">
                      <Headline>{component.name}</Headline>
                      {/* <Caption1 color="--color-text-secondary">Blah</Caption1> */}
                    </VStack>
                  </Container>
                </Button>
              </>
            })}
          </HStack>
        }


      </VStack>

    </VStack>
  )
}
export default Overview
