import React from 'react'
import { Title1, Title2, Title3, Headline, Body, Subhead, Footnote, Caption1, Caption2, Space, Divider, VStack } from '@themeable/react-components'

function SampleText( screenSize ) {

    const sampleText = (`
    Lorem ipsum dolor sit amet. 
    `)

return (
    <>
    <VStack>

        <Caption1 color='text-secondary'>Title 1 Default</Caption1>
        <Title1>{sampleText}</Title1>
        <Space.Content />
        <Caption1 color='text-secondary'>Title 1 Light</Caption1>
        <Title1 weight='light'>{sampleText}</Title1>
        <Space.Content />
        <Caption1 color='text-secondary'>Title 1 Regular</Caption1>
        <Title1 weight='regular'>{sampleText}</Title1>
        <Space.Content />
        <Caption1 color='text-secondary'>Title 1 Bold</Caption1>
        <Title1 weight='bold'>{sampleText}</Title1>
        
        <Space.NewSection />
        <Divider />
        <Space.NewSection />

        <Caption1 color='text-secondary'>Title 2 Default</Caption1>
        <Title2>{sampleText}</Title2>
        <Space.Content />
        <Caption1 color='text-secondary'>Title 2 Light</Caption1>
        <Title2 weight='light'>{sampleText}</Title2>
        <Space.Content />
        <Caption1 color='text-secondary'>Title 2 Regular</Caption1>
        <Title2 weight='regular'>{sampleText}</Title2>
        <Space.Content />
        <Caption1 color='text-secondary'>Title 2 Bold</Caption1>
        <Title2 weight='bold'>{sampleText}</Title2>
        
        <Space.NewSection />
        <Divider />
        <Space.NewSection />

        <Caption1 color='text-secondary'>Title 3 Default</Caption1>
        <Title3>{sampleText}</Title3>
        <Space.Content />
        <Caption1 color='text-secondary'>Title 3 Light</Caption1>
        <Title3 weight='light'>{sampleText}</Title3>
        <Space.Content />
        <Caption1 color='text-secondary'>Title 3 Regular</Caption1>
        <Title3 weight='regular'>{sampleText}</Title3>
        <Space.Content />
        <Caption1 color='text-secondary'>Title 3 Bold</Caption1>
        <Title3 weight='bold'>{sampleText}</Title3>
        
        <Space.NewSection />
        <Divider />
        <Space.NewSection />
        
        <Caption1 color='text-secondary'>Headline Default</Caption1>
        <Headline>{sampleText}</Headline>
        <Space.Content />
        <Caption1 color='text-secondary'>Headline Light</Caption1>
        <Headline weight='light'>{sampleText}</Headline>
        <Space.Content />
        <Caption1 color='text-secondary'>Headline Regular</Caption1>
        <Headline weight='regular'>{sampleText}</Headline>
        <Space.Content />
        <Caption1 color='text-secondary'>Headline Bold</Caption1>
        <Headline weight='bold'>{sampleText}</Headline>
        
        <Space.NewSection />
        <Divider />
        <Space.NewSection />
        
        <Caption1 color='text-secondary'>Body Default</Caption1>
        <Body>{sampleText}</Body>
        <Space.Content />
        <Caption1 color='text-secondary'>Body Light</Caption1>
        <Body weight='light'>{sampleText}</Body>
        <Space.Content />
        <Caption1 color='text-secondary'>Body Regular</Caption1>
        <Body weight='regular'>{sampleText}</Body>
        <Space.Content />
        <Caption1 color='text-secondary'>Body Bold</Caption1>
        <Body weight='bold'>{sampleText}</Body>
        
        <Space.NewSection />
        <Divider />
        <Space.NewSection />
        
        <Caption1 color='text-secondary'>Subhead Default</Caption1>
        <Subhead>{sampleText}</Subhead>
        <Space.Content />
        <Caption1 color='text-secondary'>Subhead Light</Caption1>
        <Subhead weight='light'>{sampleText}</Subhead>
        <Space.Content />
        <Caption1 color='text-secondary'>Subhead Regular</Caption1>
        <Subhead weight='regular'>{sampleText}</Subhead>
        <Space.Content />
        <Caption1 color='text-secondary'>Subhead Bold</Caption1>
        <Subhead weight='bold'>{sampleText}</Subhead>
        
        <Space.NewSection />
        <Divider />
        <Space.NewSection />
        
        <Caption1 color='text-secondary'>Footnote Default</Caption1>
        <Footnote>{sampleText}</Footnote>
        <Space.Content />
        <Caption1 color='text-secondary'>Footnote Light</Caption1>
        <Footnote weight='light'>{sampleText}</Footnote>
        <Space.Content />
        <Caption1 color='text-secondary'>Footnote Regular</Caption1>
        <Footnote weight='regular'>{sampleText}</Footnote>
        <Space.Content />
        <Caption1 color='text-secondary'>Footnote Bold</Caption1>
        <Footnote weight='bold'>{sampleText}</Footnote>

        
        <Space.NewSection />
        <Divider />
        <Space.NewSection />
        
        <Caption1 color='text-secondary'>Caption 1 Default</Caption1>
        <Caption1>{sampleText}</Caption1>
        <Space.Content />
        <Caption1 color='text-secondary'>Caption 1 Light</Caption1>
        <Caption1 weight='light'>{sampleText}</Caption1>
        <Space.Content />
        <Caption1 color='text-secondary'>Caption 1 Regular</Caption1>
        <Caption1 weight='regular'>{sampleText}</Caption1>
        <Space.Content />
        <Caption1 color='text-secondary'>Caption 1 Bold</Caption1>
        <Caption1 weight='bold'>{sampleText}</Caption1>
        
        <Space.NewSection />
        <Divider />
        <Space.NewSection />

        <Caption1 color='text-secondary'>Caption 2 Default</Caption1>
        <Caption2>{sampleText}</Caption2>
        <Space.Content />
        <Caption1 color='text-secondary'>Caption 2 Light</Caption1>
        <Caption2 weight='light'>{sampleText}</Caption2>
        <Space.Content />
        <Caption1 color='text-secondary'>Caption 2 Regular</Caption1>
        <Caption2 weight='regular'>{sampleText}</Caption2>
        <Space.Content />
        <Caption1 color='text-secondary'>Caption 2 Bold</Caption1>
        <Caption2 weight='bold'>{sampleText}</Caption2>
        
        <Space.NewSection />
    </VStack>


    </>
  )
}

export { SampleText }
