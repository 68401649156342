import React, { useContext, useEffect, useState } from "react";
import { VStack, Title1, Button, Container, Input } from "@themeable/react-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContextValue } from '../../AppContext'

const ComponentsNav = ({ componentList }) => {

  const navigate = useNavigate();
  const { context } = useContextValue();
  const { token } = useParams();
  const [componentName, setComponentName] = useState();

  useEffect(() => {
    if (token) {
      const params = new URLSearchParams(token);
      if (params.has('component')) {
        setComponentName(decodeURIComponent(params.get('component')))
      }
    }
  }, [token, componentName]);




  return <>
    <VStack align='group' width="hug">
      {componentList.map(component =>
        <Button
          small
          width="hug"
          text1={component.name}
          variant={component.name === componentName ? 'primary' : 'secondary'}
          onClick={() => {
            navigate(`../systemid=${context?.selectedSystem}&page=components&component=${component
              .name
              .trim()
              .replaceAll(' ', '-')
              }`);
          }
          } />
      )}

    </VStack>
  </>
}

export { ComponentsNav }
