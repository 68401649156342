import React from 'react'
import FoundationsPanel from '../../modules/FoundationsPanel'
import { Accordion, Body, Button, Caption1, Container, Divider, Footnote, HStack, Headline, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Tab, Tag, Title2, Title3, VStack } from '@themeable/react-components'
import { useContextValue } from '../../AppContext';
import { InputRadius } from '../../modules/InputPixel';

function Radius() {
  return (
    <>
      <VStack align=''>

        <FoundationsPanel
          form={<>
            <Title3>Controls</Title3>
            <InputRadius
              name='smallControl'
              label='Small Controls'
            />
            <InputRadius
              name='largeControl'
              label='Large Controls'
            />
          </>}
          snippet={<>
            <HStack align='center'>
              <Tab
                active
                text1='Small tab'
                stacked
                small
                icon1='Placeholder'
                indicator='bottom'
              />
              <Tab
                text1='Small tab'
                stacked
                small
                icon1='Placeholder'
                indicator='bottom'
              />
              <Tab
                text1='Small tab'
                stacked
                small
                icon1='Placeholder'
                indicator='bottom'
              />
            </HStack>
            <Divider nested extendLeft extendRight />
            <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
              <VStack>
                <HStack align='left group' wrap>
                  <Button
                    small
                    variant='primary'
                    text1='Small'
                    width='hug'
                  />
                  <Button
                    small
                    variant='secondary'
                    text1='Button'
                    width='hug'
                  />
                  <Button
                    small
                    variant='secondary'
                    text1='Button'
                    width='hug'
                  />
                  <Button
                    small
                    variant='secondary'
                    text1='Small'
                    width='hug'
                  />
                  <Button
                    small
                    variant='secondary'
                    text1='Small'
                    width='hug'
                  />
                </HStack>

              </VStack>
              <VStack align='top'>

                <Button>
                  <Container variant='fill'>
                    <div
                      style={{
                        display: 'flex',
                        flexGrow: '1',
                        height: '80px',
                        background: 'rgba(var(--color-fill-container), var(--color-fill-container-alpha))',
                        margin: 'var(--space-v-ext) var(--space-h-ext)',
                        borderRadius: 'var(--radius-large-control)'
                      }}
                    />
                    <Caption1>Selectable Card</Caption1>
                  </Container>
                </Button>
              </VStack>

            </HStack>
            <Container variant='fill'>
              <HStack align='left middle'>
                <Input value='Text input' />
                <Button width='small' small variant='destructive' icon1='CloseCircle' />
              </HStack>
            </Container>
            <Space.NewSection />
            <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
              <Button text1='Large Button' />
              <Button text1='Large Button' variant='primary' />
            </HStack>
            <Space.NewSection />
            <Divider extendRight extendLeft nested />
            <HStack align='center'>
              <Tab
                active
                text1='Large tab'
                indicator='top'
              />
              <Tab
                text1='Large tab'
                indicator='top'
              />
              <Tab
                text1='Large tab'
                indicator='top'
              />
            </HStack>
          </>}
        />


        <FoundationsPanel
          form={<>
            <Title3>Decorative Elements</Title3>
            <InputRadius
              name='container'
              label='Containers'
            />
            <InputRadius
              name='page'
              label='Page'
            />
          </>}
          snippet={<>
            <Container>
              <Section>
                <Accordion
                  parentContent={<>
                    <Body>Accordion + container + section</Body>
                  </>}>
                  <Footnote>Child element</Footnote>
                </Accordion>
                <Accordion
                  parentContent={<>
                    <Body>Accordion + container + section</Body>
                  </>}>
                  <Footnote>Child element</Footnote>
                </Accordion>
              </Section>
            </Container>
            <Accordion
              parentContent={<>
                <Body>Accordion</Body>
              </>}>
              <Footnote>Child element</Footnote>
            </Accordion>

            <Container>

              <Accordion
                parentContent={<>
                  <Body>Accordion + container</Body>
                </>}>
                <Footnote>Child element</Footnote>
              </Accordion>
            </Container>
          </>}

        />

      </VStack>

    </>
  )
}

export default Radius
