import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Icon, Input, Label, Media, Section, Space, Subhead, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";

function ComponentSnippet({ thumbnail = false }) {
  const [size, setSize] = useState('Medium');
  const [profile, setProfile] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [loop, setLoop] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [mute, setMute] = useState(false);

  const imageUrl = '/assets/thumbnail.jpg';
  const videoUrl = 'https://firebasestorage.googleapis.com/v0/b/themeable-ce1f4.appspot.com/o/assets%2Fhero-graphic.mp4?alt=media&token=36031779-b46e-46e2-84d0-d17d0ef6aea5';

  const [showProfileImage, setShowProfileImage] = useState(true);

  const [altTag, setAltTag] = useState("This is the Media Component");
  const [showCaption, setShowCaption] = useState(false);
  const [customWidth, setCustomWidth] = useState('');
  const [fallbackColor, setFallbackColor] = useState('Text Primary');
  const [type, setType] = useState('image');
  const [aspectRatio, setAspectRatio] = useState('1 / 1');
  const [alignCaption, setAlignCaption] = useState('left')

  const Design = (
    <>
      <Selector
        label='Size'
        prop={size} setProp={setSize}
        options={[
          'Small',
          'Medium',
          'Large',
          'Fill',
          'Custom'
        ]} />
      {size === 'Custom' &&
        <TextInput
          label='Custom size'
          placeholder='var(--size-base), 32px, 2rem, etc'
          value={customWidth} // Set value conditionally
          setProp={(e) => setCustomWidth(e.target.value)}
        />
      }
      <Divider />
      <SwitchRow
        prop={showCaption} setProp={setShowCaption}
        label='Show Caption'
      />
      {showCaption &&
        <ChipRow
          label='Align Caption'
          prop={alignCaption} setProp={setAlignCaption}
          options={['Left', 'Center', 'Right']} />
      }
      <Divider />
      <SwitchRow
        label='Profile Image'
        prop={profile}
        setProp={setProfile}
      />
      {profile ?
        <>
          <SwitchRow
            label='Show profile image'
            prop={showProfileImage}
            setProp={setShowProfileImage}
          />
          <Selector
            label='Fallback Color'
            prop={fallbackColor}
            setProp={setFallbackColor}
            options={['Primary', 'Accent', 'Positive', 'Negative', 'Informative', 'Notice', 'Text Primary', 'Text Secondary']}
          />
        </>
        :
        <>
          <Divider />
          <TextInput
            label='Aspect Ratio'
            placeholder='1 / 1'
            prop={aspectRatio} setProp={(e) => setAspectRatio(e.target.value)}
          />
        </>
      }
    </>
  );

  const Content = (
    <>
      <ChipRow
        label='Media type'
        prop={type} setProp={setType}
        options={['Image', 'Video']} />
      {type === 'video' &&
        <>
          <Divider />
          <SwitchRow
            label='Play automatically'
            setProp={setAutoPlay} prop={autoPlay}
          />
          <SwitchRow
            label='Show video controls'
            prop={showControls} setProp={setShowControls} />
          <SwitchRow
            label='Repeat/Loop video'
            prop={loop} setProp={setLoop} />
          <SwitchRow
            label='Mute video'
            prop={mute} setProp={setMute} />

        </>
      }
      <Divider />
      <TextInput
        prop={altTag} setProp={(e) => setAltTag(e.target.value)}
        label='Caption / Alt Tag'
        placeholder="Don't forget the alt tag for screen readers!"
      />
    </>
  );


  // const Technical = (
  //   <>
  //     <TextInput
  //       label='Custom Path for SVG Document'
  //       prop={svgPath}
  //       placeholder='../icons.svg'
  //       setProp={(e) => setSvgPath(e.target.value)}
  //     />
  //   </>
  // );

  const SnippetComponent = (
    <Media
      imageSrc={showProfileImage ? imageUrl : undefined}
      size={size === 'Custom' ? customWidth === '' ? '300px' : customWidth : size.toLowerCase()}
      profile={profile}
      showCaption={showCaption}
      caption={altTag}
      fallbackColor={fallbackColor === 'Text Primary'
        ? undefined
        : fallbackColor
          .toLowerCase()
          .replaceAll(' ', '-')
      }
      type={type === 'image' ? undefined : 'video'}
      aspectRatio={profile ? undefined : aspectRatio}
      alignCaption={alignCaption === 'left' ? undefined : alignCaption}
      children={profile ? 'BW' : undefined}
      videoSrc={type === 'video' ? [videoUrl, videoUrl] : undefined}


      loop={loop}
      showControls={showControls}
      muted={mute}
      autoPlay={autoPlay}
    />
  );

  return (
    <>
      {thumbnail ? SnippetComponent :
        <TemplateVertical
          component={SnippetComponent}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
            {
              name: 'Content',
              controls: Content
            },
            // {
            //   name: 'Technical',
            //   controls: Technical
            // }
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

