import { Container, Input, Label, VStack } from '@themeable/react-components'
import React from 'react'
import { useContextValue } from '../AppContext';

function InputAlpha({ hide, name }) {

    const { context, cColor, setCColor } = useContextValue();

    const handleAlphaChange = (event) => {
		const { name, value } = event.target;
        const validNumberRegex = /^(100|[1-9]?[0-9])?$/;
		if (value.match(validNumberRegex) || value < 101) {
			setCColor(prev => {
			const oppositeColorMode = context.colorMode === 'light' ? 'dark' : 'light';
			return {
				...prev,
				[context.colorMode]: {
					...prev[context.colorMode],
                    [name]: value === '' ? '' : (parseInt(value) / 100).toString()
				},
				[oppositeColorMode]: {
                    [name]: '',
                    ...prev[oppositeColorMode],
				}
			};
			});
		}
	};

	const stringToNumber = (number) => {
		
        if (number === '') {
            return '';
        } else {
            return Math.round(number * 100).toString();
        }

	}
    

    if(hide) {
        return (<VStack maxWidth='130px' />)
    } else {
        return (
            <VStack maxWidth='130px'>
                <Label label='Alpha'>
                    <Container variant='fill'>
                    <Input 
                        type='number'
                        placeholder={stringToNumber(context.systemList[context.selectedSystem].foundations.color[context.colorMode][name])}
                        align='right'
                        trailingIconColor='text-secondary'
                        trailingIconName='Percent'
                        onChange={handleAlphaChange}
                        name={name}
                        value={(stringToNumber(cColor && cColor[context.colorMode] && cColor[context.colorMode][name]) || '')}
                        />
                    </Container>
                </Label>
            </VStack>
        )
    }
}

export default InputAlpha
