export function getRelativeTime(seconds) {
  // Convert the seconds timestamp to milliseconds
  const date = new Date(seconds * 1000);
  const now = new Date();

  const differenceInSeconds = Math.floor((now - date) / 1000);

  if (differenceInSeconds < 60) {
    return `a few seconds ago`;
  } else if (differenceInSeconds < 3600) {
    const minutes = Math.floor(differenceInSeconds / 60);
    return `${minutes} min ago`;
  } else if (differenceInSeconds < 86400) {
    const hours = Math.floor(differenceInSeconds / 3600);
    return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(differenceInSeconds / 86400);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
}
