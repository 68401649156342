import { Container, HStack, Section, VStack } from '@themeable/react-components'
import React from 'react'
import { useContextValue } from '../AppContext';

function FoundationsPanel({ snippet, form }) {

  const { context, setContext } = useContextValue();

  return (
    <VStack>
      <Container
        variant='default'
        height='hug'
      >
        <HStack gridDesktop={2} gridTablet={2} gridMobile={1} height='fill'>
          <VStack align='middle'>
            <Section>
              {form}
            </Section>
          </VStack>
          <Container className={`alt-theme ${context?.colorMode} ${context?.density}`} height='fill' variant='fill' style={{ outline: '1px dotted rgba(var(--color-border), .4)', overflow: 'hidden', borderRadius: 'var(--ts-radius-page)', transition: 'all .4s' }}>
            <VStack align={`alt-theme ${context?.colorMode} ${context?.density} middle`} height='fill' style={{ background: 'rgb(var(--ts-color-background-flat))' }}>
              <Section>
                {snippet}
              </Section>
            </VStack>
          </Container>
        </HStack>
      </Container>
    </VStack>
  )
}

export default FoundationsPanel
