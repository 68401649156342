import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Input, Section, Space, Subhead, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";
import '../styles.css'

function ComponentSnippet({ thumbnail = false }) {
  const [height, setHeight] = useState('hug');
  const [section, setSection] = useState(false);
  const [showGuides, setShowGuides] = useState(false);


  const Design = (
    <>
      <ChipRow
        label='Height'
        textColor={null}
        prop={height} setProp={setHeight}
        options={['Hug', 'Fill']} />
      <Divider />
      <SwitchRow
        label='Has Section'
        textColor={null}
        prop={section} setProp={setSection}
      />
      <Divider />
      <SwitchRow
        label='Show Guides'
        textColor={null}
        prop={showGuides} setProp={setShowGuides}
      />

    </>
  );


  const SectionComponent = (
    <Section
      height={height}
      className={showGuides ? 'section-example' : null}
    >
      <Container height={height}>
        {section ?
          <Section
            className={showGuides ? 'section-example' : null}
          >
            <Body>Text inside a container inside a section</Body>
          </Section>
          :
          <Body>Text inside a container inside a section</Body>
        }
      </Container>
    </Section>
  );

  return (
    <>
      {thumbnail ? SectionComponent :
        <TemplateVertical
          component={SectionComponent}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

