import { Body, Button, Caption1, Cell, CodeSnippet, Container, Divider, Footnote, HStack, Icon, Input, Label, Section, Sheet, Space, Subhead, Switch, Tab, Title3, VStack } from "@themeable/react-components";
import React, { useEffect, useRef, useState } from "react";
import reactToString from "react-element-to-jsx-string";

function TemplateVertical({ component, controls }) {
  const [mode, setMode] = useState('Auto');
  const [showCode, setShowCode] = useState(false);

  // Additional states and refs from original code
  const [screenSize, setScreenSize] = useState('Auto');
  const screenSizes = ['Auto', 'Compact', 'Medium', 'Expanded'];
  const screenSizeRef = useRef();
  const modeRef = useRef();
  const modes = ['Auto', 'Light', 'Dark'];
  const [screenSizeSheet, setScreenSizeSheet] = useState(false);
  const [modeSheet, setModeSheet] = useState(false);
  const [attType, setAttType] = useState(controls[0].name);


  const customReactToStringOptions = {
    functionValue: () => "() => null", // Replace all functions with a simplified placeholder
    showFunctions: false,             // Optionally hide function bodies completely
    useFragmentShortSyntax: true
  };


  const cleanElementProps = (element) => {
    if (!React.isValidElement(element)) {
      throw new Error('Invalid React element passed to cleanElementProps');
    }

    // Filter out null and undefined props
    const filteredProps = Object.fromEntries(
      Object.entries(element.props).filter(([_, value]) => value != null) // Exclude null and undefined
    );

    // Recursively clean children if any
    const cleanChildren = React.Children.map(element.props.children, (child) =>
      React.isValidElement(child) ? cleanElementProps(child) : child
    );

    // Recreate the element with filtered props and cleaned children
    return React.createElement(
      element.type, // Use the original element type
      filteredProps, // Pass only the filtered props
      cleanChildren // Pass the cleaned children
    );
  };

  return (
    <>
      <Container>
        <Section>
          <HStack
            gridExpanded={showCode ? [1, 1] : [1]}
            gridMedium={[1]}
            gridCompact={[1]}
            align='group'
          >
            <Container variant="blank" style={{ overflow: 'hidden', position: 'relative' }}>
              <Section
                height="fill"
                className={`alt-theme ${mode.toLowerCase()}`}
                style={{
                  background: 'rgb(var(--color-background-flat))',
                }} >
                <div style={{ top: '0', position: 'absolute', right: '0' }}>
                  <HStack align="group right">
                    <Button
                      variant="quaternary"
                      icon2="ChevronDown"
                      text1={mode === 'Auto' ? 'Color Mode' : mode}
                      small
                      ref={modeRef}
                      onClick={() => setModeSheet(true)}
                      width="hug"
                    />
                    {/* Mode Sheet */}
                    <Sheet
                      onDismiss={() => setModeSheet(false)}
                      active={modeSheet}
                      size='compact'
                      anchor={modeRef}
                    >
                      {modes.map((m) => {
                        const icon = m === 'Light' ? 'Sun' : m === 'Dark' ? 'Moon' : null;
                        return (
                          <Cell
                            key={m}
                            onClick={() => {
                              setMode(m);
                              setModeSheet(false);
                            }} >
                            <HStack>
                              <Icon name={mode === m ? 'Check' : "Blank"} />
                              <Body>{m}</Body>
                              {icon && <Icon name={icon} />}
                            </HStack>
                          </Cell>
                        )
                      })}
                    </Sheet>

                    {/* <Button */}
                    {/*   variant="quaternary" */}
                    {/*   icon2="ChevronDown" */}
                    {/*   text1={screenSize === 'Auto' ? 'Screen Size' : screenSize} */}
                    {/*   small */}
                    {/*   ref={screenSizeRef} */}
                    {/*   onClick={() => setScreenSizeSheet(true)} */}
                    {/*   width="hug" */}
                    {/* /> */}
                    {/* <Sheet */}
                    {/*   onDismiss={() => setScreenSizeSheet(false)} */}
                    {/*   active={screenSizeSheet} */}
                    {/*   size='compact' */}
                    {/*   anchor={screenSizeRef} */}
                    {/* > */}
                    {/*   {screenSizes.map((m) => ( */}
                    {/*     <Cell */}
                    {/*       key={m} */}
                    {/*       onClick={() => { */}
                    {/*         setScreenSize(m); */}
                    {/*         setScreenSizeSheet(false); */}
                    {/*       }} > */}
                    {/*       <HStack> */}
                    {/*         <Icon name={screenSize === m ? 'Check' : "Blank"} /> */}
                    {/*         <Body>{m}</Body> */}
                    {/*       </HStack> */}
                    {/*     </Cell> */}
                    {/*   ))} */}
                    {/* </Sheet> */}
                    <Button
                      icon1="React"
                      small
                      variant={showCode ? 'primary' : 'quaternary'}
                      onClick={() => setShowCode(!showCode)}
                      width='hug'
                    />
                  </HStack>
                </div>
                <Section >
                  <HStack
                    align={`center middle ${screenSize.toLowerCase()}`}
                    style={{
                      height: '400px'
                    }
                    }
                    className="">
                    {component}
                  </HStack>
                </Section>
              </Section>
            </Container>

            {showCode && (
              <Container variant="fill" height="fill">
                <VStack align="alt-theme" height="fill" scroll="vertical" showOverflowBar={false}>
                  <CodeSnippet
                    language="jsx"
                    code={reactToString(cleanElementProps(component), customReactToStringOptions)}
                  />
                </VStack>
              </Container>
            )}

          </HStack>
          <Space.Content />

          <HStack align="">
            {controls.length > 1 && controls.map((control) => {
              return (
                <Tab
                  text1={control.name}
                  small
                  width="hug"
                  active={control.name === attType}
                  onClick={() => setAttType(control.name)}
                  indicator='bottom'
                />
              )
            })}
          </HStack>
          <Space.Content />
          <VStack align="group">
            {controls.map((control) => (
              <>
                {control.name === attType && control.controls}
              </>
            ))}
          </VStack>
        </Section>
      </Container>
      <Space.NewSection />
    </>
  );
}

export default TemplateVertical;
function ChipRow({ prop, label, options, setProp }) {
  return (
    <>
      <HStack height="hug" align="middle">
        <Subhead>{label}</Subhead>
        <HStack align="right">
          {options.map((option) => (
            <Button
              key={option}
              variant={option.toLowerCase() === prop.toLowerCase() ? "primary" : "secondary"}
              small
              width="hug"
              onClick={() => setProp(option.toLowerCase())}
              text1={option}
            />
          ))}
        </HStack>
      </HStack>
    </>
  );
}

const PropCallout = ({ propName, propType }) => (
  <Container variant="outline" width="hug">
    <Subhead color="text-secondary" width="hug">
      {propName}:
      <span style={{
        color: 'rgba(var(--color-text-primary), var(--color-text-primary-alpha))'
      }}
      > {propType}</span>;
    </Subhead>
  </Container>
)


function TextInput({ prop, setProp, label, placeholder, footnote, type = 'text' }) {
  return (
    <Label labelColor="--color-text-primary" label={label} horizontal ratio={[1, 1]}>
      <VStack>
        <Container variant="fill">
          <Input placeholder={placeholder} onChange={setProp} value={prop} type={type} />
        </Container>
        {/* {footnote && <Footnote color='text-secondary'>{footnote}</Footnote>} */}
      </VStack>
    </Label>
  )
}

function Selector({ prop, setProp, label, options }) {

  const selectorRef = useRef();
  const [selectSheet, setSelectSheet] = useState(false);

  return (
    <>
      <HStack height="hug" align="middle">
        <Subhead>{label}</Subhead>
        <Button
          ref={selectorRef}
          variant="secondary"
          small
          width="hug"
          onClick={() => setSelectSheet(true)}
          icon2='ChevronDown'
          text1={prop}
        />
        <Sheet active={selectSheet} onDismiss={() => setSelectSheet(false)} anchor={selectorRef} >
          {options.map((option) => (
            <Cell key={option} onClick={() => {
              setProp(option);
              setSelectSheet(false);
            }}>
              <HStack align="middle">
                {option.toLowerCase() === prop.toLowerCase() ?
                  <Icon name="Check" /> :
                  <Icon name='Blank' />
                }
                <Body>{option}</Body>
              </HStack>
            </Cell>
          ))}
        </Sheet>

      </HStack>
    </>
  );
};

function SwitchRow({ prop, label, options, setProp }) {
  return (
    <>
      <HStack height="hug" align="middle">
        <Subhead>{label}</Subhead>
        <Switch active={prop} onChange={setProp} />
      </HStack>
    </>
  );
}



export {
  SwitchRow,
  Selector,
  ChipRow,
  TemplateVertical,
  PropCallout,
  TextInput
}
