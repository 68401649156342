import React from 'react'
import FoundationsPanel from '../../modules/FoundationsPanel'
import { Accordion, Body, Button, Caption1, Container, Divider, Footnote, HStack, Headline, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Tab, Tag, Title2, Title3, VStack } from '@themeable/react-components'
import { useContextValue } from '../../AppContext';
import { InputSpace } from '../../modules/InputPixel';

function SpaceFoundations() {
  const { context, cSpace, setCSpace } = useContextValue();

  return (
    <>
      <VStack align=''>


        <Button url='https://getthemeable.com/education/how-to-create-a-spacing-system'>
          <Container style={{
            backgroundImage: 'url(../assets/help-articles/foundation-help-spacing.jpg)',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}>
            <Section>
              <VStack align='middle center'>
                <Title2 color='on-primary'>
                  Space
                </Title2>
                <HStack wrap>
                  <Headline width='hug' color='on-primary'>
                    Not sure what your settings should be? Check out this article
                  </Headline>
                  <Icon name='ArrowRight' color='on-primary' />
                </HStack>
              </VStack>
            </Section>
          </Container>
        </Button>


        <FoundationsPanel
          form={<>
            <Title3>Internal Spacing</Title3>
            <Headline>Small components</Headline>
            <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
              <InputSpace
                name='hSmall'
                label='Horizontal'
              />
              <InputSpace
                name='vSmall'
                label='Vertical'
              />
            </HStack>
            <Divider />
            <Headline>Large components</Headline>
            <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
              <InputSpace
                name='hExtra'
                label='Horizontal'
              />
              <InputSpace
                name='vExtra'
                label='Vertical'
              />
            </HStack>
          </>}
          snippet={<>
            <Container>
              <HStack align='left middle'>
                <Body>Task complete</Body>
                <Button width='hug' icon1='Check' small />
              </HStack>
            </Container>
            <Container>
              <HStack align='left middle'>
                <Body>See remaning tasks</Body>
                <Button width='hug' icon1='ArrowRight' small variant='primary' />
              </HStack>
            </Container>
            <Space.NewSection />
            <HStack align='middle center'>
              <Button width='hug' variant='quaternary' icon1='MediaVolume' small />
              <Button width='hug' variant='secondary' icon1='MediaSkipBack' small />
              <Button width='hug' variant='primary' icon1='MediaPlay' />
              <Button width='hug' variant='secondary' icon1='MediaSkipForward' small />
              <Button width='hug' variant='tertiary' icon1='MediaShuffle' small />
            </HStack>
            <Space.NewSection />
            <HStack align='center middle'>
              <Button variant='destructive' icon1='Placeholder' text1='Cancel' />
              <Button variant='secondary' icon1='Placeholder' text1='Continue' />
            </HStack>
          </>}
        />


        <FoundationsPanel
          form={<>
            <Title3>Section Spacing</Title3>
            <Space.Content />
            <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
              <InputSpace
                name='hSection'
                label='Horizontal'
              />
              <InputSpace
                name='vSection'
                label='Vertical'
              />
            </HStack>
          </>}
          snippet={<>
            <HStack align='left middle'>
              <Button width='hug' variant='quaternary' icon1='Hamburger' small />
              <HStack align='group right'>
                <Button width='hug' variant='secondary' icon1='Person' small />
                <Button width='hug' variant='primary' text1='Purchase' small />
              </HStack>
            </HStack>
            <Divider nested extendLeft extendRight />
            <Space.NewSection />
            <Title3>Title</Title3>
            <Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
            </Body>
            <Divider />
            <HStack align='left'>
              <Button variant='primary' text1='Button' />
              <Button text1='Button' />
            </HStack>
            <HStack align='left'>
              <Button variant='tertiary' text1='Button' />
              <Button variant='quaternary' text1='Button' />
            </HStack>
            <HStack align='left'>
              <Button small variant='primary' text1='Button' />
              <Button small text1='Button' />
            </HStack>
            <HStack align='left'>
              <Button small variant='tertiary' text1='Button' />
              <Button small variant='quaternary' text1='Button' />
            </HStack>
          </>}
        />


        <FoundationsPanel
          form={<>
            <Title3>Finer Details</Title3>
            <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
              <InputSpace
                name='content'
                label='Content'
              />
            </HStack>
            <Space.Content />
            <Headline>Text/Icon adjustments</Headline>
            <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
              <InputSpace
                name='hUnify'
                label='Horizontal'
              />
              <InputSpace
                name='vUnify'
                label='Vertical'
              />

            </HStack>
          </>}
          snippet={<>
            <Container>
              <Body>
                Text inside container
              </Body>
            </Container>
            <Input value='Text input' />
            <Input leadingIconName='Placeholder' value='Text input' trailingIconName='Plus' />
            <HStack>
              <Button
                variant='primary'
                icon1='Placeholder'
                width='hug'
              />
              <Button
                variant='primary'
                width='hug'
                text1='Button'
              />
              <Button
                variant='primary'
                width='hug'
                text1='Button'
                icon1='Placeholder'
              />
              <Button
                variant='primary'
                text1='Button'
                width='hug'
                icon2='Placeholder'
              />
            </HStack>
            <HStack>
              <Button
                variant='quaternary'
                icon1='Placeholder'
                width='hug'
              />
              <Button
                variant='quaternary'
                text1='Button'
                width='hug'
              />
              <Button
                variant='quaternary'
                text1='Button'
                icon1='Placeholder'
                width='hug'
              />
              <Button
                variant='quaternary'
                text1='Button'
                icon2='Placeholder'
                width='hug'
              />
            </HStack>
            <HStack>
              <Button
                variant='primary'
                icon1='Placeholder'
                small
                width='hug'
              />
              <Button
                variant='primary'
                text1='Button'
                small
                width='hug'
              />
              <Button
                variant='primary'
                text1='Button'
                icon1='Placeholder'
                small
                width='hug'
              />
              <Button
                variant='primary'
                text1='Button'
                iconw='Placeholder'
                small
                width='hug'
              />

            </HStack>
            <HStack>
              <Button
                variant='quaternary'
                icon1='Placeholder'
                small
                width='hug'
              />
              <Button
                variant='quaternary'
                text1='Button'
                small
                width='hug'
              />
              <Button
                variant='quaternary'
                text1='Button'
                icon1='Placeholder'
                small
                width='hug'
              />
              <Button
                variant='quaternary'
                text1='Button'
                iconw='Placeholder'
                small
                width='hug'
              />
            </HStack>

          </>}
        />



      </VStack>

    </>
  )
}

export default SpaceFoundations
