import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GlobalNav from '../../modules/Nav'
import { Sheet, Section, HStack, Media, Cell, Container, Icon, Button, Title2, Headline, Title3, Popover, Subhead, Divider, Caption1, Group, VStack, Caption2, Input, Label, Body, Loader, Tab, Title1, Space, Footnote } from '@themeable/react-components';
import { useContextValue } from '../../AppContext';
import { SaveData, CalculateChanges, timestampToDate, CreateNewSystem } from '../Utilities/UtilityFunctions';

const Home = () => {

  const {
    context,
    setContext,
    cColor,
    cRadius,
    cSpace,
    cSize,
    cText,
    setCText
  } = useContextValue();
  const navigate = useNavigate();


  // =====================
  // =====================
  // Autosave feature ----
  // =====================
  // =====================
  const [pageTitle, setPageTitle] = useState('Welcome');
  const [pageSubTitle, setPageSubTitle] = useState('Select a system below or create a new one.');




  return (<>
    <main className='page-bleed' style={{ backgroundColor: 'rgb(var(--color-background-flat))', height: '100%' }}>
      <GlobalNav />

      <>
        <Section>
          <VStack grow align='left'>
            <Title1>{pageTitle}</Title1>
            <Body>{pageSubTitle}</Body>
            <Button small width='hug' text1='Create System' icon1='PlusCircle' variant='primary' onClick={() => { CreateNewSystem(null, context) }} />
          </VStack>
        </Section>
        {context.systemList && (
          <>
            <Section>
              <VStack style={{ maxWidth: '400px' }}>
                <Subhead color='text-secondary'>Existing systems:</Subhead>
                {Object.keys(context.systemList).map((systemId) => {
                  const system = context.systemList[systemId];
                  return (
                    <Cell key={systemId} onClick={() => navigate(`systemid=${systemId}`)}>
                      <HStack align='middle'>
                        <VStack>
                          <Body>{system.name}</Body>
                          <Footnote color='text-secondary'>Modified: <span style={{ color: 'rgba(var(--color-text-primary), var(--color-text-primary-alpha))' }}>{timestampToDate(context.systemList[systemId].lastModified)}</span></Footnote>
                        </VStack>
                        <Icon name='ChevronRight' />
                      </HStack>
                    </Cell>
                  );
                })}
              </VStack>
            </Section>
          </>
        )}
      </>

    </main>
  </>)

};

export default Home;
