import React, { useEffect, useState } from 'react'
import FoundationsPanel from '../../modules/FoundationsPanel'
import { Accordion, Body, Button, Caption1, Container, Divider, Footnote, HStack, Headline, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Tab, Tag, Title2, Title3, VStack } from '@themeable/react-components'
import { useContextValue } from '../../AppContext';
import InputColor from '../../modules/InputColor';
import InputAlpha from '../../modules/InputAlpha';


function Color() {


  return (<>
    <VStack>
      <Button width='fill' url='https://getthemeable.com/education/how-to-create-a-color-system'>
        <Container style={{
          backgroundImage: 'url(../assets/help-articles/foundation-help-color.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}>
          <Section>
            <VStack align='middle center'>
              <Title2 color='on-primary'>
                Color
              </Title2>
              <HStack wrap>

                <Headline width='hug' color='on-primary'>
                  Not sure what your settings should be? Check out this article
                </Headline>
                <Icon name='ArrowRight' color='on-primary' />

              </HStack>
            </VStack>
          </Section>
        </Container>
      </Button>
      <FoundationsPanel
        form={<>
          <Title3>Prominent Colors</Title3>
          <HStack>
            <InputColor
              label='Primary'
              name='primary'
            />
            <InputAlpha
              name='primaryAlpha'
            />
          </HStack>
          <HStack>
            <InputColor
              label='Color on Primary'
              name='onPrimary'
            />
            <InputAlpha
              hide
            />
          </HStack>
          <Divider />
          <HStack>
            <InputColor
              label='Accent'
              name='accent'
            />
            <InputAlpha
              name='accentAlpha'
            />
          </HStack>
          <HStack>
            <InputColor
              label='Color on Accent'
              name='onAccent'
            />
            <InputAlpha
              hide
            />
          </HStack>
        </>}
        snippet={<>
          <Subhead>Select an Option:</Subhead>
          <HStack align='middle left group' wrap>
            <Button
              variant='tertiary'
              text1='Option'
              width='hug'
              small
            />
            <Button
              variant='primary'
              text1='Selected'
              width='hug'
              small
            />
            <Button
              variant='tertiary'
              text1='Option 3'
              width='hug'
              small
            />
            <Button
              variant='tertiary'
              text1='Option 4'
              width='hug'
              small
            />
            <Button
              variant='tertiary'
              text1='Option 5'
              small
              width='hug'
            />
          </HStack>
          <Divider nested />
          <HStack align='middle'>
            <Subhead>Remember next time?</Subhead>
            <Space.Grow />
            <Switch
              checked
              onChange={(e) => { console.log(e) }}
            />
          </HStack>
          <Divider nested />
          <Space.NewSection />
          <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
            <Button
              variant='accent'
              text1='Save as Draft'
            />
            <Button
              variant='primary'
              text1='Publish'
            />
          </HStack>
          <VStack align='group'>
            <Button
              variant='tertiary'
              text1='Cancel'
            />
          </VStack>
        </>}
      />


      <FoundationsPanel
        form={<>
          <Title3>Text/Glyph Colors</Title3>
          <HStack>
            <InputColor
              label='Text Primary'
              name='textPrimary'
            />
            <InputAlpha
              name='textPrimaryAlpha'
            />
          </HStack>
          <HStack>
            <InputColor
              label='Text Secondary'
              name='textSecondary'
            />
            <InputAlpha
              name='textSecondaryAlpha'
            />
          </HStack>
          <Divider />
          <HStack>
            <InputColor
              label='Icon'
              name='icon'
            />
            <InputAlpha
              name='iconAlpha'
            />
          </HStack>
        </>}
        snippet={<>
          <HStack align='left middle'>
            <Tag text1='Tag Example' status='neutral' />
            <Space.Content />
            <Icon name='HeartOutline' />
            <Space.Content />
            <Icon name='ThreeDots' />
          </HStack>
          <Title2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Title2>
          <Body color='text-secondary'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.
          </Body>

          <HStack>
            <Divider vertical />
            <Space.Content />
            <Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
            </Body>
          </HStack>
          <Space.NewSection />
          <Button small variant='secondary' width='hug' text1='Show more' icon2='ChevronDown' />
        </>}
      />


      <FoundationsPanel
        form={<>
          <Title3>Decorative Elements</Title3>

          <HStack>
            <InputColor
              label='Background (Elevated)'
              name='backgroundElevated'
            />
            <InputAlpha
              hide
            />
          </HStack>
          <HStack>
            <InputColor
              label='Background (Flat)'
              name='backgroundFlat'
            />
            <InputAlpha
              hide
            />
          </HStack>

          <Divider />

          <HStack>
            <InputColor
              label='Fill (Container)'
              name='fillContainer'
            />
            <InputAlpha
              name='fillContainerAlpha'
            />
          </HStack>
          <HStack>
            <InputColor
              label='Fill (Control)'
              name='fillControl'
            />
            <InputAlpha
              name='fillControlAlpha'
            />
          </HStack>

          <Divider />

          <HStack>
            <InputColor
              label='Border'
              name='border'
            />
            <InputAlpha
              name='borderAlpha'
            />
          </HStack>
          <HStack>
            <InputColor
              label='Read Only'
              name='readOnly'
            />
            <InputAlpha
              name='readOnlyAlpha'
            />
          </HStack>
          <HStack>
            <InputColor
              label='Divider'
              name='divider'
            />
            <InputAlpha
              name='dividerAlpha'
            />
          </HStack>

        </>}
        snippet={<>
          <Caption1 color='text-secondary' align='center'>Background</Caption1>
          <Container variant='outline'>
            <Caption1 color='text-secondary'>Border</Caption1>
            <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>

              <Container variant='default'>
                <Caption1 color='text-secondary'>Background Elevated</Caption1>
                <VStack align='middle center'>
                  <Tag status='neutral' text1='Fill (Control)' />
                  <HStack>
                    <Switch checked={false} onChange={() => { }} /> <Button small text1='Button' />
                  </HStack>
                </VStack>
              </Container>
              <Container variant='fill'>
                <Caption1 color='text-secondary'>Fill Container</Caption1>
                <VStack align='middle center'>
                  <Tag status='neutral' text1='Fill (Control)' />
                  <HStack>
                    <Switch checked={false} onChange={() => { }} /> <Button small text1='Button' />
                  </HStack>
                </VStack>
              </Container>
            </HStack>
          </Container>
          <Caption1 color='text-secondary' align='center'>Divider</Caption1>
          <Divider />
          <Container variant='default'>
            <HStack>
              <Caption1 color='text-secondary'>Background Elevated</Caption1>
              <Divider vertical />
              <Container variant='fill'>
                <Body color='read-only'>Placeholder (read only)</Body>
              </Container>
            </HStack>
          </Container>
          <Caption1 color='text-secondary' align='center'>Read only</Caption1>
          <HStack align='middle center' gridDesktop={3} gridMobile={3} gridTablet={3}>
            <HStack></HStack>
            <HStack align='center middle'>
              <div
                style={{
                  background: 'rgba(var(--ts-color-read-only), var(--ts-color-read-only-alpha))',
                  width: '8px',
                  height: '8px',
                  borderRadius: '16px',
                  margin: 'calc(var(--ts-space-content) / 2)'
                }}
              />
              <div
                style={{
                  background: 'rgba(var(--ts-color-read-only), var(--ts-color-read-only-alpha))',
                  width: '8px',
                  height: '8px',
                  borderRadius: '16px',
                  margin: 'calc(var(--ts-space-content) / 2)'
                }}
              />
              <div
                style={{
                  background: 'rgba(var(--ts-color-primary), 1)',
                  width: '12px',
                  height: '12px',
                  borderRadius: '16px',
                  margin: 'calc(var(--ts-space-content) / 2)'
                }}
              />
              <div
                style={{
                  background: 'rgba(var(--ts-color-read-only), var(--ts-color-read-only-alpha))',
                  width: '8px',
                  height: '8px',
                  borderRadius: '16px',
                  margin: 'calc(var(--ts-space-content) / 2)'
                }}
              />
            </HStack>
            <HStack align='right'>
              <Button small variant='readOnly' text1='Read Only' width='hug' />
              <Button small variant='secondary' text1='Next' />
            </HStack>
          </HStack>
        </>}
      />



      <FoundationsPanel
        form={<>
          <Title3>Interactions</Title3>
          <HStack>
            <InputColor
              label='Hover'
              name='surfaceHover'
            />
            <InputAlpha
              name='surfaceHoverAlpha'
            />
          </HStack>
          <HStack>
            <InputColor
              label='Clicked/Down'
              name='surfaceDown'
            />
            <InputAlpha
              name='surfaceDownAlpha'
            />
          </HStack>
        </>}
        snippet={<>
          <Section>

            <HStack align='middle left' gridDesktop={2} gridMobile={1} gridTablet={2}>
              <VStack align='left'>
                <HStack align='left'>
                  <Tab
                    active
                    icon1='Placeholder'
                    text1='Tab 1'
                    small
                    stacked
                  />
                  <Tab
                    icon1='Placeholder'
                    text1='Tab 2'
                    small
                    stacked
                  />
                  <Tab
                    icon1='Placeholder'
                    text1='Tab 3'
                    small
                    stacked
                  />
                </HStack>
                <Button small icon1='CloseCircle' width='hug' text1='Delete' />
              </VStack>
              <VStack>
                <Accordion defaultActive parentContent={<>
                  <Headline>Parent</Headline>
                </>}>
                  <Container>
                    <Input placeholder='Placeholder' />
                  </Container>
                  <Container variant='outline'>
                    <Input placeholder='Placeholder' />
                  </Container>
                  <Space.Content />
                  <Button text1='Confirm' />
                  <HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
                    <Button text1='Delete' variant='destructive' />
                    <Button text1='Cancel' variant='tertiary' />
                  </HStack>
                </Accordion>
              </VStack>
            </HStack>
          </Section>
        </>}
      />


      <FoundationsPanel
        form={<>
          <Title3>Status colors</Title3>
          <HStack>
            <InputColor
              label='Positive'
              name='positive'
            />
            <InputAlpha
              hide
            />
          </HStack>
          <HStack>
            <InputColor
              label='Informative'
              name='informative'
            />
            <InputAlpha
              hide
            />
          </HStack>
          <HStack>
            <InputColor
              label='Notice'
              name='notice'
            />
            <InputAlpha
              hide
            />
          </HStack>
          <HStack>
            <InputColor
              label='Negative'
              name='negative'
            />
            <InputAlpha
              hide
            />
          </HStack>

        </>}
        snippet={<>
          <HStack align='middle'>
            <Icon name='CheckCircle' color='positive' />
            <Space.Content />
            <Body color='positive'>Good job!</Body>
          </HStack>
          <HStack align='middle'>
            <Icon name='Info' color='informative' />
            <Space.Content />
            <Body color='informative'>Processing your order...</Body>
          </HStack>
          <HStack align='middle'>
            <Icon name='Warning' color='notice' />
            <Space.Content />
            <Body color='notice'>We noticed some unusual activity</Body>
          </HStack>
          <HStack align='middle'>
            <Icon name='Trash' color='negative' />
            <Space.Content />
            <Body color='negative'>Are you sure you want to delete? <br />This cannot be undone.</Body>
          </HStack>
        </>}
      />

    </VStack>
  </>)
}

export default Color
