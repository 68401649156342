import { Container, HStack, Section, Title3, VStack, Headline, Divider, Title1, Title2, Space } from '@themeable/react-components'
import React from 'react'
import { useContextValue } from '../AppContext';
import { InputLetterSpacing, InputTextSize, InputWeight } from './InputTextSize';

function FoundationsPanel({ header, name, children }) {

return (
    <VStack grow>
        <Container grow >
			<Section>

				<Title2>{header}</Title2>
				<Headline>Compact</Headline>
				<HStack gridDesktop={4} gridMobile={4} gridTablet={4}>

					<InputTextSize 
						density='compact'
						label='Font Size'
						name={`${name}Size`}
						/>

					<InputTextSize 
						density='compact'
						label='Line Height'
                        name={`${name}LineHeight`}
						/>

					<InputLetterSpacing 
						density='compact'
						label='Letter Spacing'
                        name={`${name}LetterSpacing`}
						/>
					
				</HStack>
				
				<Headline>Medium</Headline>
				<HStack gridDesktop={4} gridMobile={4} gridTablet={4}>

					<InputTextSize 
						density='medium'
						label='Font Size'
						name={`${name}Size`}
						/>

					<InputTextSize 
						density='medium'
						label='Line Height'
                        name={`${name}LineHeight`}
						/>

					<InputLetterSpacing 
						density='medium'
						label='Letter Spacing'
                        name={`${name}LetterSpacing`}
						/>
					
				</HStack>
				
				<Headline>Expanded</Headline>
				<HStack gridDesktop={4} gridMobile={4} gridTablet={4}>

					<InputTextSize 
						density='expanded'
						label='Font Size'
						name={`${name}Size`}
						/>

					<InputTextSize 
						density='expanded'
						label='Line Height'
                        name={`${name}LineHeight`}
						/>

					<InputLetterSpacing 
						density='expanded'
						label='Letter Spacing'
                        name={`${name}LetterSpacing`}
						/>
					
				</HStack>

				<Divider />

				<Headline>Text Weight</Headline>
				<HStack gridDesktop={4} gridMobile={4} gridTablet={4}>

					<InputWeight 
						label='Default'
						name={`${name}`}
						/>
					<InputWeight 
						label='Light'
						name={`${name}Light`}
						/>
					<InputWeight 
						label='Regular'
						name={`${name}Regular`}
						/>
					<InputWeight 
						label='Bold'
						name={`${name}Bold`}
						/>

				</HStack>

                <Space.NewSection />

				<Container  variant='fill' style={{outline: '1px dotted rgba(var(--color-border), .4)', overflow: 'hidden', borderRadius: 'var(--ts-radius-container)', transition: 'all .4s'}}>
					<VStack style={{background: 'rgb(var(--ts-color-background-flat))'}}>
						<Section>
							<VStack align='middle'>
                                {children}
							</VStack>
						</Section>	
					</VStack>
				</Container>

			</Section>
		</Container>
    </VStack>
)}

export default FoundationsPanel