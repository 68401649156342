import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";

function ComponentSnippet({ thumbnail = false }) {
  const [size, setSize] = useState('Medium');
  const [switchValue, setSwitchValue] = useState(true);
  const [profile, setProfile] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [loop, setLoop] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [mute, setMute] = useState(false);

  const [showProfileImage, setShowProfileImage] = useState(true);

  const [altTag, setAltTag] = useState("This is the Media Component");
  const [showCaption, setShowCaption] = useState(false);
  const [customWidth, setCustomWidth] = useState('');
  const [fallbackColor, setFallbackColor] = useState('');
  const [type, setType] = useState('image');
  const [aspectRatio, setAspectRatio] = useState('1 / 1');
  const [alignCaption, setAlignCaption] = useState('left')

  const Design = (
    <>
      <Selector
        label='Size'
        prop={size} setProp={setSize}
        options={[
          'Small',
          'Medium',
          'Large',
          'Fill',
          'Custom'
        ]} />
      {size === 'Custom' &&
        <TextInput
          label='Custom size'
          placeholder='var(--size-base), 32px, 2rem, etc'
          value={customWidth} // Set value conditionally
          setProp={(e) => setCustomWidth(e.target.value)}
        />
      }
      <Divider />
      <SwitchRow
        prop={showCaption} setProp={setShowCaption}
        label='Show Caption'
      />
      {showCaption &&
        <ChipRow
          label='Align Caption'
          prop={alignCaption} setProp={setAlignCaption}
          options={['Left', 'Center', 'Right']} />
      }
      <Divider />
      <SwitchRow
        label='Profile Image'
        prop={profile}
        setProp={setProfile}
      />
      {profile ?
        <>
          <SwitchRow
            label='Show profile image'
            prop={showProfileImage}
            setProp={setShowProfileImage}
          />
        </>
        :
        <>
          <Divider />
          <TextInput
            label='Aspect Ratio'
            placeholder='1 / 1'
            prop={aspectRatio} setProp={(e) => setAspectRatio(e.target.value)}
          />
        </>
      }
    </>
  );

  const Content = (
    <>
      <ChipRow
        label='Media type'
        prop={type} setProp={setType}
        options={['Image', 'Video']} />
      {type === 'video' &&
        <>
          <Divider />
          <SwitchRow
            label='Play automatically'
            setProp={setAutoPlay} prop={autoPlay}
          />
          <SwitchRow
            label='Show video controls'
            prop={showControls} setProp={setShowControls} />
          <SwitchRow
            label='Repeat/Loop video'
            prop={loop} setProp={setLoop} />
          <SwitchRow
            label='Mute video'
            prop={mute} setProp={setMute} />

        </>
      }
      <Divider />
      <TextInput
        prop={altTag} setProp={(e) => setAltTag(e.target.value)}
        label='Caption / Alt Tag'
        placeholder="Don't forget the alt tag for screen readers!"
      />
    </>
  );


  const Technical = (
    <>
      <PropCallout propName='active' propType='boolean' />
      <PropCallout propName='className' propType='string' />
      <PropCallout propName='onChange' propType='(e) => setActive(e)' />
    </>
  );

  const SnippetComponent = (
    <Label width="hug" text1='Label text'  >
      <Switch
        onChange={(bool) => setSwitchValue(bool)}
        active={switchValue}
        className='demo'
      />
    </Label>
  );

  return (
    <>
      {thumbnail ? SnippetComponent :
        <TemplateVertical
          component={SnippetComponent}
          controls={[
            {
              name: 'Technical',
              controls: Technical
            },
            // {
            //   name: 'Design',
            //   controls: Design
            // },
            // {
            //   name: 'Content',
            //   controls: Content
            // },
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

