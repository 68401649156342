import { Button, Container, Divider, HStack, Loader, Section, Space, VStack } from '@themeable/react-components'
import React, { useState, useEffect } from 'react'
import TreeArrow from '../../modules/TreeArrow';
import { useContextValue } from '../../AppContext';
import { useParams, useNavigate } from 'react-router-dom';

function FoundationsNav() {
  const navigate = useNavigate();
  const { context, setContext } = useContextValue();
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [activeL2, setActiveL2] = useState('color');
  const [activeL3, setActiveL3] = useState('')
  const [colorMode, setColorMode] = useState('light');
  const [density, setDensity] = useState('compact');
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      const params = new URLSearchParams(token);
      if (params.has('subpage')) {
        setContext(prev => ({
          ...prev,
          subpage: decodeURIComponent(params.get('subpage'))
        }));
        setActiveL2(decodeURIComponent(params.get('subpage')));
      }
      if (params.has('colorMode')) {
        setContext(prev => ({
          ...prev,
          colorMode: decodeURIComponent(params.get('colorMode'))
        }));
        setColorMode(decodeURIComponent(params.get('colorMode')));
      }
      if (params.has('density')) {
        setContext(prev => ({
          ...prev,
          density: decodeURIComponent(params.get('density'))
        }));
        setDensity(decodeURIComponent(params.get('density')));
      }
      if (params.has('l3')) {
        setContext(prev => ({
          ...prev,
          activeL3: decodeURIComponent(params.get('l3'))
        }));
        setActiveL3(decodeURIComponent(params.get('l3')));
      }
    }
    // Set loading to false once URL parameters are processed
    setLoading(false);
  }, [token]);

  useEffect(() => {
    if (!loading) {
      updateURL(density, colorMode, activeL2, activeL3);
    }
  }, [density, colorMode, activeL2, loading, activeL3]);

  const updateURL = (density, colorMode, activeL2, activeL3) => {
    const params = new URLSearchParams();
    params.set('systemid', context.selectedSystem);
    params.set('page', context.page);
    params.set('subpage', activeL2);
    params.set('density', density);
    params.set('colorMode', colorMode);
    params.set('l3', activeL3);
    navigate(`../${params.toString()}`);
  };

  if (loading) {
    return <Loader />;
  }


  return (
    <VStack width='hug group' align='left' style={{ position: 'sticky', top: '64px' }}>

      <Button
        variant={activeL2 === 'color' ? 'primary' : 'secondary'}
        text1='Color'
        width='hug'
        onClick={() => { setActiveL2('color'); setActiveL3(''); }}
        small
      />
      {activeL2 === 'color' ? (<>
        <VStack align='top left'>
          <HStack>
            <TreeArrow />
            <Button
              variant={colorMode === 'light' ? 'primary' : 'secondary'}
              text1='Light'
              width='hug'
              onClick={() => { setColorMode('light') }}
              small
            />
          </HStack>
          <HStack>
            <TreeArrow lastChild />
            <Button
              variant={colorMode === 'dark' ? 'primary' : 'secondary'}
              text1='Dark'
              width='hug'
              onClick={() => { setColorMode('dark') }}
              small
            />
          </HStack>
          <Divider />
        </VStack>
      </>) : ''}

      <Button
        variant={activeL2 === 'size' ? 'primary' : 'secondary'}
        text1='Size'
        width='hug'
        onClick={() => { setActiveL2('size'); setActiveL3(''); }}
        small
      />
      {activeL2 === 'size' ? (<>
        <VStack align='top left'>
          <HStack>
            <TreeArrow />
            <Button
              variant={density === 'compact' ? 'primary' : 'secondary'}
              text1='Compact'
              width='hug'
              onClick={() => { setDensity('compact') }}
              small
            />
          </HStack>
          <HStack>
            <TreeArrow />
            <Button
              variant={density === 'medium' ? 'primary' : 'secondary'}
              text1='Medium'
              width='hug'
              onClick={() => { setDensity('medium') }}
              small
            />
          </HStack>
          <HStack>
            <TreeArrow lastChild />
            <Button
              variant={density === 'expanded' ? 'primary' : 'secondary'}
              text1='Expanded'
              width='hug'
              onClick={() => { setDensity('expanded') }}
              small
            />
          </HStack>
          <Divider />
        </VStack>
      </>) : ''}



      <Button
        variant={activeL2 === 'space' ? 'primary' : 'secondary'}
        text1='Space'
        onClick={() => { setActiveL2('space'); setActiveL3('') }}
        small
        width='hug'
      />
      {activeL2 === 'space' ? (<>
        <VStack align='top left'>
          <HStack>
            <TreeArrow />
            <Button
              variant={density === 'compact' ? 'primary' : 'secondary'}
              text1='Compact'
              width='hug'
              onClick={() => { setDensity('compact') }}
              small
            />
          </HStack>
          <HStack>
            <TreeArrow />
            <Button
              variant={density === 'medium' ? 'primary' : 'secondary'}
              text1='Medium'
              width='hug'
              onClick={() => { setDensity('medium') }}
              small
            />
          </HStack>
          <HStack>
            <TreeArrow lastChild />
            <Button
              variant={density === 'expanded' ? 'primary' : 'secondary'}
              text1='Expanded'
              width='hug'
              onClick={() => { setDensity('expanded') }}
              small
            />
          </HStack>
          <Divider />
        </VStack>
      </>) : ''}


      <Button
        variant={activeL2 === 'radius' ? 'primary' : 'secondary'}
        text1='Radius'
        width='hug'
        onClick={() => { setActiveL2('radius'); setActiveL3('') }}
        small
      />
      {activeL2 === 'radius' ? (<>
        <VStack align='top left'>
          <HStack>
            <TreeArrow />
            <Button
              variant={density === 'compact' ? 'primary' : 'secondary'}
              text1='Compact'
              width='hug'
              onClick={() => { setDensity('compact') }}
              small
            />
          </HStack>
          <HStack>
            <TreeArrow />
            <Button
              variant={density === 'medium' ? 'primary' : 'secondary'}
              text1='Medium'
              width='hug'
              onClick={() => { setDensity('medium') }}
              small
            />
          </HStack>
          <HStack>
            <TreeArrow lastChild />
            <Button
              variant={density === 'expanded' ? 'primary' : 'secondary'}
              text1='Expanded'
              width='hug'
              onClick={() => { setDensity('expanded') }}
              small
            />
          </HStack>
          <Divider />
        </VStack>
      </>) : ''}









      <Button
        variant={activeL2 === 'text' ? 'primary' : 'secondary'}
        text1='Text'
        width='hug'
        onClick={() => { setActiveL2('text'); setActiveL3('families') }}
        small
      />
      {activeL2 === 'text' ? (<>
        <VStack align='top left'>
          <HStack>
            <TreeArrow />
            <Button
              variant={activeL3 === 'families' ? 'primary' : 'secondary'}
              text1='Families'
              width='hug'
              onClick={() => { setActiveL3('families') }}
              small
            />
          </HStack>
          <HStack>
            <TreeArrow lastChild />
            <Button
              variant={activeL3 === 'styles' ? 'primary' : 'secondary'}
              text1='Styles'
              width='hug'
              onClick={() => { setActiveL3('styles') }}
              small
            />
          </HStack>
        </VStack>
      </>) : ''}


    </VStack>
  )
}

export default FoundationsNav
