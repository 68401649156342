import { systemTemplates } from './SystemTemplates';
import { db, auth, functions } from '../../services/firebase';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc, arrayUnion, deleteDoc, Timestamp, arrayRemove } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';
const createAndUploadCSS = httpsCallable(functions, 'createAndUploadCSS');


const timestampToDate = (timestamp) => {
  // Convert seconds and nanoseconds to milliseconds
  if (timestamp) {
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;

    // Create a new Date object for the timestamp
    const date = new Date(milliseconds);

    // Get the current time
    const now = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = now.getTime() - date.getTime();

    // Convert time difference to different units
    const secondsDifference = timeDifference / 1000;
    const minutesDifference = secondsDifference / 60;
    const hoursDifference = minutesDifference / 60;
    const daysDifference = hoursDifference / 24;

    // Format the date based on the time difference
    if (secondsDifference < 60) {
      return `a few seconds ago`;
    } else if (minutesDifference < 60) {
      return `${Math.floor(minutesDifference)}m ago`;
    } else if (hoursDifference < 24) {
      return `${Math.floor(hoursDifference)}hr ago`;
    } else if (daysDifference < 7) {
      return `${Math.floor(daysDifference)}d ago`;
    } else {
      // Get the components of the date (month, day, year)
      const month = date.getMonth() + 1; // Months are zero-based, so we add 1
      const day = date.getDate();
      const year = date.getFullYear() % 100; // Get only the last two digits of the year

      // Format the date as mm/dd/yy
      return `${month}/${day}/${year}`;
    }
  }
};

const CreateNewSystem = async (optionalId) => {
  // Ensure user is authenticated
  if (!auth.currentUser) {
    throw new Error('User must be authenticated to create a system');
  }

  const newSystemRef = doc(collection(db, 'systems')); // Generate a new system document with auto-ID
  const sourceSystemId = optionalId ? optionalId : "RITkioUXdZBmVM5W2z8Z";

  try {
    // Step 1: Fetch the source system data
    const sourceSystemRef = doc(db, 'systems', sourceSystemId);
    const sourceSystemDoc = await getDoc(sourceSystemRef);

    if (!sourceSystemDoc.exists()) {
      throw new Error(`Source system ${sourceSystemId} not found`);
    }

    const sourceSystemData = sourceSystemDoc.data();

    // Step 2: Define the new system data based on the source system
    const systemData = {
      owner: auth.currentUser.uid, // Set to current user
      name: `New System`, // Append "(copy)" to the original name
      foundations: sourceSystemData.foundations, // Copy foundations
      createdOn: Timestamp.now(),
      lastModified: Timestamp.now(),
    };

    // Step 3: Create the new system document
    await setDoc(newSystemRef, systemData);
    console.log('New system created with ID:', newSystemRef.id);

    // Step 4: Update the user's subscribed systems
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, {
      'systems.subscribed': arrayUnion(newSystemRef.id),
    });
    console.log('User subscribed systems updated');

    // Step 5: Copy the components from the source system
    const sourceComponentsRef = collection(db, 'systems', sourceSystemId, 'components');
    const sourceComponentsSnapshot = await getDocs(sourceComponentsRef);

    if (!sourceComponentsSnapshot.empty) {
      const targetComponentsRef = collection(db, 'systems', newSystemRef.id, 'components');
      const copyPromises = [];

      sourceComponentsSnapshot.forEach((docSnapshot) => {
        const componentData = docSnapshot.data();
        const newComponentRef = doc(targetComponentsRef); // Auto-generate ID for each component
        copyPromises.push(setDoc(newComponentRef, componentData));
      });

      await Promise.all(copyPromises);
      console.log(`Copied ${copyPromises.length} components to new system ${newSystemRef.id}`);
    } else {
      console.log('No components found in source system to copy');
    }

    // Step 6: Navigate to the new system
    const url = `../systemid=${newSystemRef.id}`;
    window.location.href = url;
    console.log('Navigating to:', url);

    return newSystemRef.id; // Return the new system ID
  } catch (error) {
    console.error('Error creating system or copying components:', error);
    throw error; // Propagate the error for further handling
  }
};

function hexToRGB(hex) {
  // Remove the "#" character from the hex code if it's there
  hex = hex.replace(/^#/, '');

  // Parse the red, green, and blue values from the hex code
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}


const AddAltThemeToDom = (altTheme, cColor, cRadius, cSpace, cSize, cText) => {


  // Function to recursively merge two objects
  function mergeObjects(target, source) {

    for (const key in source) {
      if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
        // If the key exists in both objects and is an object, merge recursively
        if (target.hasOwnProperty(key) && typeof target[key] === 'object' && !Array.isArray(target[key])) {
          mergeObjects(target[key], source[key]);
        } else {
          // Otherwise, assign the value directly
          target[key] = source[key];
        }
      } else {
        // If the key exists in the target and is not blank, override
        if (target.hasOwnProperty(key) && source[key] !== "") {
          target[key] = source[key];
        }
      }
    }
  }


  mergeObjects(altTheme.color.light, cColor.light);
  mergeObjects(altTheme.color.dark, cColor.dark);

  mergeObjects(altTheme.radius.compact, cRadius.compact);
  mergeObjects(altTheme.radius.medium, cRadius.medium);
  mergeObjects(altTheme.radius.expanded, cRadius.expanded);

  mergeObjects(altTheme.space.compact, cSpace.compact);
  mergeObjects(altTheme.space.medium, cSpace.medium);
  mergeObjects(altTheme.space.expanded, cSpace.expanded);

  mergeObjects(altTheme.size.compact, cSize.compact);
  mergeObjects(altTheme.size.medium, cSize.medium);
  mergeObjects(altTheme.size.expanded, cSize.expanded);
  mergeObjects(altTheme.size.responsive, cSize.resolution);

  mergeObjects(altTheme.text.weight, cText.weight);
  mergeObjects(altTheme.text.compact, cText.compact);
  mergeObjects(altTheme.text.medium, cText.medium);
  mergeObjects(altTheme.text.expanded, cText.expanded);
  mergeObjects(altTheme.text.family, cText.family);

  // Check if a style tag with the id 'alt-theme' already exists
  let existingStyle = document.getElementById('alt-theme');
  if (existingStyle) {
    // If the style exists, remove it
    existingStyle.remove();
  }

  // Create a new style tag
  let style = document.createElement('style');
  style.id = 'alt-theme';





  const colorL = altTheme.color.light
  const colorD = altTheme.color.dark
  const radius = altTheme.radius
  const size = altTheme.size
  const space = altTheme.space
  const text = altTheme.text
  const textFamily = cText?.family

  style.textContent = `
    
    @import url("${textFamily.src}");
    @import url("${textFamily.titleSrc}");
    
    :root .alt-theme {
        --font-title-family: ${textFamily?.titleName !== '' ? `${textFamily?.titleName}, ` : ''} ${text.family?.titleFallback};
        --font-family:  ${textFamily?.name !== '' ? `${textFamily?.name}, ` : ''} ${text?.family?.fallback};

        --font-title1-light: ${text.weight.title1Light};
        --font-title1-regular: ${text.weight.title1Regular};
        --font-title1-bold: ${text.weight.title1Bold};
        --font-title2-light: ${text.weight.title2Light};
        --font-title2-regular: ${text.weight.title2Regular};
        --font-title2-bold: ${text.weight.title2Bold};
        --font-title3-light: ${text.weight.title3Light};
        --font-title3-regular: ${text.weight.title3Regular};
        --font-title3-bold: ${text.weight.title3Bold};
        --font-headline-light: ${text.weight.headlineLight};
        --font-headline-regular: ${text.weight.headlineRegular};
        --font-headline-bold: ${text.weight.headlineBold};
        --font-body-light: ${text.weight.bodyLight};
        --font-body-regular: ${text.weight.bodyRegular};
        --font-body-bold: ${text.weight.bodyBold};
        --font-subhead-light: ${text.weight.subheadLight};
        --font-subhead-regular: ${text.weight.subheadRegular};
        --font-subhead-bold: ${text.weight.subheadBold};
        --font-footnote-light: ${text.weight.footnoteLight};
        --font-footnote-regular: ${text.weight.footnoteRegular};
        --font-footnote-bold: ${text.weight.footnoteBold};
        --font-caption1-light: ${text.weight.caption1Light};
        --font-caption1-regular: ${text.weight.caption1Regular};
        --font-caption1-bold: ${text.weight.caption1Bold};
        --font-caption2-light: ${text.weight.caption2Light};
        --font-caption2-regular: ${text.weight.caption2Regular};
        --font-caption2-bold: ${text.weight.caption2Bold};
    }

    :root .alt-theme * {
        transition: all .2s;
    }

    @media (prefers-color-scheme: light) {
        :root .alt-theme {
            --color-primary: ${hexToRGB(colorL.primary)};
            --color-primary-alpha: ${colorL.primaryAlpha};
            --color-on-primary: ${hexToRGB(colorL.onPrimary)};
            --color-accent: ${hexToRGB(colorL.accent)};
            --color-accent-alpha: ${colorL.accentAlpha};
            --color-on-accent: ${hexToRGB(colorL.onAccent)};
            --color-text-primary: ${hexToRGB(colorL.textPrimary)};
            --color-text-primary-alpha: ${colorL.textPrimaryAlpha};
            --color-text-secondary: ${hexToRGB(colorL.textSecondary)};
            --color-text-secondary-alpha: ${colorL.textSecondaryAlpha};
            --color-background-flat: ${hexToRGB(colorL.backgroundFlat)};
            --color-background-elevated: ${hexToRGB(colorL.backgroundElevated)};
            --color-surface-hover: ${hexToRGB(colorL.surfaceHover)};
            --color-surface-hover-alpha: ${colorL.surfaceHoverAlpha};
            --color-surface-down: ${hexToRGB(colorL.surfaceDown)};
            --color-surface-down-alpha: ${colorL.surfaceDownAlpha};
            --color-fill-control: ${hexToRGB(colorL.fillControl)};
            --color-fill-control-alpha: ${colorL.fillControlAlpha};
            --color-fill-container: ${hexToRGB(colorL.fillContainer)};
            --color-fill-container-alpha: ${colorL.fillContainerAlpha};
            --color-positive: ${hexToRGB(colorL.positive)};
            --color-negative: ${hexToRGB(colorL.negative)};
            --color-informative: ${hexToRGB(colorL.informative)};
            --color-notice: ${hexToRGB(colorL.notice)};
            --color-icon: ${hexToRGB(colorL.icon)};
            --color-icon-alpha: ${colorL.iconAlpha};
            --color-border: ${hexToRGB(colorL.border)};
            --color-border-alpha: ${colorL.borderAlpha};
            --color-divider: ${hexToRGB(colorL.divider)};
            --color-divider-alpha: ${colorL.dividerAlpha};
            --color-read-only: ${hexToRGB(colorL.readOnly)};
            --color-read-only-alpha: ${colorL.readOnlyAlpha};
        }
    }
    
    @media (prefers-color-scheme: dark) {
        :root .alt-theme {
            --color-primary: ${hexToRGB(colorD.primary)};
            --color-primary-alpha: ${colorD.primaryAlpha};
            --color-on-primary: ${hexToRGB(colorD.onPrimary)};
            --color-accent: ${hexToRGB(colorD.accent)};
            --color-accent-alpha: ${colorD.accentAlpha};
            --color-on-accent: ${hexToRGB(colorD.onAccent)};
            --color-text-primary: ${hexToRGB(colorD.textPrimary)};
            --color-text-primary-alpha: ${colorD.textPrimaryAlpha};
            --color-text-secondary: ${hexToRGB(colorD.textSecondary)};
            --color-text-secondary-alpha: ${colorD.textSecondaryAlpha};
            --color-background-flat: ${hexToRGB(colorD.backgroundFlat)};
            --color-background-elevated: ${hexToRGB(colorD.backgroundElevated)};
            --color-surface-hover: ${hexToRGB(colorD.surfaceHover)};
            --color-surface-hover-alpha: ${colorD.surfaceHoverAlpha};
            --color-surface-down: ${hexToRGB(colorD.surfaceDown)};
            --color-surface-down-alpha: ${colorD.surfaceDownAlpha};
            --color-fill-control: ${hexToRGB(colorD.fillControl)};
            --color-fill-control-alpha: ${colorD.fillControlAlpha};
            --color-fill-container: ${hexToRGB(colorD.fillContainer)};
            --color-fill-container-alpha: ${colorD.fillContainerAlpha};
            --color-positive: ${hexToRGB(colorD.positive)};
            --color-negative: ${hexToRGB(colorD.negative)};
            --color-informative: ${hexToRGB(colorD.informative)};
            --color-notice: ${hexToRGB(colorD.notice)};
            --color-icon: ${hexToRGB(colorD.icon)};
            --color-icon-alpha: ${colorD.iconAlpha};
            --color-border: ${hexToRGB(colorD.border)};
            --color-border-alpha: ${colorD.borderAlpha};
            --color-divider: ${hexToRGB(colorD.divider)};
            --color-divider-alpha: ${colorD.dividerAlpha};
            --color-read-only: ${hexToRGB(colorD.readOnly)};
            --color-read-only-alpha: ${colorD.readOnlyAlpha};
        }
    }

    :root .alt-theme.light {
            --color-primary: ${hexToRGB(colorL.primary)};
            --color-primary-alpha: ${colorL.primaryAlpha};
            --color-on-primary: ${hexToRGB(colorL.onPrimary)};
            --color-accent: ${hexToRGB(colorL.accent)};
            --color-accent-alpha: ${colorL.accentAlpha};
            --color-on-accent: ${hexToRGB(colorL.onAccent)};
            --color-text-primary: ${hexToRGB(colorL.textPrimary)};
            --color-text-primary-alpha: ${colorL.textPrimaryAlpha};
            --color-text-secondary: ${hexToRGB(colorL.textSecondary)};
            --color-text-secondary-alpha: ${colorL.textSecondaryAlpha};
            --color-background-flat: ${hexToRGB(colorL.backgroundFlat)};
            --color-background-elevated: ${hexToRGB(colorL.backgroundElevated)};
            --color-surface-hover: ${hexToRGB(colorL.surfaceHover)};
            --color-surface-hover-alpha: ${colorL.surfaceHoverAlpha};
            --color-surface-down: ${hexToRGB(colorL.surfaceDown)};
            --color-surface-down-alpha: ${colorL.surfaceDownAlpha};
            --color-fill-control: ${hexToRGB(colorL.fillControl)};
            --color-fill-control-alpha: ${colorL.fillControlAlpha};
            --color-fill-container: ${hexToRGB(colorL.fillContainer)};
            --color-fill-container-alpha: ${colorL.fillContainerAlpha};
            --color-positive: ${hexToRGB(colorL.positive)};
            --color-negative: ${hexToRGB(colorL.negative)};
            --color-informative: ${hexToRGB(colorL.informative)};
            --color-notice: ${hexToRGB(colorL.notice)};
            --color-icon: ${hexToRGB(colorL.icon)};
            --color-icon-alpha: ${colorL.iconAlpha};
            --color-border: ${hexToRGB(colorL.border)};
            --color-border-alpha: ${colorL.borderAlpha};
            --color-divider: ${hexToRGB(colorL.divider)};
            --color-divider-alpha: ${colorL.dividerAlpha};
            --color-read-only: ${hexToRGB(colorL.readOnly)};
            --color-read-only-alpha: ${colorL.readOnlyAlpha};
        }
        :root .alt-theme.dark {
            --color-primary: ${hexToRGB(colorD.primary)};
            --color-primary-alpha: ${colorD.primaryAlpha};
            --color-on-primary: ${hexToRGB(colorD.onPrimary)};
            --color-accent: ${hexToRGB(colorD.accent)};
            --color-accent-alpha: ${colorD.accentAlpha};
            --color-on-accent: ${hexToRGB(colorD.onAccent)};
            --color-text-primary: ${hexToRGB(colorD.textPrimary)};
            --color-text-primary-alpha: ${colorD.textPrimaryAlpha};
            --color-text-secondary: ${hexToRGB(colorD.textSecondary)};
            --color-text-secondary-alpha: ${colorD.textSecondaryAlpha};
            --color-background-flat: ${hexToRGB(colorD.backgroundFlat)};
            --color-background-elevated: ${hexToRGB(colorD.backgroundElevated)};
            --color-surface-hover: ${hexToRGB(colorD.surfaceHover)};
            --color-surface-hover-alpha: ${colorD.surfaceHoverAlpha};
            --color-surface-down: ${hexToRGB(colorD.surfaceDown)};
            --color-surface-down-alpha: ${colorD.surfaceDownAlpha};
            --color-fill-control: ${hexToRGB(colorD.fillControl)};
            --color-fill-control-alpha: ${colorD.fillControlAlpha};
            --color-fill-container: ${hexToRGB(colorD.fillContainer)};
            --color-fill-container-alpha: ${colorD.fillContainerAlpha};
            --color-positive: ${hexToRGB(colorD.positive)};
            --color-negative: ${hexToRGB(colorD.negative)};
            --color-informative: ${hexToRGB(colorD.informative)};
            --color-notice: ${hexToRGB(colorD.notice)};
            --color-icon: ${hexToRGB(colorD.icon)};
            --color-icon-alpha: ${colorD.iconAlpha};
            --color-border: ${hexToRGB(colorD.border)};
            --color-border-alpha: ${colorD.borderAlpha};
            --color-divider: ${hexToRGB(colorD.divider)};
            --color-divider-alpha: ${colorD.dividerAlpha};
            --color-read-only: ${hexToRGB(colorD.readOnly)};
            --color-read-only-alpha: ${colorD.readOnlyAlpha};
        }
        
        @media screen and (max-width: 720px){
            :root .alt-theme {
                --space-v: ${parseInt(space.compact.vExtra) + parseInt(space.compact.vUnify)}px;
                --space-h: ${parseInt(space.compact.hExtra) + parseInt(space.compact.hUnify)}px;
                --space-v-small: ${space.compact.vSmall}px;
                --space-v-extra: ${parseInt(space.compact.vExtra)}px;
                --space-v-section: ${space.compact.vSection}px;
                --space-v-unify: ${parseInt(space.compact.vUnify)}px;
                --space-h-section: ${space.compact.hSection}px;
                --space-h-small: ${space.compact.hSmall}px;
                --space-h-extra: ${space.compact.hExtra}px;
                --space-h-unify: ${space.compact.hUnify}px;
                --space-content: ${space.compact.content}px;
                --size-base: ${size.compact.base}px;
                --size-small: ${size.compact.small}px;
                --size-large: ${size.compact.large}px;
                --radius-small-control: ${radius.compact.smallControl}px;
                --radius-large-control: ${radius.compact.largeControl}px;
                --radius-container: ${radius.compact.container}px;
                --radius-page: ${radius.compact.page}px;
                --font-title1: ${text.weight.title1} ${text.compact.title1Size / 16}rem/${text.compact.title1LineHeight / 16}rem var(--font-title-family);
                --font-title1-letter-spacing: ${text.compact.title1LetterSpacing}em;
                --font-title2: ${text.weight.title2} ${text.compact.title2Size / 16}rem/${text.compact.title2LineHeight / 16}rem var(--font-title-family);
                --font-title2-letter-spacing: ${text.compact.title2LetterSpacing}em;
                --font-title3: ${text.weight.title3} ${text.compact.title3Size / 16}rem/${text.compact.title3LineHeight / 16}rem var(--font-title-family);
                --font-title3-letter-spacing: ${text.compact.title3LetterSpacing}em;
                --font-headline: ${text.weight.headline} ${text.compact.headlineSize / 16}rem/${text.compact.headlineLineHeight / 16}rem var(--font-family);
                --font-headline-letter-spacing: ${text.compact.headlineLetterSpacing}em;
                --font-body: ${text.weight.body} ${text.compact.bodySize / 16}rem/${text.compact.bodyLineHeight / 16}rem var(--font-family);
                --font-body-letter-spacing: ${text.compact.bodyLetterSpacing}em;
                --font-subhead: ${text.weight.subhead} ${text.compact.subheadSize / 16}rem/${text.compact.subheadLineHeight / 16}rem var(--font-family);
                --font-subhead-letter-spacing: ${text.compact.subheadLetterSpacing}em;
                --font-footnote: ${text.weight.footnote} ${text.compact.footnoteSize / 16}rem/${text.compact.footnoteLineHeight / 16}rem var(--font-family);
                --font-footnote-letter-spacing: ${text.compact.footnoteLetterSpacing}em;
                --font-caption1: ${text.weight.caption1} ${text.compact.caption1Size / 16}rem/${text.compact.caption1LineHeight / 16}rem var(--font-family);
                --font-caption1-letter-spacing: ${text.compact.caption1LetterSpacing}em;
                --font-caption2: ${text.weight.caption2} ${text.compact.caption2Size / 16}rem/${text.compact.caption2LineHeight / 16}rem var(--font-family);
                --font-caption2-letter-spacing: ${text.compact.caption2LetterSpacing}em;
            }
        }
        :root .alt-theme.compact {
            --space-v: ${parseInt(space.compact.vExtra) + parseInt(space.compact.vUnify)}px;
            --space-h: ${parseInt(space.compact.hExtra) + parseInt(space.compact.hUnify)}px;
            --space-v-small: ${space.compact.vSmall}px;
            --space-v-extra: ${parseInt(space.compact.vExtra)}px;
            --space-v-section: ${space.compact.vSection}px;
            --space-v-unify: ${parseInt(space.compact.vUnify)}px;
            --space-h-section: ${space.compact.hSection}px;
            --space-h-small: ${space.compact.hSmall}px;
            --space-h-extra: ${space.compact.hExtra}px;
            --space-h-unify: ${space.compact.hUnify}px;
            --space-content: ${space.compact.content}px;
            --size-base: ${size.compact.base}px;
            --size-small: ${size.compact.small}px;
            --size-large: ${size.compact.large}px;
            --radius-small-control: ${radius.compact.smallControl}px;
            --radius-large-control: ${radius.compact.largeControl}px;
            --radius-container: ${radius.compact.container}px;
            --radius-page: ${radius.compact.page}px;
            --font-title1: ${text.weight.title1} ${text.compact.title1Size / 16}rem/${text.compact.title1LineHeight / 16}rem var(--font-title-family);
            --font-title1-letter-spacing: ${text.compact.title1LetterSpacing}em;
            --font-title2: ${text.weight.title2} ${text.compact.title2Size / 16}rem/${text.compact.title2LineHeight / 16}rem var(--font-title-family);
            --font-title2-letter-spacing: ${text.compact.title2LetterSpacing}em;
            --font-title3: ${text.weight.title3} ${text.compact.title3Size / 16}rem/${text.compact.title3LineHeight / 16}rem var(--font-title-family);
            --font-title3-letter-spacing: ${text.compact.title3LetterSpacing}em;
            --font-headline: ${text.weight.headline} ${text.compact.headlineSize / 16}rem/${text.compact.headlineLineHeight / 16}rem var(--font-family);
            --font-headline-letter-spacing: ${text.compact.headlineLetterSpacing}em;
            --font-body: ${text.weight.body} ${text.compact.bodySize / 16}rem/${text.compact.bodyLineHeight / 16}rem var(--font-family);
            --font-body-letter-spacing: ${text.compact.bodyLetterSpacing}em;
            --font-subhead: ${text.weight.subhead} ${text.compact.subheadSize / 16}rem/${text.compact.subheadLineHeight / 16}rem var(--font-family);
            --font-subhead-letter-spacing: ${text.compact.subheadLetterSpacing}em;
            --font-footnote: ${text.weight.footnote} ${text.compact.footnoteSize / 16}rem/${text.compact.footnoteLineHeight / 16}rem var(--font-family);
            --font-footnote-letter-spacing: ${text.compact.footnoteLetterSpacing}em;
            --font-caption1: ${text.weight.caption1} ${text.compact.caption1Size / 16}rem/${text.compact.caption1LineHeight / 16}rem var(--font-family);
            --font-caption1-letter-spacing: ${text.compact.caption1LetterSpacing}em;
            --font-caption2: ${text.weight.caption2} ${text.compact.caption2Size / 16}rem/${text.compact.caption2LineHeight / 16}rem var(--font-family);
            --font-caption2-letter-spacing: ${text.compact.caption2LetterSpacing}em;
        }
        @media screen and (min-width: 721px) and (max-width: 1200px){
            :root .alt-theme {
                --space-v: ${parseInt(space.medium.vExtra) + parseInt(space.medium.vUnify)}px;
                --space-v-small: ${space.medium.vSmall}px;
                --space-v-extra: ${parseInt(space.medium.vExtra)}px;
                --space-v-section: ${space.medium.vSection}px;
                --space-v-unify: ${parseInt(space.medium.vUnify)}px;
                --space-h: ${parseInt(space.medium.hExtra) + parseInt(space.medium.hUnify)}px;
                --space-h-section: ${space.medium.hSection}px;
                --space-h-small: ${space.medium.hSmall}px;
                --space-h-extra: ${space.medium.hExtra}px;
                --space-h-unify: ${space.medium.hUnify}px;
                --space-content: ${space.medium.content}px;
                --size-base: ${size.medium.base}px;
                --size-small: ${size.medium.small}px;
                --size-large: ${size.medium.large}px;
                --radius-small-control: ${radius.medium.smallControl}px;
                --radius-large-control: ${radius.medium.largeControl}px;
                --radius-container: ${radius.medium.container}px;
                --radius-page: ${radius.medium.page}px;
                --font-title1: ${text.weight.title1} ${text.medium.title1Size / 16}rem/${text.medium.title1LineHeight / 16}rem var(--font-title-family);
                --font-title1-letter-spacing: ${text.medium.title1LetterSpacing}em;
                --font-title2: ${text.weight.title2} ${text.medium.title2Size / 16}rem/${text.medium.title2LineHeight / 16}rem var(--font-title-family);
                --font-title2-letter-spacing: ${text.medium.title2LetterSpacing}em;
                --font-title3: ${text.weight.title3} ${text.medium.title3Size / 16}rem/${text.medium.title3LineHeight / 16}rem var(--font-title-family);
                --font-title3-letter-spacing: ${text.medium.title3LetterSpacing}em;
                --font-headline: ${text.weight.headline} ${text.medium.headlineSize / 16}rem/${text.medium.headlineLineHeight / 16}rem var(--font-family);
                --font-headline-letter-spacing: ${text.medium.headlineLetterSpacing}em;
                --font-body: ${text.weight.body} ${text.medium.bodySize / 16}rem/${text.medium.bodyLineHeight / 16}rem var(--font-family);
                --font-body-letter-spacing: ${text.medium.bodyLetterSpacing}em;
                --font-subhead: ${text.weight.subhead} ${text.medium.subheadSize / 16}rem/${text.medium.subheadLineHeight / 16}rem var(--font-family);
                --font-subhead-letter-spacing: ${text.medium.subheadLetterSpacing}em;
                --font-footnote: ${text.weight.footnote} ${text.medium.footnoteSize / 16}rem/${text.medium.footnoteLineHeight / 16}rem var(--font-family);
                --font-footnote-letter-spacing: ${text.medium.footnoteLetterSpacing}em;
                --font-caption1: ${text.weight.caption1} ${text.medium.caption1Size / 16}rem/${text.medium.caption1LineHeight / 16}rem var(--font-family);
                --font-caption1-letter-spacing: ${text.medium.caption1LetterSpacing}em;
                --font-caption2: ${text.weight.caption2} ${text.medium.caption2Size / 16}rem/${text.medium.caption2LineHeight / 16}rem var(--font-family);
                --font-caption2-letter-spacing: ${text.medium.caption2LetterSpacing}em;
            }
        }
        :root .alt-theme.medium {
            --space-v: ${parseInt(space.medium.vExtra) + parseInt(space.medium.vUnify)}px;
            --space-v-small: ${space.medium.vSmall}px;
            --space-v-extra: ${parseInt(space.medium.vExtra)}px;
            --space-v-unify: ${parseInt(space.medium.vUnify)}px;
            --space-v-section: ${space.medium.vSection}px;
            --space-h: ${parseInt(space.medium.hExtra) + parseInt(space.medium.hUnify)}px;
            --space-h-section: ${space.medium.hSection}px;
            --space-h-small: ${space.medium.hSmall}px;
            --space-h-extra: ${space.medium.hExtra}px;
            --space-h-unify: ${space.medium.hUnify}px;
            --space-content: ${space.medium.content}px;
            --size-base: ${size.medium.base}px;
            --size-small: ${size.medium.small}px;
            --size-large: ${size.medium.large}px;
            --radius-small-control: ${radius.medium.smallControl}px;
            --radius-large-control: ${radius.medium.largeControl}px;
            --radius-container: ${radius.medium.container}px;
            --radius-page: ${radius.medium.page}px;
            --font-title1: ${text.weight.title1} ${text.medium.title1Size / 16}rem/${text.medium.title1LineHeight / 16}rem var(--font-title-family);
            --font-title1-letter-spacing: ${text.medium.title1LetterSpacing}em;
            --font-title2: ${text.weight.title2} ${text.medium.title2Size / 16}rem/${text.medium.title2LineHeight / 16}rem var(--font-title-family);
            --font-title2-letter-spacing: ${text.medium.title2LetterSpacing}em;
            --font-title3: ${text.weight.title3} ${text.medium.title3Size / 16}rem/${text.medium.title3LineHeight / 16}rem var(--font-title-family);
            --font-title3-letter-spacing: ${text.medium.title3LetterSpacing}em;
            --font-headline: ${text.weight.headline} ${text.medium.headlineSize / 16}rem/${text.medium.headlineLineHeight / 16}rem var(--font-family);
            --font-headline-letter-spacing: ${text.medium.headlineLetterSpacing}em;
            --font-body: ${text.weight.body} ${text.medium.bodySize / 16}rem/${text.medium.bodyLineHeight / 16}rem var(--font-family);
            --font-body-letter-spacing: ${text.medium.bodyLetterSpacing}em;
            --font-subhead: ${text.weight.subhead} ${text.medium.subheadSize / 16}rem/${text.medium.subheadLineHeight / 16}rem var(--font-family);
            --font-subhead-letter-spacing: ${text.medium.subheadLetterSpacing}em;
            --font-footnote: ${text.weight.footnote} ${text.medium.footnoteSize / 16}rem/${text.medium.footnoteLineHeight / 16}rem var(--font-family);
            --font-footnote-letter-spacing: ${text.medium.footnoteLetterSpacing}em;
            --font-caption1: ${text.weight.caption1} ${text.medium.caption1Size / 16}rem/${text.medium.caption1LineHeight / 16}rem var(--font-family);
            --font-caption1-letter-spacing: ${text.medium.caption1LetterSpacing}em;
            --font-caption2: ${text.weight.caption2} ${text.medium.caption2Size / 16}rem/${text.medium.caption2LineHeight / 16}rem var(--font-family);
            --font-caption2-letter-spacing: ${text.medium.caption2LetterSpacing}em;
        }
        @media screen and (min-width: 1201px){
            :root .alt-theme {
                --space-v: ${parseInt(space.expanded.vExtra) + parseInt(space.expanded.vUnify)}px;
                --space-v-small: ${space.expanded.vSmall}px;
                --space-v-extra: ${parseInt(space.expanded.vExtra)}px;
                --space-v-section: ${space.expanded.vSection}px;
                --space-v-unify: ${parseInt(space.expanded.vUnify)}px;
                --space-h: ${parseInt(space.expanded.hExtra) + parseInt(space.expanded.hUnify)}px;
                --space-h-section: ${space.expanded.hSection}px;
                --space-h-small: ${space.expanded.hSmall}px;
                --space-h-extra: ${space.expanded.hExtra}px;
                --space-h-unify: ${space.expanded.hUnify}px;
                --space-content: ${space.expanded.content}px;
                --size-base: ${size.expanded.base}px;
                --size-small: ${size.expanded.small}px;
                --size-large: ${size.expanded.large}px;
                --radius-small-control: ${radius.expanded.smallControl}px;
                --radius-large-control: ${radius.expanded.largeControl}px;
                --radius-container: ${radius.expanded.container}px;
                --radius-page: ${radius.expanded.page}px;
                --font-title1: ${text.weight.title1} ${text.expanded.title1Size / 16}rem/${text.expanded.title1LineHeight / 16}rem var(--font-title-family);
                --font-title1-letter-spacing: ${text.expanded.title1LetterSpacing}em;
                --font-title2: ${text.weight.title2} ${text.expanded.title2Size / 16}rem/${text.expanded.title2LineHeight / 16}rem var(--font-title-family);
                --font-title2-letter-spacing: ${text.expanded.title2LetterSpacing}em;
                --font-title3: ${text.weight.title3} ${text.expanded.title3Size / 16}rem/${text.expanded.title3LineHeight / 16}rem var(--font-title-family);
                --font-title3-letter-spacing: ${text.expanded.title3LetterSpacing}em;
                --font-headline: ${text.weight.headline} ${text.expanded.headlineSize / 16}rem/${text.expanded.headlineLineHeight / 16}rem var(--font-family);
                --font-headline-letter-spacing: ${text.expanded.headlineLetterSpacing}em;
                --font-body: ${text.weight.body} ${text.expanded.bodySize / 16}rem/${text.expanded.bodyLineHeight / 16}rem var(--font-family);
                --font-body-letter-spacing: ${text.expanded.bodyLetterSpacing}em;
                --font-subhead: ${text.weight.subhead} ${text.expanded.subheadSize / 16}rem/${text.expanded.subheadLineHeight / 16}rem var(--font-family);
                --font-subhead-letter-spacing: ${text.expanded.subheadLetterSpacing}em;
                --font-footnote: ${text.weight.footnote} ${text.expanded.footnoteSize / 16}rem/${text.expanded.footnoteLineHeight / 16}rem var(--font-family);
                --font-footnote-letter-spacing: ${text.expanded.footnoteLetterSpacing}em;
                --font-caption1: ${text.weight.caption1} ${text.expanded.caption1Size / 16}rem/${text.expanded.caption1LineHeight / 16}rem var(--font-family);
                --font-caption1-letter-spacing: ${text.expanded.caption1LetterSpacing}em;
                --font-caption2: ${text.weight.caption2} ${text.expanded.caption2Size / 16}rem/${text.expanded.caption2LineHeight / 16}rem var(--font-family);
                --font-caption2-letter-spacing: ${text.expanded.caption2LetterSpacing}em;
            }
        }
        :root .alt-theme.expanded {
            --space-v: ${parseInt(space.expanded.vExtra) + parseInt(space.expanded.vUnify)}px;
            --space-h: ${parseInt(space.expanded.hExtra) + parseInt(space.expanded.hUnify)}px;
            --space-v-small: ${space.expanded.vSmall}px;
            --space-v-extra: ${parseInt(space.expanded.vExtra)}px;
            --space-v-section: ${space.expanded.vSection}px;
            --space-v-unify: ${parseInt(space.expanded.vUnify)}px;
            --space-h-section: ${space.expanded.hSection}px;
            --space-h-small: ${space.expanded.hSmall}px;
            --space-h-extra: ${space.expanded.hExtra}px;
            --space-h-unify: ${space.expanded.hUnify}px;
            --space-content: ${space.expanded.content}px;
            --size-base: ${size.expanded.base}px;
            --size-small: ${size.expanded.small}px;
            --size-large: ${size.expanded.large}px;
            --radius-small-control: ${radius.expanded.smallControl}px;
            --radius-large-control: ${radius.expanded.largeControl}px;
            --radius-container: ${radius.expanded.container}px;
            --radius-page: ${radius.expanded.page}px;
            --font-title1: ${text.weight.title1} ${text.expanded.title1Size / 16}rem/${text.expanded.title1LineHeight / 16}rem var(--font-title-family);
            --font-title1-letter-spacing: ${text.expanded.title1LetterSpacing}em;
            --font-title2: ${text.weight.title2} ${text.expanded.title2Size / 16}rem/${text.expanded.title2LineHeight / 16}rem var(--font-title-family);
            --font-title2-letter-spacing: ${text.expanded.title2LetterSpacing}em;
            --font-title3: ${text.weight.title3} ${text.expanded.title3Size / 16}rem/${text.expanded.title3LineHeight / 16}rem var(--font-title-family);
            --font-title3-letter-spacing: ${text.expanded.title3LetterSpacing}em;
            --font-headline: ${text.weight.headline} ${text.expanded.headlineSize / 16}rem/${text.expanded.headlineLineHeight / 16}rem var(--font-family);
            --font-headline-letter-spacing: ${text.expanded.headlineLetterSpacing}em;
            --font-body: ${text.weight.body} ${text.expanded.bodySize / 16}rem/${text.expanded.bodyLineHeight / 16}rem var(--font-family);
            --font-body-letter-spacing: ${text.expanded.bodyLetterSpacing}em;
            --font-subhead: ${text.weight.subhead} ${text.expanded.subheadSize / 16}rem/${text.expanded.subheadLineHeight / 16}rem var(--font-family);
            --font-subhead-letter-spacing: ${text.expanded.subheadLetterSpacing}em;
            --font-footnote: ${text.weight.footnote} ${text.expanded.footnoteSize / 16}rem/${text.expanded.footnoteLineHeight / 16}rem var(--font-family);
            --font-footnote-letter-spacing: ${text.expanded.footnoteLetterSpacing}em;
            --font-caption1: ${text.weight.caption1} ${text.expanded.caption1Size / 16}rem/${text.expanded.caption1LineHeight / 16}rem var(--font-family);
            --font-caption1-letter-spacing: ${text.expanded.caption1LetterSpacing}em;
            --font-caption2: ${text.weight.caption2} ${text.expanded.caption2Size / 16}rem/${text.expanded.caption2LineHeight / 16}rem var(--font-family);
            --font-caption2-letter-spacing: ${text.expanded.caption2LetterSpacing}em;
        }
    }
    `;


  document.head.appendChild(style);

}

const SaveData = async (cColor, cRadius, cSpace, cSize, cText, context) => {
  try {
    // Construct the data to save
    const dataToSave = {
      cColor: cColor,
      cRadius: cRadius,
      cSpace: cSpace,
      cSize: cSize,
      cText: cText
    };

    // Get a reference to the system document
    const systemRef = doc(collection(db, 'systems'), context.selectedSystem);

    // Set the data in the "staging" collection under the specified system
    const stagingRef = doc(collection(systemRef, 'staging'), 'latest');
    await setDoc(stagingRef, dataToSave);

    console.log('Data saved successfully to Firestore');
  } catch (error) {
    console.error('Error saving data:', error);
    // Handle error
  }
};

const toCamel = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}


const CalculateChanges = (cColor, cRadius, cSpace, cSize, cText, context) => {

  cText = cText || {};
  cText.weight = cText.weight || {};
  cText.compact = cText.compact || {};
  cText.medium = cText.medium || {};
  cText.expanded = cText.expanded || {};
  cText.family = cText.family || {};

  const cleanObject = (obj) => {
    let cleaned = {};
    let numberOfChanges = 0;

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const nested = cleanObject(obj[key]);
          if (Object.keys(nested.cleaned).length !== 0) {
            cleaned[key] = nested.cleaned;
            numberOfChanges += nested.numberOfChanges;
          }
        } else if (obj[key] !== null && obj[key] !== '') {
          cleaned[key] = obj[key];
          numberOfChanges++;
        }
      }
    }
    return { cleaned, numberOfChanges };
  };

  const cleanText = (cText, context) => {
    // Initialize a cleaned object
    let cleaned = {};

    // Check if cText and context are defined and have the family property
    const systemContext = context.systemList[context.selectedSystem].foundations.text;

    // Check for differences in the 'family' property
    cleaned.family = {};
    for (const familyValue in cText.family) {
      if (cText.family[familyValue] !== systemContext.family[familyValue]) {
        // If the values are different, add them to the cleaned object
        cleaned.family[familyValue] = cText.family[familyValue];
      }
    }

    // Check for differences in other properties and exclude properties without any non-empty children
    for (const key in cText) {
      if (key !== 'family' && Object.keys(cText[key]).some(prop => cText[key][prop] !== '')) {
        cleaned[key] = {};
        for (const otherTextProperties in cText[key]) {
          if (cText[key][otherTextProperties] !== systemContext[key][otherTextProperties]) {
            cleaned[key][otherTextProperties] = cText[key][otherTextProperties];
          }
        }
      }
    }

    return cleaned;
  };







  const countFields = (obj) => {
    let count = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          count += countFields(obj[key]);
        } else {
          count++;
        }
      }
    }
    return count;
  };

  const cleanedColor = cleanObject(cColor);
  const cleanedRadius = cleanObject(cRadius);
  const cleanedSpace = cleanObject(cSpace);
  const cleanedSize = cleanObject(cSize);
  const cleanedText = cleanText(cText, context);
  const textChanges = countFields(cleanedText);


  const totalChanges =
    cleanedColor.numberOfChanges +
    cleanedRadius.numberOfChanges +
    cleanedSpace.numberOfChanges +
    cleanedSize.numberOfChanges +
    textChanges;

  // Handle case when cleanedText is empty
  const text = textChanges > 0 ? cleanedText : {};

  return {
    total: totalChanges,
    color: cleanedColor.cleaned,
    radius: cleanedRadius.cleaned,
    space: cleanedSpace.cleaned,
    size: cleanedSize.cleaned,
    text: text,
    textChanges: textChanges
  };
};

const PublishUpdate = async (setContext, context, color, radius, space, size, text, setPublishState) => {

  const foundations = {};
  setPublishState('publishing');

  const filterForData = (element, category) => {
    const filteredData = {};

    const filterObject = (obj) => {
      const filteredObj = {};
      for (const key in obj) {
        const value = obj[key];
        if ((typeof value === 'object' && Object.keys(value).length > 0) || (typeof value !== 'object' && value !== '')) {
          if (typeof value === 'object') {
            const filteredValue = filterObject(value);
            if (Object.keys(filteredValue).length > 0) {
              filteredObj[key] = filteredValue;
            }
          } else {
            filteredObj[key] = value;
          }
        }
      }
      return filteredObj;
    };

    // Call filterObject for nested objects
    filteredData[category] = filterObject(element);

    // If there's any data after filtering, add it to foundations under the respective category
    if (Object.keys(filteredData[category]).length > 0) {
      foundations[category] = filteredData[category];
    }
  };

  filterForData(color, 'color');
  filterForData(radius, 'radius');
  filterForData(space, 'space');
  filterForData(size, 'size');
  filterForData(text, 'text');

  foundations.text.family = text.family;


  const dataToUpdate = {
    foundations
  }

  try {
    // Get a reference to the system document within "systems" collection
    const systemRef = doc(collection(db, 'systems'), context.selectedSystem);


    // Update the data in the specified system document, merging it with existing data
    await setDoc(systemRef, dataToUpdate, { merge: true });

    // Delete the "latest" document inside the "staging" subcollection
    const stagingLatestRef = doc(collection(systemRef, 'staging'), 'latest');
    await deleteDoc(stagingLatestRef);

    const res = await createAndUploadCSS({
      css: {
        systemId: context.selectedSystem,
        foundations: context.systemList[context.selectedSystem].foundations,
      }
    });


    setPublishState('published')
  } catch (error) {
    console.error('Error updating data:', error);
    // Handle error
  }
}

const DeleteSystem = async (context) => {

  try {
    // Delete the document from the "systems" collection
    const systemDocRef = doc(db, 'systems', context.selectedSystem);
    await deleteDoc(systemDocRef);

    // Remove the system from the user's subscribed systems array
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, {
      'systems.subscribed': arrayRemove(context.selectedSystem),
    });

    console.log('System deleted successfully');

    window.location.href = '/';
    return true; // Indicate successful deletion
  } catch (error) {
    console.error('Error deleting system:', error);
    // Handle the error accordingly
    throw error; // Propagate the error further if needed
  }
};



export {
  CreateNewSystem,
  AddAltThemeToDom,
  SaveData,
  CalculateChanges,
  PublishUpdate,
  DeleteSystem,
  toCamel,
  timestampToDate
};
