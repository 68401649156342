import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Icon, Input, Label, Section, Space, Subhead, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";

function ComponentSnippet({ thumbnail = false }) {
  const [variant, setVariant] = useState('Outline');
  const [button, setButton] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showContainer, setShowContainer] = useState(true);
  const [showLabel, setShowLabel] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [placeholder, setPlaceholder] = useState('Enter text');
  const [align, setAlign] = useState('Left');
  const [multiLine, setMultiLine] = useState(false);
  const [numberOfLines, setNumberOfLines] = useState(1);
  const [maxNumberOfLines, setMaxNumberOfLines] = useState(4);
  const [type, setType] = useState('Text');

  const Design = (
    <>
      <SwitchRow
        label='Show Container'
        textColor={null}
        prop={showContainer} setProp={setShowContainer}
        showDivider={false}
      />
      {showContainer &&
        <>
          <Divider />
          <Selector
            label='Container Style'
            textColor={null}
            prop={variant} setProp={setVariant}
            options={['Default', 'Fill', 'Outline', 'Blank']} />
          <Divider />
          <SwitchRow
            textColor={null}
            label='Show Label'
            showDivider={false}
            prop={showLabel} setProp={setShowLabel} />
          <Divider />
          <SwitchRow
            showDivider={false}
            label='Show Icon'
            prop={showIcon} setProp={setShowIcon}
          />
          <Divider />
          <SwitchRow
            textColor={null}
            label='Show Button'
            showDivider={false}
            prop={button} setProp={setButton} />
        </>
      }
    </>
  );

  const Content = (
    <>
      <TextInput
        label='Placeholder text'
        prop={placeholder}
        setProp={(e) => setPlaceholder(e.target.value)}
        placeholder='Enter placeholder text'
      />
      <Divider />
      <TextInput
        label='Input value'
        prop={inputValue}
        setProp={(e) => setInputValue(e.target.value)}
        placeholder='Enter value text'
      />
      <Divider />
      <Selector
        label='Align text'
        textColor={null}
        prop={align} setProp={setAlign}
        options={['Left', 'Center', 'Right']} />
      <Divider />

      {/* const [numberOfLines, setNumberOfLines] = useState(1); */}
      {/* const [maxNumberOfLines, setMaxNumberOfLines] = useState(4); */}
      <SwitchRow
        label='Multi Line'
        prop={multiLine}
        setProp={setMultiLine}
      />
      {multiLine && (
        <>
          <HStack>
            <TextInput
              label={'Minimum number of lines'}
              type='number'
              prop={numberOfLines}
              setProp={(e) => setNumberOfLines(e.target.value)}
            />
            <Space.Content />
            <TextInput
              label={'Max number of lines'}
              type='number'
              prop={maxNumberOfLines}
              setProp={(e) => setMaxNumberOfLines(e.target.value)}
            />
          </HStack>
        </>
      )}

    </>
  );


  const Technical = (
    <>
      <Selector
        label='Type'
        prop={type}
        setProp={setType}
        options={["Text", "Number"]}
      />
      <Divider />
      <Space.Content />
      <Subhead> In addition to these props, we also offer: </Subhead>
      <Space.Content />
      <HStack wrap>
        <PropCallout propName='className' propType='string' />
        <PropCallout propName='id' propType='string' />
        <PropCallout propName='name' propType='string' />
        <PropCallout propName='type' propType='string' />
        <PropCallout propName='onChange' propType='event' />
        <PropCallout propName='onKeyDown' propType='event' />
        <PropCallout propName='ariaLabel' propType='string' />
      </HStack>
    </>
  );

  const SnippetComponent = (
    <VStack align={showLabel ? 'group' : null}>
      {showContainer && showLabel && <Label label="I'm a label" />}
      {showContainer && showLabel && <Space.Content />}
      {showContainer ?
        <Container variant={toCamel(variant)}>
          {button ? (
            <HStack>
              <HStack align="middle group">
                {showIcon && <Icon name="SearchOutline" />}
                {showIcon && <Space.Content />}
                <Input
                  value={inputValue}
                  type={type}
                  placeholder={placeholder}
                  align={align.toLowerCase()}
                  multiLine={multiLine}
                  numberOfLines={multiLine ? numberOfLines : null}
                  maxNumberOfLines={multiLine ? maxNumberOfLines : null}
                />
              </HStack>
              <Button
                width="hug"
                icon1="Close"
                small
                variant="tertiary"
              />
            </HStack>
          ) : showIcon ? (
            <HStack align="middle group">
              {showIcon && <Icon name="SearchOutline" />}
              {showIcon && <Space.Content />}
              <Input
                value={inputValue ? inputValue : null}
                type={type}
                placeholder={placeholder}
                align={align.toLowerCase()}
                multiLine={multiLine}
                numberOfLines={multiLine ? numberOfLines : null}
                maxNumberOfLines={multiLine ? maxNumberOfLines : null}
              />
            </HStack>
          ) : (
            <Input
              value={inputValue ? inputValue : null}
              type={type}
              placeholder={placeholder}
              align={align.toLowerCase()}
              multiLine={multiLine}
              numberOfLines={multiLine ? numberOfLines : null}
              maxNumberOfLines={multiLine ? maxNumberOfLines : null}

            />
          )}
        </Container>
        : (
          <Input
            value={inputValue ? inputValue : null}
            type={type}
            placeholder={placeholder}
            align={align.toLowerCase()}
            multiLine={multiLine}
            numberOfLines={multiLine ? numberOfLines : null}
            maxNumberOfLines={multiLine ? maxNumberOfLines : null}
          />
        )
      }
    </VStack>
  );

  return (
    <>
      {thumbnail ? SnippetComponent :
        <TemplateVertical
          component={SnippetComponent}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
            {
              name: 'Content',
              controls: Content
            },
            {
              name: 'Technical',
              controls: Technical
            }
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

