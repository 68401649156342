import React, { useEffect, useState } from 'react'
import { useContextValue } from '../../AppContext';
import { Accordion, Body, Button, Caption2, Container, Divider, HStack, Headline, Icon, Scroll, Section, Sheet, Space, Subhead, Title1, Title2, Title3, VStack } from '@themeable/react-components';
import { CalculateChanges, PublishUpdate } from '../Utilities/UtilityFunctions';
import { handleCheckout } from '../../services/stripe';

function ChangeLogSheet({ active, changes, handleSheetChange }) {

  const {
    context,
    setContext,
    cColor,
    setCColor,
    cRadius,
    setCRadius,
    cSpace,
    setCSpace,
    cSize,
    setCSize,
    cText,
    setCText
  } = useContextValue();
  const [loadingStripe, setLoadingStripe] = useState(false);


  const handlePaymentCheckout = async (priceId) => {
    const error = await handleCheckout(priceId);
    console.log(error)
  }

  const deleteValue = (contextObject, category, valueToDelete) => {
    // Create a copy of the state object
    const newState = { ...contextObject };
    // Check if the category exists in the state object
    if (newState[category]) {
      // Delete the value from the category
      delete newState[category][valueToDelete];
    }
    // Update the state object with the modified object
    switch (contextObject) {
      case cText:
        setCText(newState);
        break;
      case cColor:
        setCColor(newState);
        break;
      case cSpace:
        setCSpace(newState);
        break;
      case cRadius:
        setCRadius(newState);
        break;
      case cSize:
        setCSize(newState);
        break;
      default:
        // Handle the case when an invalid state object is passed
        break;
    }

  };


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderColorUpdates = () => {
    if (changes.color && Object.keys(changes.color).length > 0) {
      // Calculate the total number of changes
      const totalChanges = Object.values(changes.color)
        .reduce((total, categoryChanges) => total + Object.keys(categoryChanges).length, 0);

      return (
        <VStack align='top'>
          <Accordion
            defaultActive
            parentContent={<Title3>Color Tokens ({totalChanges})</Title3>}
          >
            <Container variant='fill'>
              <Section>
                {Object.entries(changes.color).map(([category, values]) => (
                  <VStack key={capitalizeFirstLetter(category)}>
                    <Headline>{capitalizeFirstLetter(category)}</Headline>
                    <Space.Content />
                    <HStack
                      gridExpanded={[1, 1, 1]}
                      gridMedium={[1, 1, 1]}
                      gridCompact={[1, 1, 1]}
                    >
                      <Subhead>Existing</Subhead>
                      <VStack />
                      <Subhead>Update</Subhead>
                    </HStack>
                    <Divider nested extendLeft extendRight />
                    {values && Object.entries(values).map(([key, value]) => (
                      <>
                        <HStack
                          key={key}
                          gridExpanded={[1, 1, 1]}
                          gridMedium={[1, 1, 1]}
                          gridCompact={[1, 1, 1]}
                          align='middle'>
                          <VStack align=''>
                            <Caption2 color='text-secondary'>{key}</Caption2>
                            <HStack align='middle'>
                              {context.systemList[context.selectedSystem].foundations.color[category][key].includes('#') ? (
                                <>
                                  <Body>{context.systemList[context.selectedSystem].foundations.color[category][key].toUpperCase()}</Body>
                                  <Space.Content />
                                  <span
                                    style={{
                                      background: context.systemList[context.selectedSystem].foundations.color[category][key],
                                      height: '16px',
                                      border: '1px solid rgba(var(--color-border),var(--color-border-alpha))',
                                      width: '16px',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <Body>
                                    {context.systemList[context.selectedSystem].foundations.color[category][key] * 100}%
                                  </Body>

                                </>
                              )}
                            </HStack>
                          </VStack>
                          <VStack align='middle center'>
                            <Icon name='ArrowRight' />
                          </VStack>
                          <VStack align=''>
                            <Caption2 color='text-secondary'>{key}</Caption2>
                            <HStack align='middle'>
                              <Body>{context.systemList[context.selectedSystem].foundations.color[category][value]}</Body>
                              {value.includes('#') ? (
                                <>
                                  <HStack align='left middle'>
                                    <Button
                                      variant='destructive'
                                      icon1='Trash'
                                      small
                                      onClick={() => deleteValue(cColor, category, key)}
                                    />
                                    {value}
                                    <Space.Content />
                                    <span
                                      style={{
                                        background: value,
                                        border: '1px solid rgba(var(--color-border),var(--color-border-alpha))',
                                        height: '16px',
                                        width: '16px',
                                        borderRadius: '4px',
                                      }}
                                    />
                                  </HStack>
                                </>
                              ) : (
                                <>
                                  <HStack align='left middle'>
                                    <Button
                                      variant='destructive'
                                      icon1='Trash'
                                      small
                                      onClick={() => deleteValue(cColor, category, key)}
                                    />
                                    <Body>
                                      {value * 100}%
                                    </Body>
                                  </HStack>
                                </>
                              )}
                            </HStack>
                          </VStack>
                        </HStack>
                      </>
                    ))}
                  </VStack>
                ))}
              </Section>
            </Container>
          </Accordion>
        </VStack>
      );
    } else {
      return null;
    }
  };


  const orderedCategories = ['compact', 'medium', 'expanded'];
  const textCategories = ['weight', 'family', 'compact', 'medium', 'expanded']
  const sizeCategories = ['responsive', 'compact', 'medium', 'expanded']

  const renderSizeUpdates = () => {
    if (changes.size && Object.keys(changes.size).length > 0) {
      // Calculate the total number of changes
      const totalChanges = Object.values(changes.size)
        .reduce((total, categoryChanges) => total + Object.keys(categoryChanges).length, 0);

      return (
        <VStack align='top'>
          <Accordion
            defaultActive
            parentContent={<>
              <Title3>Size Tokens ({totalChanges})</Title3>
            </>}
          >
            <Container variant='fill'>
              <Section>
                {sizeCategories.map(category => {
                  if (changes.size[category] && Object.keys(changes.size[category]).length > 0) {
                    return (
                      <div key={category}>
                        <Headline>{capitalizeFirstLetter(category)}</Headline>
                        <Space.Content />
                        <HStack
                          gridExpanded={[1, 1, 1]}
                          gridMedium={[1, 1, 1]}
                          gridCompact={[1, 1, 1]}

                        >
                          <Subhead>Existing</Subhead>
                          <VStack />
                          <Subhead>Update</Subhead>
                        </HStack>
                        <Divider nested extendLeft extendRight />
                        {changes.size[category] && Object.entries(changes.size[category]).map(([key, value]) => (
                          <>
                            <HStack key={key}
                              gridExpanded={[1, 1, 1]}
                              gridMedium={[1, 1, 1]}
                              gridCompact={[1, 1, 1]}
                              align='middle'>
                              <VStack align=''>
                                <Caption2 color='text-secondary'>{key}</Caption2>
                                <Body>{context.systemList[context.selectedSystem].foundations?.size[category][key]?.toUpperCase()}px</Body>
                              </VStack>
                              <VStack align='middle center'>
                                <Icon name='ArrowRight' />
                              </VStack>
                              <VStack align=''>
                                <Caption2 color='text-secondary'>{key}</Caption2>
                                <HStack align='middle left'>
                                  <Button
                                    variant='destructive'
                                    icon1='Trash'
                                    small
                                    onClick={() => deleteValue(cSize, category, key)}
                                  />
                                  <Body>{value}px</Body>
                                </HStack>
                              </VStack>
                            </HStack>
                          </>
                        ))}
                      </div>
                    );
                  } else {
                    return null; // Skip rendering if there are no changes for this category
                  }
                })}
              </Section>
            </Container>
          </Accordion>
        </VStack>
      );
    } else {
      return null;
    }
  };


  const renderSpaceUpdates = () => {
    if (changes.space && Object.keys(changes.space).length > 0) {
      // Calculate the total number of changes
      const totalChanges = Object.values(changes.space)
        .reduce((total, categoryChanges) => total + Object.keys(categoryChanges).length, 0);

      return (
        <VStack align='top'>
          <Accordion
            defaultActive
            parentContent={<>
              <Title3>Space Tokens ({totalChanges})</Title3>
            </>}
          >
            <Container variant='fill'>
              <Section>

                {orderedCategories.map(category => {
                  if (changes.space[category] && Object.keys(changes.space[category]).length > 0) {
                    return (
                      <div key={capitalizeFirstLetter(category)}>
                        <Headline>{capitalizeFirstLetter(category)}</Headline>
                        <Space.Content />
                        <HStack
                          gridExpanded={[1, 1, 1]}
                          gridMedium={[1, 1, 1]}
                          gridCompact={[1, 1, 1]}
                        >
                          <Subhead>Existing</Subhead>
                          <VStack />
                          <Subhead>Update</Subhead>
                        </HStack>
                        {changes.space[category] && Object.entries(changes.space[category]).map(([key, value]) => (
                          <>
                            <Divider nested />
                            <HStack key={key}
                              gridExpanded={[1, 1, 1]}
                              gridMedium={[1, 1, 1]}
                              gridCompact={[1, 1, 1]}
                              align='middle'>
                              <VStack align=''>
                                <Caption2 color='text-secondary'>{key}</Caption2>
                                <Body>{context.systemList[context.selectedSystem].foundations.space[category][key].toUpperCase()}px</Body>
                              </VStack>
                              <VStack align='middle center'>
                                <Icon name='ArrowRight' />
                              </VStack>
                              <VStack align=''>
                                <Caption2 color='text-secondary'>{key}</Caption2>
                                <HStack align='middle left'>
                                  <Button
                                    variant='destructive'
                                    icon1='Trash'
                                    small
                                    onClick={() => deleteValue(cSpace, category, key)}
                                  />
                                  <Body>{value}px</Body>
                                </HStack>
                              </VStack>
                            </HStack>
                          </>
                        ))}
                      </div>
                    );
                  } else {
                    return null; // Skip rendering if there are no changes for this category
                  }
                })}

              </Section>
            </Container>
          </Accordion>
        </VStack>
      );
    } else {
      return null;
    }
  };


  const renderRadiusUpdates = () => {
    if (changes.radius && Object.keys(changes.radius).length > 0) {

      const totalChanges = Object.values(changes.space)
        .reduce((total, categoryChanges) => total + Object.keys(categoryChanges).length, 0);

      return (
        <VStack align='top'>
          <Accordion
            defaultActive
            parentContent={<>
              <Title3>Radius Tokens ({totalChanges})</Title3>
            </>}
          >
            <Container variant='fill'>
              <Section>
                {orderedCategories.map(category => {
                  if (changes.radius[category] && Object.keys(changes.radius[category]).length > 0) {
                    return (
                      <div key={capitalizeFirstLetter(category)}>
                        <Headline>{capitalizeFirstLetter(category)}</Headline>
                        <Space.Content />
                        <HStack
                          gridExpanded={[1, 1, 1]}
                          gridMedium={[1, 1, 1]}
                          gridCompact={[1, 1, 1]}
                        >
                          <Subhead>Existing</Subhead>
                          <VStack />
                          <Subhead>Update</Subhead>
                        </HStack>
                        {changes.radius[category] && Object.entries(changes.radius[category]).map(([key, value]) => (
                          <>
                            <Divider nested />
                            <HStack key={key}
                              gridExpanded={[1, 1, 1]}
                              gridMedium={[1, 1, 1]}
                              gridCompact={[1, 1, 1]}
                              align='middle'>
                              <VStack align=''>
                                <Caption2 color='text-secondary'>{key}</Caption2>
                                <Body>{context.systemList[context.selectedSystem].foundations.radius[category][key].toUpperCase()}px</Body>
                              </VStack>
                              <VStack align='middle center'>
                                <Icon name='ArrowRight' />
                              </VStack>
                              <VStack align=''>
                                <Caption2 color='text-secondary'>{key}</Caption2>
                                <HStack align='middle left'>
                                  <Button
                                    variant='destructive'
                                    icon1='Trash'
                                    small
                                    onClick={() => deleteValue(cRadius, category, key)}
                                  />
                                  <Body>{value}px</Body>
                                </HStack>
                              </VStack>
                            </HStack>
                          </>
                        ))}
                      </div>
                    );
                  } else {
                    return null; // Skip rendering if there are no changes for this category
                  }
                })}
              </Section>
            </Container>
          </Accordion>
        </VStack>
      );
    } else {
      return null;
    }
  };

  const renderTextUpdates = () => {
    if (changes.text && Object.keys(changes.text).length > 0) {
      // Calculate the total number of changes
      const totalChanges = Object.values(changes.text)
        .reduce((total, categoryChanges) => total + Object.keys(categoryChanges).length, 0);

      return (
        <VStack align='top'>
          <Accordion
            defaultActive
            parentContent={<Title3>Text Tokens ({totalChanges})</Title3>}
          >
            <Container variant='fill'>
              <Section>
                {textCategories.map(category => {
                  if (changes.text[category] && Object.keys(changes.text[category].length > 0)) {
                    return (
                      <>
                        <Headline>{capitalizeFirstLetter(category)}</Headline>
                        <Space.Content />

                        <HStack
                          gridExpanded={[1, 1, 1]}
                          gridMedium={[1, 1, 1]}
                          gridCompact={[1, 1, 1]}>
                          <Subhead color='text-secondary'>Existing</Subhead>
                          <VStack />
                          <Subhead color='text-secondary'>Update</Subhead>
                        </HStack>
                        {changes.text[category] && Object.entries(changes.text[category]).map(([key, value]) => (
                          <div key={key}>
                            <Divider nested extendLeft extendRight />
                            <HStack gridExpanded={[1, 1, 1]}
                              gridMedium={[1, 1, 1]}
                              gridCompact={[1, 1, 1]} align='middle'>
                              <VStack align=''>
                                <Caption2 color='text-secondary'>{key}</Caption2>
                                <Body style={{ wordBreak: 'break-all' }}>
                                  {context?.systemList[context?.selectedSystem]?.foundations?.text[category][key] !== "" && context?.systemList[context?.selectedSystem]?.foundations?.text[category][key] !== undefined ? (
                                    context?.systemList[context?.selectedSystem]?.foundations?.text[category][key]
                                  ) : (
                                    "-"
                                  )}
                                </Body>
                              </VStack>
                              <VStack align='middle center'>
                                <Icon name='ArrowRight' />
                              </VStack>
                              <VStack align=''>
                                <Caption2 color='text-secondary'>{key}</Caption2>
                                <HStack align='left middle'>
                                  <Button
                                    variant='destructive'
                                    icon1='Trash'
                                    small
                                    onClick={() => deleteValue(cText, category, key)}
                                  />
                                  <Body style={{ wordBreak: 'break-all' }}>
                                    {value !== "" ? (
                                      value
                                    ) : (
                                      "-"
                                    )}
                                  </Body>
                                </HStack>

                              </VStack>
                            </HStack>
                          </div>
                        ))}
                      </>

                    )
                  } else return null;

                })}
              </Section>
            </Container>
          </Accordion>
        </VStack>
      );
    } else {
      return null;
    }
  };


  const [publishState, setPublishState] = useState('unpublished');

  const handleUpdate = () => {
    if (context.user.subscription === 'premium') {
      setPublishState('publishing')
      PublishUpdate(setContext, context, cColor, cRadius, cSpace, cSize, cText, setPublishState);
    } else {
      setPublishState('notPremium')
    }
  }

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const x = CalculateChanges(cColor, cRadius, cSpace, cSize, cText, context)
    if (x.total < 1) {
      setShowMessage(true)
    } else {
      setShowMessage(false)
    }
  }, [cColor, cRadius, cSpace, cSize, cText, context])


  const dismiss = () => {
    handleSheetChange()
    if (publishState === 'published') {
      window.location.reload();
    } else {
      setPublishState('unpublished')
    }
  }


  return (
    <>
      <Sheet
        size={publishState === 'notPremium' ? 'medium' : 'expanded'}
        active={active} onDismiss={handleSheetChange}
        height={publishState === 'notPremium' ? 'hug' : 'fill'}
      >
        <Section>
          <VStack showOn='expanded medium'>
            <HStack
              gridExpanded={[1, 1, 1]}
              gridMedium={[1, 1, 1]}
              gridCompact={[1, 1, 1]}
              align='middle'>
              <VStack />
              <Headline align='center'>
                {publishState === 'notPremium' ? (<></>) : (<>
                  {context.systemList[context.selectedSystem].name} Updates
                </>)}
              </Headline>
              <HStack align='right'>
                {publishState !== 'published' && publishState !== 'notPremium' && showMessage === false ? (
                  <Button width='hug' variant='primary' text1='Publish Updates' small onClick={handleUpdate} loading={publishState === 'publishing'} />
                ) : ''}
                <Button width='hug' variant='secondary' icon1='Close' small onClick={dismiss} />
              </HStack>
            </HStack>
          </VStack>
          <VStack showOn='compact'>
            <HStack gridExpanded={[1, 1, 1]}
              gridMedium={[1, 1, 1]}
              gridCompact={[1, 1, 1]} align='middle'>
              <Headline align='left'>
                {publishState === 'notPremium' ? (<>Account Settings</>) : (<>
                  {context.systemList[context.selectedSystem].name} Updates
                </>)}
              </Headline>
              <HStack align='right'>
                <Button width='hug' variant='primary' text1='Publish Updates' small onClick={handleUpdate} />
                <Button width='hug' variant='secondary' icon1='Close' small onClick={dismiss} />
              </HStack>
            </HStack>
          </VStack>
          <Divider extendLeft extendRight nested />
        </Section>
        <VStack>
          {publishState === 'notPremium' ? (<>
            <Section>
              <Title3 color='primary'>
                Ready to publish <br />your first system?? 😮🫣
              </Title3>
              <Body>
                To get the most out of Themeable, you must first subscribe. Want to try it out for a month? Use this promo code at checkout:
              </Body>
              <Space.Content />
              <Container variant='outline'>
                <Headline align='center' color='accent'>1MONTH</Headline >
              </Container>
              <Space.NewSection />
              <HStack>
                <Button text1='Learn more' variant='secondary' url='https://getthemeable.com/' target='_blank' />
                <Button text1='Try Themeable 🎉' loading={loadingStripe} variant='primary' onClick={() => { handlePaymentCheckout('price_1Nf8ItHUEMXMwifTAxyefQ8d'); setLoadingStripe(true); }} />
              </HStack>
            </Section>
          </>) : (<>
            <Section>
              {publishState !== 'published' ? (
                <>
                  {renderColorUpdates()}
                  {renderSizeUpdates()}
                  {renderSpaceUpdates()}
                  {renderRadiusUpdates()}
                  {renderTextUpdates()}
                </>
              ) : (
                <>
                  <HStack height='fill' align='middle center'>
                    <VStack align='middle center' maxWidth='400px'>
                      <Icon name='CheckCircle' color='positive' size='64px' />
                      <Title2>Update complete</Title2>
                      <Body align='center'>
                        <span className='bold'>
                          {context.systemList[context.selectedSystem].name}</span> has been successfully updated. All websites using this system should update shortly. Themeable Figma Plugin will be updated as well.
                      </Body>
                    </VStack>
                  </HStack>
                </>
              )}



              {showMessage ? (
                <>
                  <VStack align='middle center'>
                    <VStack align='middle' maxWidth='370px'>
                      <VStack align='middle center'>
                        <Icon name='Info' color='informative' size='64px' />
                      </VStack>
                      <Title2 align='center'>No Updates</Title2>
                      <Body tag='span' weight='bold' align='center'>{context.systemList[context.selectedSystem].name}<Body tag='span'> has no updates. Continue editing system to see changes here.</Body></Body>
                      <Space.Content />
                      <Button variant='primary' text1='Edit System' onClick={dismiss} />
                    </VStack>
                  </VStack>
                </>
              ) : ''}
            </Section>
          </>)}
        </VStack>

      </Sheet>
    </>
  )
}

export default ChangeLogSheet
