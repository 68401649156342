import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Icon, Input, Label, Section, Space, Subhead, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";

function ComponentSnippet({ thumbnail = false }) {
  const [color, setColor] = useState('Default');
  const [colorCustom, setColorCustom] = useState(undefined);
  const [iconName, setIconName] = useState('PlusCircle');
  const [size, setSize] = useState(undefined);

  const Design = (
    <>

      <TextInput
        label='Size'
        placeholder='var(--size-base), 32px, 2rem, etc'
        prop={size} setProp={(e) => setSize(e.target.value)}
      />
      <Divider />
      <Selector
        label='Icon Color (Variables)'
        textColor={null}
        prop={color} setProp={setColor}
        options={[
          'Default',
          'primary',
          'accent',
          'read-only',
          'notice',
          'negative',
          'positive',
          'informative',
        ]} />
      {color === 'Default' && (
        <>
          <Divider />
          <TextInput
            label='Custom Color'
            placeholder='red'
            prop={colorCustom}
            setProp={(e) => setColorCustom(e.target.value)}
          />
        </>
      )}
    </>
  );

  const Content = (
    <>
      <Selector
        options={[
          'PlusCircle',
          'Plus',
          'SearchOutline',
          'Search',
          'Minus'
        ]}
        label='Icon name'
        prop={iconName}
        setProp={setIconName}
      />
    </>
  );



  const SnippetComponent = (
    <Icon
      name={iconName}
      size={size ? size : undefined}
      color={color !== 'Default' ? color : undefined}
      colorCustom={colorCustom ? colorCustom : undefined}
    />
  );

  return (
    <>
      {thumbnail ? SnippetComponent :
        <TemplateVertical
          component={SnippetComponent}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
            {
              name: 'Content',
              controls: Content
            }
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

