import React, { useState } from "react";
import { Body, Button, Caption1, Caption2, Container, Divider, Footnote, Headline, HStack, Icon, Input, Label, Media, Section, Space, Subhead, Title1, Title2, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";

function ComponentSnippet({ thumbnail = false }) {
  const [svgPath, setSvgPath] = useState(null);
  const [size, setSize] = useState('Medium');
  const [profile, setProfile] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [loop, setLoop] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [mute, setMute] = useState(false);
  const imageUrl = '/assets/thumbnail.jpg';
  const videoUrl = 'https://firebasestorage.googleapis.com/v0/b/themeable-ce1f4.appspot.com/o/assets%2Fhero-graphic.mp4?alt=media&token=36031779-b46e-46e2-84d0-d17d0ef6aea5';
  const [showProfileImage, setShowProfileImage] = useState(true);
  const [altTag, setAltTag] = useState("This is the Media Component");
  const [showCaption, setShowCaption] = useState(false);
  const [customWidth, setCustomWidth] = useState('');
  const [fallbackColor, setFallbackColor] = useState('');
  const [type, setType] = useState('image');
  const [aspectRatio, setAspectRatio] = useState('1 / 1');
  const [alignCaption, setAlignCaption] = useState('left')

  const [selectedVariant1, setSelectedVariant1] = useState('Headline');
  const [selectedVariant2, setSelectedVariant2] = useState('Body');
  const [tag1, setTag1] = useState('P');
  const [tag2, setTag2] = useState('P');
  const [selectedColor1, setSelectedColor1] = useState('Text Primary');
  const [selectedColor2, setSelectedColor2] = useState('Text Primary');
  const [weight1, setWeight1] = useState('Default');
  const [weight2, setWeight2] = useState('Default');
  const [align1, setAlign1] = useState('Default');
  const [align2, setAlign2] = useState('Default');

  const colors = ['Text Primary', 'Text Secondary', 'Read Only', 'Positive', 'Negative', 'Informative', 'Notice']
  const tags = ['Span', 'Div', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P']
  const weights = ['Default', 'Light', 'Regular', 'Bold']

  const textVariants = { Title1, Title2, Title3, Headline, Body, Subhead, Footnote, Caption1, Caption2, }

  const alignmentOptions = ['Default', 'Left', 'Center', 'Right']

  const TextComponent1 = textVariants[selectedVariant1]
  const TextComponent2 = textVariants[selectedVariant2]



  const Design = (
    <>
      <Space.Content />
      <Headline>Text 1 Example</Headline>
      <Space.Content />
      <Selector
        label='Class'
        prop={selectedVariant1} setProp={setSelectedVariant1}
        options={Object.keys(textVariants)}
      />
      <Selector
        label='Tag'
        prop={tag1} setProp={setTag1}
        options={tags}
      />
      <Selector
        label='Align'
        prop={align1} setProp={setAlign1}
        options={alignmentOptions}
      />
      <Divider />
      <Selector
        label='Color'
        prop={selectedColor1} setProp={setSelectedColor1}
        options={colors}
      />
      <Selector
        label='Weight'
        prop={weight1} setProp={setWeight1}
        options={weights}
      />
      <Space.NewSection />
      <Headline>Text 2 Example</Headline>
      <Space.Content />
      <Selector
        label='Class'
        prop={selectedVariant2} setProp={setSelectedVariant2}
        options={Object.keys(textVariants)}
      />
      <Selector
        label='Tag'
        prop={tag2} setProp={setTag2}
        options={tags}
      />
      <Divider />
      <Selector
        label='Align'
        prop={align2} setProp={setAlign2}
        options={alignmentOptions}
      />
      <Selector
        label='Color'
        prop={selectedColor2} setProp={setSelectedColor2}
        options={colors}
      />
      <Selector
        label='Weight'
        prop={weight2} setProp={setWeight2}
        options={weights}
      />



    </>
  );



  // const Technical = (
  //   <>
  //     <TextInput
  //       label='Custom Path for SVG Document'
  //       prop={svgPath}
  //       placeholder='../icons.svg'
  //       setProp={(e) => setSvgPath(e.target.value)}
  //     />
  //   </>
  // );

  const text2Classes = [
    selectedColor2 === 'Text Primary' ? undefined : `color-${selectedColor2.toLowerCase().replaceAll(' ', '-')}`,
    weight2 === 'Default' ? undefined : `weight-${weight2.toLowerCase()}`,
    'align-right'
  ]
    .filter(Boolean)
    .join(' ');

  const SnippetComponent = (
    <VStack align="group">
      <TextComponent1
        tag={tag1 === 'P' ? undefined : tag1.toLowerCase()}
        color={selectedColor1 === 'Text Primary' ? undefined : selectedColor1.toLowerCase().replaceAll(' ', '-')}
        weight={weight1 === 'Default' ? undefined : weight1.toLowerCase()}
        align={align1 === 'Default' ? undefined : align1.toLowerCase()}
      >
        {selectedVariant1} Example
      </TextComponent1>
      <TextComponent2
        tag={tag2 === 'P' ? undefined : tag2.toLowerCase()}
        align={align2 === 'Default' ? undefined : align2.toLowerCase()}
      >
        {selectedVariant2} with<span
          className={text2Classes}
        > inline style</span>
      </TextComponent2>
    </VStack>
  );

  return (
    <>
      {thumbnail ? SnippetComponent :
        <TemplateVertical
          component={SnippetComponent}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
            // {
            //   name: 'Content',
            //   controls: Content
            // },
            // {
            //   name: 'Technical',
            //   controls: Technical
            // }
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

