import { Container, Input, Label, VStack } from '@themeable/react-components'
import React, { useEffect, useState } from 'react'
import { useContextValue } from '../AppContext';


function InputTextSize({ name, label, density }) {

    const { context, cText, setCText } = useContextValue();

    const handleTextStyleChange = (event, density) => {
		const { name, value } = event.target;

		setCText(prev => ({
			...prev,
			[density]: {
				...prev[density],
				[name]: value
			}
		}));
	};
    

  
    return (
        <VStack>
            <Label label={label}>
                <Container variant='fill'>
                    <Input 
                        type='number'
                        placeholder={context.systemList[context.selectedSystem].foundations.text[density][name]}
                        align='right'
                        trailingIconColor='text-secondary'
                        trailingIconName='Pixel'
                        onChange={(e) => {handleTextStyleChange(e, density)}}
                        name={name}
                        value={cText[density][name] || ''}
                        />
                </Container>
            </Label>
        </VStack>
    )
}

function InputLetterSpacing({ name, label, density }) {

    const { context, cText, setCText } = useContextValue();

    const handleTextStyleChange = (event, density) => {
		const { name, value } = event.target;

		setCText(prev => ({
			...prev,
			[density]: {
				...prev[density],
				[name]: value
			}
		}));
	};
    

  
    return (
        <VStack>
            <Label label={label}>
                <Container variant='fill'>
                    <Input 
                        type='number'
                        placeholder={context.systemList[context.selectedSystem].foundations.text[density][name]}
                        align='right'
                        trailingIconColor='text-secondary'
                        trailingIconName='EM'
                        onChange={(e) => {handleTextStyleChange(e, density)}}
                        name={name}
                        value={cText[density][name] || ''}
                        />
                </Container>
            </Label>
        </VStack>
    )
}

function InputWeight({ name, label }) {

    const { context, cText, setCText } = useContextValue();

    const handleTextStyleChange = (event) => {
		const { name, value } = event.target;

		setCText(prev => ({
			...prev,
			weight: {
				...prev.weight,
				[name]: value
			}
		}));
	};
  
    return (
        <VStack>
            <Label label={label}>
                <Container variant='fill'>
                    <Input 
                        type='number'
                        placeholder={context.systemList[context.selectedSystem].foundations.text.weight[name]}
                        align='right'
                        trailingIconColor='text-secondary'
                        onChange={(e) => {handleTextStyleChange(e)}}
                        name={name}
                        step='50'
                        value={cText.weight[name] || ''}
                        />
                </Container>
            </Label>
        </VStack>
    )
}





export { InputTextSize, InputLetterSpacing, InputWeight }
