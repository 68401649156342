import { Button, Container, Headline, HStack, Input, Section, Sheet, Subhead, VStack } from "@themeable/react-components/dist";
import { useRef, useState } from "react";
import './styles.css'

export function AddBlock({ insertBlock, index, position }) {

  const [imageDataModal, setImageDataModal] = useState(false);
  const addImageButtonRef = useRef(null);
  const [URL, setURL] = useState('');
  const [caption, setCaption] = useState('');

  return (
    <HStack align="group center" className={`add-block ${imageDataModal ? 'active' : ''}`}>
      <Button small width="hug"
        onClick={() => insertBlock(index, "RichText", "below")}
        icon1="Text"
        variant="quaternary"
      />
      <Button
        width="hug"
        ref={addImageButtonRef}
        small
        variant="quaternary"
        icon1="ImageThumbnail"
        onClick={() => setImageDataModal(!imageDataModal)}
      />
      <Sheet
        size="medium"
        active={imageDataModal}
        onDismiss={() => setImageDataModal(false)}
        anchor={addImageButtonRef}
      >
        <Section>
          <Headline align="center">Insert Image</Headline>
          <VStack align="group">
            <Subhead>Url</Subhead>
            <Container variant="fill">
              <Input
                placeholder='https://your-url.com'
                onChange={(e) => setURL(e.target.value)}
              />
            </Container>
          </VStack>
          <VStack align="group">
            <Subhead>Caption</Subhead>
            <Container variant="fill">
              <Input
                placeholder='alt text here'
                onChange={(e) => setCaption(e.target.value)}
              />
            </Container>
          </VStack>
          <Button
            text1="Insert Image"
            variant={caption && URL ? 'primary' : 'readOnly'}
            small
            onClick={() => {
              insertBlock(index, 'Image', position, { url: URL, caption: caption });
              setImageDataModal(false);
            }}
          />
        </Section>
      </Sheet>
    </HStack>
  )
}
