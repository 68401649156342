import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";

function ComponentSnippet({ thumbnail = false }) {

  const [switchValue, setSwitchValue] = useState(true);
  const [demo, setDemo] = useState('Input');
  const [layout, setLayout] = useState('Vertical');
  const [width, setWidth] = useState('Hug')
  const [textClass, setTextClass] = useState('Subhead')
  const [textColor, setTextColor] = useState('textSecondary')
  const [ratio, setRatio] = useState('1, 1');
  const [textValue, setTextValue] = useState('Label text')
  const [helperText, setHelperText] = useState('Helper text');
  const [error, setError] = useState(false);

  const Design = (
    <>
      <Selector
        label='Demo Example'
        prop={demo} setProp={setDemo}
        options={[
          'Input',
          'Switch',
          'Chips'
        ]} />
      <Divider />
      <ChipRow label='Width' prop={width} setProp={setWidth} options={['Hug', 'Fill']} />
      <ChipRow label='Layout' prop={layout} setProp={setLayout} options={['Vertical', 'Horizontal']} />
      {layout === 'horizontal' && width === 'fill' &&
        <TextInput label='Input / Label Ratio' placeholder='1, 1' prop={ratio} setProp={(e) => { setRatio(e.target.value) }} />
      }
    </>
  );

  const Content = (
    <>
      <TextInput label='Label text' placeholder='Label text' prop={textValue} setProp={(e) => setTextValue(e.target.value)} />
      <TextInput label='Helper Text' placeholder='Input first name' prop={helperText} setProp={(e) => setHelperText(e.target.value)} />
      <SwitchRow label='Error' prop={error} setProp={setError} />
    </>
  );


  const Technical = (
    <>
      {/* <PropCallout propName='active' propType='boolean' /> */}
      {/* <PropCallout propName='className' propType='string' /> */}
      {/* <PropCallout propName='onChange' propType='(e) => setActive(e)' /> */}
    </>
  );

  const SnippetComponent = demo === 'Chips' ? (
    layout === 'horizontal' ?
      <HStack group width={width} align='middle'>
        <VStack>
          <Label label={textValue} />
          <Footnote color={error ? 'negative' : 'text-secondary'}>
            {helperText}
          </Footnote>
        </VStack>
        <Space.Content />
        <VStack width="hug">
          <HStack width="hug">
            <Button text1="1" variant="primary" small />
            <Button text1="2" variant="secondary" small />
            <Button text1="3" variant="secondary" small />
          </HStack>
        </VStack>
      </HStack>
      :
      <VStack group width={width}>
        <Label label={textValue} />
        <Space.Content />
        <HStack width="hug">
          <Button text1="1" variant="primary" small />
          <Button text1="2" variant="secondary" small />
          <Button text1="3" variant="secondary" small />
        </HStack>
        <Footnote color={error ? 'negative' : 'text-secondary'}>
          {helperText}
        </Footnote>
      </VStack>

  ) : (
    <Label
      width={width.toLowerCase()}
      label={textValue}
      horizontal={layout.toLowerCase() === 'horizontal'}
      labelClass={textClass}
      labelColor={textColor}
      ratio={width === 'fill' ? ratio.replaceAll(' ', '').split(',').map(Number) : undefined}
      error={error}
      onErrorResolve={() => setError(false)}
      helperText={helperText}
    >
      {demo === 'Switch' && (
        <Switch
          onChange={(bool) => setSwitchValue(bool)}
          active={switchValue}
          className='demo'
        />
      )}
      {demo === 'Input' && (
        <Container variant="fill">
          <Input placeholder='Edit text' />
        </Container>
      )}
    </Label>
  );
  return (
    <>
      {thumbnail ? SnippetComponent :
        <TemplateVertical
          component={SnippetComponent}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
            {
              name: 'Content',
              controls: Content
            },
            // {
            //   name: 'Technical',
            //   controls: Technical
            // },
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

