import React, { useEffect, useContext } from 'react';
import Home from './page/Home/Home';
import WelcomeHome from './page/Home/WelcomeHome';
import Systems from './page/Systems/Systems';
import Signup from './page/Auth/Signup';
import Login from './page/Auth/Login';
import ForgotPassword from './page/Auth/forgotPassword';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import PrivateRoute from './page/Auth/auth-functions';
import { ThemeableProvider } from '@themeable/react-components'
import Roadmap from './page/Roadmap';
import Documentation from './page/Documentation';
import { useContextValue } from './AppContext';
import { collection, doc, getDoc, setDoc, updateDoc, arrayUnion, terminate } from "firebase/firestore";
import { db, functions, auth } from '../src/services/firebase';
import { AddAltThemeToDom } from './page/Utilities/UtilityFunctions';


function App() {

  const {
    context,
    setContext,
    setAltTheme,
    altTheme,
    cColor,
    setCColor,
    cRadius,
    setCRadius,
    cSpace,
    setCSpace,
    cSize,
    setCSize,
    cText,
    setCText
  } = useContextValue();


  const fetchUserData = async uid => {
    const docSnap = await getDoc(doc(db, "users", uid));
    const user = docSnap.data();

    if (user && user.systems && user.systems.subscribed) {
      const subscribedSystems = user.systems.subscribed;
      const systemDataPromises = subscribedSystems.map(async (element) => {
        const systemSettings = await getDoc(doc(db, "systems", element));
        const systemData = systemSettings.data();

        // Fetch data from the "staging/latest" document
        const stagingLatestDoc = await getDoc(doc(db, `systems/${element}/staging/latest`));
        const stagingLatestData = stagingLatestDoc.exists() ? stagingLatestDoc.data() : null;

        return { id: element, ...systemData, staging: stagingLatestData }; // Include the "staging/latest" data
      });

      const systemData = await Promise.all(systemDataPromises);
      const systemList = systemData.reduce((acc, curr) => {
        acc[curr?.id] = curr;
        return acc;
      }, {});
      setContext(prev => ({
        ...prev,
        systemList,
        user: user
      }));
    } else {
      setContext(prev => ({ ...prev, systemList: '' }));
    }
  };


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchUserData(user.uid);
      } else {
        // User is signed out
        // Clear user data or perform other actions
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {

    if (context?.systemList) {
      setAltTheme(context?.systemList[context?.selectedSystem]?.foundations)
    }

    if (context?.systemList) {
      const staging = context?.systemList[context?.selectedSystem]?.staging;
      setCColor(prev => staging?.cColor || prev);
      setCRadius(prev => staging?.cRadius || prev);
      setCSpace(prev => staging?.cSpace || prev);
      setCSize(prev => staging?.cSize || prev);
      setCText(prev => staging?.cText || prev);
    }
  }, [context?.systemList, context?.selectedSystem]);


  useEffect(() => {
    if (altTheme?.color !== undefined) {
      AddAltThemeToDom(altTheme, cColor, cRadius, cSpace, cSize, cText)
    }
  }, [altTheme, cColor, cRadius, cSpace, cSize, cText])


  return (
    <ThemeableProvider systemId='HfYd4W4R1Ii2lDoJZqQV'>
      <body style={{
        background: 'rgb(var(--color-background-flat))'
      }}>
        <Router>
          <Routes>

            <Route
              path="/"
              element={
                <PrivateRoute>
                  <WelcomeHome />
                </PrivateRoute>
              }
            />
            <Route
              path="/:token?"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/systems/:token?"
              element={
                <PrivateRoute>
                  <Systems />
                </PrivateRoute>
              }
            />
            <Route
              path="/roadmap"
              element={
                <PrivateRoute>
                  <Roadmap />
                </PrivateRoute>
              }
            />
            <Route path="/documentation/:topic?" element={<PrivateRoute><Documentation /></PrivateRoute>} />
            <Route path="/signup/:token?" element={<Signup />} />
            <Route path="/signup" element={<Signup />} />
            {/* <Route path="/login/:token?" element={<Login />} /> */}
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </Router>
      </body>
    </ThemeableProvider>
  );
}

export default App;
