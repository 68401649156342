import { Button, Container, Headline, HStack, Input, Section, Sheet, Subhead, VStack } from "@themeable/react-components/dist";
import { useRef, useState } from "react";

export function ImageSettingsButton({
  deleteBlock,
  index,
  element,
  setBodyUpdate,
  handleDone
}) {

  const [editImageDataModal, setEditImageDataModal] = useState(false);
  const editImageButtonRef = useRef(null);

  const handleImageUpdate = (index, field, value) => {
    setBodyUpdate((prevState) => {
      const updatedState = [...prevState];
      if (updatedState[index].type === "Image" && typeof updatedState[index].data !== "object") {
        updatedState[index].data = { url: "", caption: "" };
      }
      updatedState[index].data[field] = value;
      return updatedState;
    });
  };

  return (
    <>
      <VStack
        className={`block-controls ${editImageDataModal ? 'active' : ''}`}
        align='group'
      >
        <Button
          onClick={() => setEditImageDataModal(true)}
          small
          icon1="SettingsToggles"
          variant="quaternary"
          width="hug"
          ref={editImageButtonRef}
        />
        <Button
          onClick={() => deleteBlock(index)}
          small
          icon1="Trash"
          variant="quaternary"
          width="hug"
        />
      </VStack>
      <Sheet
        size="medium"
        active={editImageDataModal}
        onDismiss={() => {
          setEditImageDataModal(false);
          handleDone()
        }}
        anchor={editImageButtonRef}
      >
        <Section>
          <Headline align="center">Insert Image</Headline>
          <VStack align="group">
            <Subhead>Url</Subhead>
            <Container variant="fill">
              <Input
                placeholder='https://your-url.com'
                value={element.data.url}
                onChange={(e) => handleImageUpdate(index, "url", e.target.value)}
              />
            </Container>
          </VStack>
          <VStack align="group">
            <Subhead>Caption</Subhead>
            <Container variant="fill">
              <Input
                placeholder='alt text here'
                value={element.data.caption}
                onChange={(e) => handleImageUpdate(index, "caption", e.target.value)}
              />
            </Container>
          </VStack>
        </Section>
      </Sheet>
    </>
  )
}
