import React from "react";
import { Input, VStack, HStack, Space, Body, Subhead, Footnote, Title2, Title3, Headline, Container, Divider } from "@themeable/react-components";

const TextForm = ({ system, screenSize, handleInputChange, setScreenSize, textSnippet, setTextSnippet }) => {


  const text = system.foundations?.text?.[screenSize] || {};
  const textFamily = system.foundations?.text?.family || {};
  const textWeight = system.foundations?.text?.weight || {};

  return (
    <>

      <Title2>Font families</Title2>

      <Container variant='outline'>


        <Title3>Title Font Family</Title3>
        <Footnote color='text-secondary'>This applies to "Title 1", "Title 2", and "Title 3"</Footnote>
        <Divider />
        <Subhead color='text-secondary'>Font Family</Subhead>
        <HStack align='middle'>
          <Body color='text-secondary'>“</Body>
          <Input variant='fill' value={textFamily?.titleName} onChange={(e) => handleInputChange('textFamily', 'titleName', e)} />
          <Body color='text-secondary'>"</Body>
        </HStack>
        <Divider />
        <Subhead color='text-secondary'>Font Family Source</Subhead>
        <HStack align='middle'>
          <Body color='text-secondary'>@import url(“</Body>
          <Input variant='fill' value={textFamily.titleSrc} onChange={(e) => handleInputChange('textFamily', 'titleSrc', e)} />
          <Body color='text-secondary'>");</Body>
        </HStack>
        <Divider />
        <Subhead color='text-secondary'>Style</Subhead>
        <HStack>
          To do here
          {/* <RadioChip */}
          {/*   label="Serif" */}
          {/*   selected={ */}
          {/*     textFamily.titleFallback === '"New York", "Roboto Serif", ui-serif, serif'} */}
          {/*   onClick={() => handleInputChange( */}
          {/*     'textFamily', 'titleFallback', '"New York", "Roboto Serif", ui-serif, serif' */}
          {/*   )} /> */}
          {/* <RadioChip */}
          {/*   label="Sans Serif" */}
          {/*   selected={ */}
          {/*     textFamily.titleFallback === 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'} */}
          {/*   onClick={() => handleInputChange( */}
          {/*     'textFamily', 'titleFallback', 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"' */}
          {/*   )} /> */}
        </HStack>
        <Divider />


        <Subhead color='text-secondary'>Fallback fonts</Subhead>
        <Body>
          {textFamily.titleFallback}
        </Body>
      </Container>
      <Space.NewSection />
      <Container variant='outline'>

        <Title3>Main Font Family</Title3>
        <Footnote color='text-secondary'>This applies to "Headline", "Body", "Subhead", "Footnote", "Caption 1" and "Caption 2"</Footnote>
        <Divider />
        <Subhead color='text-secondary'>Font Family</Subhead>
        <HStack align='middle'>
          <Body color='text-secondary'>“</Body>
          <Input variant='fill' value={textFamily?.name} onChange={(e) => handleInputChange('textFamily', 'name', e)} />
          <Body color='text-secondary'>"</Body>
        </HStack>
        <Divider />
        <Subhead color='text-secondary'>Font Family Source</Subhead>
        <HStack align='middle'>
          <Body color='text-secondary'>@import url(“</Body>
          <Input variant='fill' value={textFamily.src} onChange={(e) => handleInputChange('textFamily', 'src', e)} />
          <Body color='text-secondary'>");</Body>
        </HStack>
        <Divider />
        <Subhead color='text-secondary'>Style</Subhead>
        <HStack>
          Here to do
          {/* <RadioChip */}
          {/*   label="Serif" */}
          {/*   selected={ */}
          {/*     textFamily.fallback === '"New York", "Roboto Serif", ui-serif, serif'} */}
          {/*   onClick={() => handleInputChange( */}
          {/*     'textFamily', 'fallback', '"New York", "Roboto Serif", ui-serif, serif' */}
          {/*   )} /> */}
          {/* <RadioChip */}
          {/*   label="Sans Serif" */}
          {/*   selected={ */}
          {/*     textFamily.fallback === 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'} */}
          {/*   onClick={() => handleInputChange( */}
          {/*     'textFamily', 'fallback', 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"' */}
          {/*   )} /> */}
        </HStack>
        <Divider />


        <Subhead color='text-secondary'>Fallback fonts</Subhead>
        <Body>
          {textFamily.fallback}
        </Body>
      </Container>

      <Space.NewSection />
      <Space.NewSection />
      <Space.NewSection />

      <Title2>Font styles</Title2>
      <Subhead>Note: all font sizes will render out at rem (for Web), pt (for iOS), dp (for Android), depending on the platform.</Subhead>
      <VStack style={{ position: 'sticky', zIndex: '2', top: '113px', background: 'rgb(var(--color-bg-elevated))' }}>
        <HStack align='middle'>
          <Subhead>
            Screen sizes:
          </Subhead>
          To do here
          {/* <RadioChip label="Compact" selected={screenSize === 'compact'} onClick={() => setScreenSize('compact')} /> */}
          {/* <RadioChip label="Medium" selected={screenSize === 'medium'} onClick={() => setScreenSize('medium')} /> */}
          {/* <RadioChip label="Expanded" selected={screenSize === 'expanded'} onClick={() => setScreenSize('expanded')} /> */}
        </HStack>
        <Divider nested />
      </VStack>




      <Container variant='outline'>


        <Title3>Title 1</Title3>
        <HStack align="bottom">
          <Input variant='fill' label='Font size' value={text.title1Size} onChange={(e) => handleInputChange('text', 'title1Size', e)} />
          <Input variant='fill' label='Line height' value={text.title1LineHeight} onChange={(e) => handleInputChange('text', 'title1LineHeight', e)} />
          <Input variant='fill' label='Letter spacing' value={text.title1LetterSpacing} onChange={(e) => handleInputChange('text', 'title1LetterSpacing', e)} />
        </HStack>
        <Divider />
        <Headline>Weights</Headline>
        <HStack>
          <Input variant='fill' label='Default' value={textWeight.title1} onChange={(e) => handleInputChange('textWeight', 'title1', e)} />
          <Input variant='fill' label='Light' value={textWeight.title1Light} onChange={(e) => handleInputChange('textWeight', 'title1Light', e)} />
          <Input variant='fill' label='Regular' value={textWeight.title1Regular} onChange={(e) => handleInputChange('textWeight', 'title1Regular', e)} />
          <Input variant='fill' label='Bold' value={textWeight.title1Bold} onChange={(e) => handleInputChange('textWeight', 'title1Bold', e)} />
        </HStack>

      </Container>
      <Container variant='outline'>

        <Title3>Title 2</Title3>
        <HStack>
          <Input variant='fill' label='Font size' value={text.title2Size} onChange={(e) => handleInputChange('text', 'title2Size', e)} />
          <Input variant='fill' label='Line height' value={text.title2LineHeight} onChange={(e) => handleInputChange('text', 'title2LineHeight', e)} />
          <Input variant='fill' label='Letter spacing' value={text.title2LetterSpacing} onChange={(e) => handleInputChange('text', 'title2LetterSpacing', e)} />
        </HStack>
        <Divider />
        <Headline>Weights</Headline>
        <HStack>
          <Input variant='fill' label='Default' value={textWeight.title2} onChange={(e) => handleInputChange('textWeight', 'title2', e)} />
          <Input variant='fill' label='Light' value={textWeight.title2Light} onChange={(e) => handleInputChange('textWeight', 'title2Light', e)} />
          <Input variant='fill' label='Regular' value={textWeight.title2Regular} onChange={(e) => handleInputChange('textWeight', 'title2Regular', e)} />
          <Input variant='fill' label='Bold' value={textWeight.title2Bold} onChange={(e) => handleInputChange('textWeight', 'title2Bold', e)} />
        </HStack>

      </Container>
      <Container variant='outline'>


        <Title3>Title 3</Title3>
        <HStack>
          <Input variant='fill' label='Font size' value={text.title3Size} onChange={(e) => handleInputChange('text', 'title3Size', e)} />
          <Input variant='fill' label='Line height' value={text.title3LineHeight} onChange={(e) => handleInputChange('text', 'title3LineHeight', e)} />
          <Input variant='fill' label='Letter spacing' value={text.title3LetterSpacing} onChange={(e) => handleInputChange('text', 'title3LetterSpacing', e)} />
        </HStack>
        <Divider />
        <Headline>Weights</Headline>
        <HStack>
          <Input variant='fill' label='Default' value={textWeight.title3} onChange={(e) => handleInputChange('textWeight', 'title3', e)} />
          <Input variant='fill' label='Light' value={textWeight.title3Light} onChange={(e) => handleInputChange('textWeight', 'title3Light', e)} />
          <Input variant='fill' label='Regular' value={textWeight.title3Regular} onChange={(e) => handleInputChange('textWeight', 'title3Regular', e)} />
          <Input variant='fill' label='Bold' value={textWeight.title3Bold} onChange={(e) => handleInputChange('textWeight', 'title3Bold', e)} />
        </HStack>

      </Container>
      <Container variant='outline'>

        <Title3>Headline</Title3>
        <HStack>
          <Input variant='fill' label='Font size' value={text.headlineSize} onChange={(e) => handleInputChange('text', 'headlineSize', e)} />
          <Input variant='fill' label='Line height' value={text.headlineLineHeight} onChange={(e) => handleInputChange('text', 'headlineLineHeight', e)} />
          <Input variant='fill' label='Letter spacing' value={text.headlineLetterSpacing} onChange={(e) => handleInputChange('text', 'headlineLetterSpacing', e)} />
        </HStack>
        <Divider />
        <Headline>Weights</Headline>
        <HStack>
          <Input variant='fill' label='Default' value={textWeight.headline} onChange={(e) => handleInputChange('textWeight', 'headline', e)} />
          <Input variant='fill' label='Light' value={textWeight.headlineLight} onChange={(e) => handleInputChange('textWeight', 'headlineLight', e)} />
          <Input variant='fill' label='Regular' value={textWeight.headlineRegular} onChange={(e) => handleInputChange('textWeight', 'headlineRegular', e)} />
          <Input variant='fill' label='Bold' value={textWeight.headlineBold} onChange={(e) => handleInputChange('textWeight', 'headlineBold', e)} />
        </HStack>

      </Container>
      <Container variant='outline'>

        <Title3>Body</Title3>
        <HStack>
          <Input variant='fill' label='Font size' value={text.bodySize} onChange={(e) => handleInputChange('text', 'bodySize', e)} />
          <Input variant='fill' label='Line height' value={text.bodyLineHeight} onChange={(e) => handleInputChange('text', 'bodyLineHeight', e)} />
          <Input variant='fill' label='Letter spacing' value={text.bodyLetterSpacing} onChange={(e) => handleInputChange('text', 'bodyLetterSpacing', e)} />
        </HStack>
        <Divider />
        <Headline>Weights</Headline>
        <HStack>
          <Input variant='fill' label='Default' value={textWeight.body} onChange={(e) => handleInputChange('textWeight', 'body', e)} />
          <Input variant='fill' label='Light' value={textWeight.bodyLight} onChange={(e) => handleInputChange('textWeight', 'bodyLight', e)} />
          <Input variant='fill' label='Regular' value={textWeight.bodyRegular} onChange={(e) => handleInputChange('textWeight', 'bodyRegular', e)} />
          <Input variant='fill' label='Bold' value={textWeight.bodyBold} onChange={(e) => handleInputChange('textWeight', 'bodyBold', e)} />
        </HStack>

      </Container>
      <Container variant='outline'>

        <Title3>Subhead</Title3>
        <HStack>
          <Input variant='fill' label='Font size' value={text.subheadSize} onChange={(e) => handleInputChange('text', 'subheadSize', e)} />
          <Input variant='fill' label='Line height' value={text.subheadLineHeight} onChange={(e) => handleInputChange('text', 'subheadLineHeight', e)} />
          <Input variant='fill' label='Letter spacing' value={text.subheadLetterSpacing} onChange={(e) => handleInputChange('text', 'subheadLetterSpacing', e)} />
        </HStack>
        <Divider />
        <Headline>Weights</Headline>
        <HStack>
          <Input variant='fill' label='Default' value={textWeight.subhead} onChange={(e) => handleInputChange('textWeight', 'subhead', e)} />
          <Input variant='fill' label='Light' value={textWeight.subheadLight} onChange={(e) => handleInputChange('textWeight', 'subheadLight', e)} />
          <Input variant='fill' label='Regular' value={textWeight.subheadRegular} onChange={(e) => handleInputChange('textWeight', 'subheadRegular', e)} />
          <Input variant='fill' label='Bold' value={textWeight.subheadBold} onChange={(e) => handleInputChange('textWeight', 'subheadBold', e)} />
        </HStack>

      </Container>
      <Container variant='outline'>

        <Title3>Footnote</Title3>
        <HStack>
          <Input variant='fill' label='Font size' value={text.footnoteSize} onChange={(e) => handleInputChange('text', 'footnoteSize', e)} />
          <Input variant='fill' label='Line height' value={text.footnoteLineHeight} onChange={(e) => handleInputChange('text', 'footnoteLineHeight', e)} />
          <Input variant='fill' label='Letter spacing' value={text.footnoteLetterSpacing} onChange={(e) => handleInputChange('text', 'footnoteLetterSpacing', e)} />
        </HStack>
        <Divider />
        <Headline>Weights</Headline>
        <HStack>
          <Input variant='fill' label='Default' value={textWeight.footnote} onChange={(e) => handleInputChange('textWeight', 'footnote', e)} />
          <Input variant='fill' label='Light' value={textWeight.footnoteLight} onChange={(e) => handleInputChange('textWeight', 'footnoteLight', e)} />
          <Input variant='fill' label='Regular' value={textWeight.footnoteRegular} onChange={(e) => handleInputChange('textWeight', 'footnoteRegular', e)} />
          <Input variant='fill' label='Bold' value={textWeight.footnoteBold} onChange={(e) => handleInputChange('textWeight', 'footnoteBold', e)} />
        </HStack>

      </Container>
      <Container variant='outline'>

        <Title3>Caption 1</Title3>
        <HStack>
          <Input variant='fill' label='Font size' value={text.caption1Size} onChange={(e) => handleInputChange('text', 'caption1Size', e)} />
          <Input variant='fill' label='Line height' value={text.caption1LineHeight} onChange={(e) => handleInputChange('text', 'caption1LineHeight', e)} />
          <Input variant='fill' label='Letter spacing' value={text.caption1LetterSpacing} onChange={(e) => handleInputChange('text', 'caption1LetterSpacing', e)} />
        </HStack>
        <Divider />
        <Headline>Weights</Headline>
        <HStack>
          <Input variant='fill' label='Default' value={textWeight.caption1} onChange={(e) => handleInputChange('textWeight', 'caption1', e)} />
          <Input variant='fill' label='Light' value={textWeight.caption1Light} onChange={(e) => handleInputChange('textWeight', 'caption1Light', e)} />
          <Input variant='fill' label='Regular' value={textWeight.caption1Regular} onChange={(e) => handleInputChange('textWeight', 'caption1Regular', e)} />
          <Input variant='fill' label='Bold' value={textWeight.caption1Bold} onChange={(e) => handleInputChange('textWeight', 'caption1Bold', e)} />
        </HStack>

      </Container>
      <Container variant='outline'>

        <Title3>Caption 2</Title3>
        <HStack>
          <Input variant='fill' label='Font size' value={text.caption2Size} onChange={(e) => handleInputChange('text', 'caption2Size', e)} />
          <Input variant='fill' label='Line height' value={text.caption2LineHeight} onChange={(e) => handleInputChange('text', 'caption2LineHeight', e)} />
          <Input variant='fill' label='Letter spacing' value={text.caption2LetterSpacing} onChange={(e) => handleInputChange('text', 'caption2LetterSpacing', e)} />
        </HStack>
        <Divider />
        <Headline>Weights</Headline>
        <HStack>
          <Input variant='fill' label='Default' value={textWeight.caption2} onChange={(e) => handleInputChange('textWeight', 'caption2', e)} />
          <Input variant='fill' label='Light' value={textWeight.caption2Light} onChange={(e) => handleInputChange('textWeight', 'caption2Light', e)} />
          <Input variant='fill' label='Regular' value={textWeight.caption2Regular} onChange={(e) => handleInputChange('textWeight', 'caption2Regular', e)} />
          <Input variant='fill' label='Bold' value={textWeight.caption2Bold} onChange={(e) => handleInputChange('textWeight', 'caption2Bold', e)} />
        </HStack>

      </Container>


    </>
  )

}

export { TextForm }
