import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Input, Section, Space, Subhead, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";

function ComponentSnippet({ thumbnail = false }) {
  const [width, setWidth] = useState('fill');
  const [height, setHeight] = useState('hug');
  const [variant, setVariant] = useState('Fill');
  const [fun, setFun] = useState('Auto');
  const [target, setTarget] = useState('Default');
  const [section, setSection] = useState(false);
  const [bodyText, setBodyText] = useState(true);
  const [button, setButton] = useState(false);
  const [input, setInput] = useState(false);
  const [showDemo, setShowDemo] = useState(true);
  const [demoTitle, setDemoTitle] = useState('');
  const [demoBody, setDemoBody] = useState('Lorem ipsum dolor sit amet, consectetur adipiscing elit.');


  const Design = (
    <>
      <Selector
        label='Variant'
        textColor={null}
        prop={variant} setProp={setVariant}
        options={['Default', 'Fill', 'Outline', 'Blank']} />
      <Divider />
      <ChipRow
        label='Width'
        textColor={null}
        prop={width} setProp={setWidth}
        options={['Hug', 'Fill']} />
      <Divider />
      <ChipRow
        label='Height'
        textColor={null}
        prop={height} setProp={setHeight}
        options={['Hug', 'Fill']} />
      <Divider />
      <SwitchRow
        label='Has Section'
        textColor={null}
        prop={section} setProp={setSection}
      />
    </>
  );

  const Content = (
    <>
      <SwitchRow
        label='Show Demo Content'
        textColor={null}
        prop={showDemo} setProp={setShowDemo}
        showDivider={false}
      />
      {showDemo &&
        <>
          <Divider />
          <TextInput
            prop={demoTitle}
            setProp={(e) => setDemoTitle(e.target.value)}
            placeholder='Enter text'
            label='Example title' />
          <TextInput
            prop={demoBody}
            setProp={(e) => setDemoBody(e.target.value)}
            placeholder='Enter text'
            label='Example body' />
          <Divider />
          <SwitchRow
            textColor={null}
            label='Show Input'
            showDivider={false}
            prop={input} setProp={setInput} />
          <Divider />
          <SwitchRow
            textColor={null}
            label='Show Button'
            showDivider={false}
            prop={button} setProp={setButton} />
        </>
      }
    </>
  );


  const Technical = (
    <>
      <ChipRow
        label='Interactive'
        prop={fun}
        setProp={setFun}
        options={["Auto", "True", "False"]}
      />
      {fun === 'link' &&
        <Selector
          label='Link Behavior'
          prop={target}
          setProp={setTarget}
          options={["Default", "_blank", "_top", "_parent"]}
        />
      }
      <Divider />
      <Space.Content />
      <Subhead> In addition to these props, we also offer: </Subhead>
      <Space.Content />
      <HStack wrap>
        <PropCallout propName='className' propType='string' />
        <PropCallout propName='key' propType='number' />
      </HStack>
    </>
  );

  const ButtonComponent = (
    <Container
      width={width}
      height={height}
      variant={toCamel(variant)}
      interactive={fun === 'true' ? true : fun === "false" ? false : undefined}
    >
      {section ? (
        <Section>
          {[
            showDemo && demoTitle && <Title3 key="title">{demoTitle}</Title3>,
            showDemo && demoBody && <Body key="body">{demoBody}</Body>,
            showDemo && input && <Input key="input" placeholder="Enter text" />,
            showDemo && button && <Button key="button" width="hug" variant="primary" text1="Action" small />,
          ].filter(Boolean)}
        </Section>
      ) : (
        // Render an array of valid children without wrapping in <>
        [
          showDemo && demoTitle && <Title3 key="title">{demoTitle}</Title3>,
          showDemo && demoBody && <Body key="body">{demoBody}</Body>,
          showDemo && input && <Input key="input" placeholder="Enter text" />,
          showDemo && button && <Button key="button" width="hug" variant="primary" text1="Action" small />,
        ].filter(Boolean) // Remove `null` or `false` values
      )}
    </Container>
  );

  return (
    <>
      {thumbnail ? ButtonComponent :
        <TemplateVertical
          component={ButtonComponent}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
            {
              name: 'Content',
              controls: Content
            },
            {
              name: 'Technical',
              controls: Technical
            }
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

