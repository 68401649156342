import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";
import '../styles.css'

function ComponentSnippet({ thumbnail = false }) {

  const [direction, setDirection] = useState('horizontal')
  const [maxWidth, setMaxWidth] = useState(undefined);
  const [align, setAlign] = useState('');
  const [showOn, setShowOn] = useState('');
  const [width, setWidth] = useState('fill');
  const [height, setHeight] = useState('hug');
  const [showOverflowBar, setShowOverflowBar] = useState(true);
  const [wrap, setWrap] = useState(false);
  const [scroll, setScroll] = useState('none');
  const [grid, setGrid] = useState('grid');
  const [gridCompact, setGridCompact] = useState('1');
  const [gridMedium, setGridMedium] = useState('2, 1');
  const [gridExpanded, setGridExpanded] = useState('3, 5, 4');
  const [group, setGroup] = useState(false);
  const [sectionSpacing, setSectionSpacing] = useState(false);

  const Design = (
    <>
      <ChipRow label='Direction' prop={direction} setProp={setDirection} options={['Horizontal', 'Vertical']} />
      {direction === 'horizontal' && <ChipRow label='Layout type' prop={grid} setProp={setGrid} options={['Default', 'Grid']} />}
      {grid === 'grid' &&
        <>
          <TextInput label='Grid on Compact' prop={gridCompact} setProp={(e) => setGridCompact(e.target.value)} placeholder='1, 2' />
          <TextInput label='Grid on Medium' prop={gridMedium} setProp={(e) => setGridMedium(e.target.value)} placeholder='2, 1, 1' />
          <TextInput label='Grid on Expanded' prop={gridExpanded} setProp={(e) => setGridExpanded(e.target.value)} placeholder='1, 3, 1, 1' />
        </>
      }
      {direction === 'horizontal' && grid === 'default' && <SwitchRow prop={wrap} setProp={setWrap} label='Wrap' />}
      {direction === 'horizontal' && grid === 'grid' && <SwitchRow label='Section Spacing' prop={sectionSpacing} setProp={setSectionSpacing} />}

      <Divider />
      <SwitchRow label='Group children' prop={group} setProp={setGroup} />
      <Divider />
      <ChipRow label='Width' prop={width} setProp={setWidth} options={['Hug', 'Fill']} />
      <TextInput
        label='Max Width'
        prop={maxWidth} setProp={(e) => setMaxWidth(e.target.value)}
        placeholder='400px'
      />
      <Divider />
      <ChipRow label='Height' prop={height} setProp={setHeight} options={['Hug', 'Fill']} />

      {direction === 'vertical' && height === 'fill' &&
        <>
          <ChipRow prop={scroll} setProp={setScroll} label='Scroll' options={['None', 'Vertical']} />
          {scroll !== 'none' && <SwitchRow prop={showOverflowBar} setProp={setShowOverflowBar} label='Show Overflow Bar' />}
        </>
      }
      <Divider />
      <TextInput
        label='Align'
        prop={align} setProp={(e) => setAlign(e.target.value)}
        placeholder='top, middle, center, etc'
      />
      <Divider />
      <TextInput
        label='Show on'
        prop={showOn} setProp={(e) => setShowOn(e.target.value)}
        placeholder='compact, medium, expanded'
      />
    </>
  );


  const Technical = (
    <>
      <PropCallout propType='string' propName='style' />
      <PropCallout propName='className' propType='string' />
      <PropCallout propName='ref' propType='useRef' />
    </>
  );

  const SnippetComponent = (
    <>
      {direction === 'horizontal' ? (
        <HStack
          className={`stack-demo ${sectionSpacing ? 's' : ''}`}
          maxWidth={maxWidth}
          align={align}
          showOn={showOn}
          width={width}
          height={height}
          showOverflowBar={showOverflowBar}
          wrap={wrap}
          gridCompact={grid === 'grid' ? gridCompact.split(', ').map(Number) : undefined}
          gridMedium={grid === 'grid' ? gridMedium.split(', ').map(Number) : undefined}
          group={group}
          gridExpanded={grid === 'grid' ? gridExpanded.split(', ').map(Number) : undefined}
          sectionSpacing={sectionSpacing}
        >
          <Button width={grid === 'default' ? "hug" : 'fill'} text1="Button" />
          <Button width={grid === 'default' ? "hug" : 'fill'} text1="Button" />
          <Button width={grid === 'default' ? "hug" : 'fill'} text1="Button" />
          <Button width={grid === 'default' ? "hug" : 'fill'} text1="Button" />
          <Button width={grid === 'default' ? "hug" : 'fill'} text1="Button" />
          <Button width={grid === 'default' ? "hug" : 'fill'} text1="Button" />
          <Button width={grid === 'default' ? "hug" : 'fill'} text1="Button" />
          <Button width={grid === 'default' ? "hug" : 'fill'} text1="Button" />
        </HStack>
      ) : (
        <VStack
          className="stack-demo"
          maxWidth={maxWidth}
          align={align}
          showOn={showOn}
          width={width}
          height={height}
          showOverflowBar={showOverflowBar}
          wrap={wrap}
          group={group}
          scroll={scroll}
        >
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
          <Button width="hug" text1="Button" />
        </VStack>
      )
      }
    </>
  );

  return (
    <>
      {thumbnail ? SnippetComponent :
        <TemplateVertical
          component={SnippetComponent}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
            {
              name: 'Technical',
              controls: Technical
            }
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

