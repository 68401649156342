import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Input, Space, Subhead, Tab, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";

function Component({ thumbnail = false }) {
  const [width, setWidth] = useState('hug');
  const [text, setText] = useState('Tab');
  const [showText, setShowText] = useState(true);
  const [small, setSmall] = useState(false);
  const [iconLayout, setIconLayout] = useState('leading');
  const [showIcon, setShowIcon] = useState(true);
  const [stacked, setStacked] = useState(false);
  const [fun, setFun] = useState('function');
  const [active, setActive] = useState('tab 1');
  const [indicator, setIndicator] = useState('top');

  const Design = (
    <>
      <SwitchRow
        label='Small'
        textColor={null}
        prop={small} setProp={setSmall} />
      <Divider />
      <ChipRow
        label='Width'
        textColor={null}
        prop={width} setProp={setWidth}
        options={['Hug', 'Fill']} />
      <Divider />
      <ChipRow
        label='Indicator Placement'
        textColor={null}
        prop={indicator} setProp={setIndicator}
        options={['Top', 'Bottom', 'None']} />
    </>
  );

  const Content = (
    <>
      <SwitchRow
        label='Text'
        textColor={null}
        prop={showText} setProp={setShowText}
        showDivider={false}
      />
      {showText &&
        <Container variant="fill">
          <Input placeholder='Enter text' onChange={(e) => setText(e.target.value)} value={text} />
        </Container>
      }
      <Divider />
      <SwitchRow
        textColor={null}
        label='Icon'
        showDivider={false}
        prop={showIcon} setProp={setShowIcon} />
      <Divider />
      {showIcon && showText &&
        <>
          <ChipRow
            label='Placement'
            prop={iconLayout} setProp={setIconLayout}
            showDivider={false}
            options={['Leading', 'Trailing']}
          />
          <Divider />
          <SwitchRow
            label='Stacked'
            showDivider={false}
            prop={stacked} setProp={setStacked} />
        </>
      }
    </>
  );


  const Technical = (
    <>
      <Divider />
      <ChipRow
        label='Click Behavior'
        prop={fun}
        setProp={setFun}
        options={["Function", "Link"]}
      />
      <Divider />
      <Space.Content />
      <Subhead> In addition to these props, we also offer: </Subhead>
      <Space.Content />
      <HStack wrap>
        <PropCallout propName='key' propType='string' />
      </HStack>
    </>
  );

  const Snippet = (
    <HStack width={width} align='center'>
      <Tab
        text1={showText && text + ' 1'}
        stacked={stacked}
        width={width}
        active={active === 'tab 1'}
        onClick={fun === 'function' && (() => setActive('tab 1'))}
        small={small}
        url={fun === 'link' && 'https://www.google.com'}
        icon1={iconLayout === 'leading' && showIcon && 'Placeholder'}
        icon2={iconLayout === 'trailing' && showIcon && 'Placeholder'}
        indicator={indicator}
      />
      <Tab
        text1={showText && text + ' 2'}
        stacked={stacked}
        width={width}
        active={active === 'tab 2'}
        onClick={() => setActive('tab 2')}
        small={small}
        url={fun === 'link' && 'https://www.google.com'}
        icon1={iconLayout === 'leading' && showIcon && 'Placeholder'}
        icon2={iconLayout === 'trailing' && showIcon && 'Placeholder'}
        indicator={indicator}
      />
    </HStack>

  );

  return (
    <>
      {thumbnail ? Snippet :
        <TemplateVertical
          component={Snippet}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
            {
              name: 'Content',
              controls: Content
            },
            {
              name: 'Technical',
              controls: Technical
            }
          ]}
        />
      }
    </>
  );
}

export default Component;

