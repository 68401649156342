import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Logo, LogoHorizontal, LogoVertical } from './Logo';
import { signOut } from "firebase/auth";
import { auth, db, functions } from '../services/firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { handleStripeSession, handleCheckout } from '../services/stripe';
import { Sheet, Button, Tab, Container, Space, Popover, Section, Media, HStack, Cell, VStack, Body, Icon, Loader, Footnote, Title3 } from '@themeable/react-components';
import { CreateNewSystem } from '../page/Utilities/UtilityFunctions';
import './Nav.css'

const GlobalNav = ({ children }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const childrenRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const profileRef = useRef(null);
  const [profileModal, setProfileModal] = useState(false);



  useEffect(() => {
    const handleScroll = () => {
      // const scrollY = window.scrollY;
      // const childrenTop = childrenRef.current.offsetTop;
      // setIsSticky(scrollY >= childrenTop);

    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [children]);

  const stick = isSticky ? 'stick' : '';



  const userInfo = async () => {
    const user = auth.currentUser;
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      setUserImage(userDoc.data().img);
      setSubscriptionStatus(userDoc.data().subscription)
    }
  };


  const navigateToFeedbackForm = () => {
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfQykfbAIeHjAxEurwNfPZikYmQ9FY5P4CJjVSJKX6DzILTKA/viewform'
  }
  useEffect(() => {
    userInfo();
  }, []);

  const onLogout = () => {
    signOut(auth).then(() => {
      navigate('/login')
    }).catch((error) => {
    });
  }

  const handlePortalSession = async () => {
    setLoadingStripe(true)
    const error = await handleStripeSession();
    if (error) {
      setErrorMessage(error);
      setLoadingStripe(false)
    }
  }

  const handlePaymentCheckout = async (priceId) => {
    const error = await handleCheckout(priceId);
    if (error) {
      setErrorMessage(error);
    }
  }

  return (
    <nav className={`nav ${stick}`} >
      <Section style={{ paddingBottom: '0px', paddingTop: '0px' }}>

        <HStack align='middle'>

          <Button width='hug' onClick={() => navigate('../')}>
            <HStack className='showOnExpanded showOnMedium'>
              <LogoHorizontal />
            </HStack>
            <HStack className='showOnCompact'>
              <Logo />
            </HStack>
          </Button>

          <HStack width='fill' align='group right'>
            {/* <Tab small active={location.pathname === '/'} text1='Home' onClick={() => navigate('../')} indicator='bottom' /> */}
            {/* <Tab small active={location.pathname === '/roadmap'} text1='Roadmap' onClick={() => navigate('../roadmap')} indicator='bottom' /> */}
            {/* <Tab small active={location.pathname === '/documentation'} text1='Documentation' onClick={() => navigate('../documentation')} indicator='bottom' /> */}
            <Button width='hug' className='showOnMedium showOnExpanded' text1='Create system' icon1='PlusCircle' small onClick={() => { CreateNewSystem() }} />
            <Button className='showOnCompact' width='hug' icon1='PlusCircle' small onClick={() => { CreateNewSystem() }} />


            <Button width='hug' ref={profileRef} onClick={() => setProfileModal(!profileModal)}>
              <Media
                profile
                size='small'
              >
                {auth.currentUser.email.charAt(0).toUpperCase()}
              </Media>
            </Button>
          </HStack>

          <Sheet anchor={profileRef} active={profileModal} onDismiss={() => setProfileModal(false)}>
            <Section>
              <Cell onClick={onLogout} >
                <HStack align='middle'>
                  <Body>Log Out</Body>
                  <Icon name='LogIn' />
                </HStack>
              </Cell>

              <Cell onClick={() => navigateToFeedbackForm()} >
                <HStack align='middle'>
                  <Body>Give Feedback</Body>
                </HStack>
              </Cell>

              {subscriptionStatus === 'premium' ? (
                <Cell
                  variant='button'
                  onClick={handlePortalSession}


                >
                  <HStack align='middle'>
                    <Body>Manage Billing</Body>
                    {loadingStripe ? (<Loader color='icon' />) : null}
                  </HStack>
                </Cell>

              ) : (<></>)}


              {subscriptionStatus !== 'premium' ? (

                <Container variant='fill'>
                  <Cell
                    variant='button'
                    onClick={() => { handlePaymentCheckout('price_1Nf8ItHUEMXMwifTAxyefQ8d'); setLoadingStripe(true); }}


                  >
                    <Body>Upgrade Account</Body>
                    {errorMessage && <Footnote color='negative'>{errorMessage}</Footnote>}
                    {loadingStripe ? (<Loader color='icon' />) : null}
                  </Cell>
                </Container>
              ) : (<></>)}
            </Section>
          </Sheet>




        </HStack>
      </Section>

      {
        children && (<>
          <Space.Content />
          <div ref={childrenRef} className="child-sticky-section">
            {children}
          </div>
        </>)
      }
    </nav >
  )
}

GlobalNav.propTypes = {
  label: PropTypes.string,
}

export default GlobalNav
