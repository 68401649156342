import React, { useEffect, useState } from 'react'
import FoundationsPanel from '../../modules/FoundationsPanel'
import { Accordion, Body, Button, Caption1, Caption2, Container, Divider, Footnote, HStack, Headline, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Tab, Tag, Title1, Title2, Title3, VStack } from '@themeable/react-components'
import { useContextValue } from '../../AppContext';
import { InputRadius } from '../../modules/InputPixel';
import { InputLetterSpacing, InputTextSize, InputWeight } from '../../modules/InputTextSize'
import TextStylePanel from '../../modules/TextStylePanel';

function TextStyles() {

	const [personalizedText, setPersonalizedText] = useState('');
	const { context, cText, setCText } = useContextValue();
	const contextText = context.systemList[context.selectedSystem].foundations.text;
	
	
	const handleTextStyleChange = (event, density) => {
		const { name, value } = event.target;

		setCText(prev => ({
			...prev,
			[density]: {
				...prev.family,
				[name]: value
			}
		}));
	};
	
	useEffect(() => {
		console.log(cText)
		// console.log(contextText)
	}, [cText])



return (<>
	<VStack grow>


	{/* <Button url='https://getthemeable.com/education/how-to-create-a-text-style-system'>
		<Container style={{
			backgroundImage: 'url(../assets/help-articles/foundation-help-text-styles.jpg)',
			backgroundPosition: 'center center',
			backgroundSize: 'cover',
			}}>
			<Section>
				<VStack align='middle center'>
					<Title2 color='on-primary'>
						Text styles
					</Title2>
					<HStack wrap>
							<Headline color='on-primary'>
								Not sure what your settings should be? Check out this article
							</Headline>
							<Icon name='ArrowRight' color='on-primary'/>
					</HStack>
				</VStack>
			</Section>
		</Container>
	</Button> */}


		<TextStylePanel 
			header='Title 1'
			name='title1'
			children={<>
				<span className='alt-theme compact'>
					<Label label='Personalized text'>
						<Container>
							<Input
								value={personalizedText}
								placeholder='Title 1 at compact size'
								onChange={(e) => {setPersonalizedText(e.target.value)}}
								/>
						</Container>
					</Label>
				</span>
				<Divider />
				<Title1 className='alt-theme compact'>
					{personalizedText !== ''?personalizedText:('Title 1 at compact size')}
					</Title1>
				<Title1 className='alt-theme compact' weight='light'>
					{personalizedText !== ''?personalizedText:('Title 1 (Light) at compact size')}
					</Title1>
				<Title1 className='alt-theme compact' weight='regular'>
					{personalizedText !== ''?personalizedText:('Title 1 (Regular) at compact size')}
					</Title1>
				<Title1 className='alt-theme compact' weight='bold'>
					{personalizedText !== ''?personalizedText:('Title 1 (Bold) at compact size')}
					</Title1>
				<Divider />
				<Title1 className='alt-theme medium'>
					{personalizedText !== ''?personalizedText:('Title 1 at medium size')}
					</Title1>
				<Title1 className='alt-theme medium' weight='light'>
					{personalizedText !== ''?personalizedText:('Title 1 (Light) at medium size')}
					</Title1>
				<Title1 className='alt-theme medium' weight='regular'>
					{personalizedText !== ''?personalizedText:('Title 1 (Regular) at medium size')}
					</Title1>
				<Title1 className='alt-theme medium' weight='bold'>
					{personalizedText !== ''?personalizedText:('Title 1 (Bold) at medium size')}
					</Title1>
				<Divider />
				<Title1 className='alt-theme expanded'>
					{personalizedText !== ''?personalizedText:('Title 1 at expanded size')}
					</Title1>
				<Title1 className='alt-theme expanded' weight='light'>
					{personalizedText !== ''?personalizedText:('Title 1 (Light) at expanded size')}
					</Title1>
				<Title1 className='alt-theme expanded' weight='regular'>
					{personalizedText !== ''?personalizedText:('Title 1 (Regular) at expanded size')}
					</Title1>
				<Title1 className='alt-theme expanded' weight='bold'>
					{personalizedText !== ''?personalizedText:('Title 1 (Bold) at expanded size')}
					</Title1>

			</>}
		/>
		
		<TextStylePanel 
			header='Title 2'
			name='title2'
			children={<>
				<span className='alt-theme compact'>
					<Label label='Personalized text'>
						<Container>
							<Input
								value={personalizedText}
								placeholder='Title 2 at compact size'
								onChange={(e) => {setPersonalizedText(e.target.value)}}
								/>
						</Container>
					</Label>
				</span>
				<Divider />
				<Title2 className='alt-theme compact'>
					{personalizedText !== ''?personalizedText:('Title 2 at compact size')}
				</Title2>
				<Title2 className='alt-theme compact' weight='light'>
					{personalizedText !== ''?personalizedText:('Title 2 (Light) at compact size')}
				</Title2>
				<Title2 className='alt-theme compact' weight='regular'>
					{personalizedText !== ''?personalizedText:('Title 2 (Regular) at compact size')}
				</Title2>
				<Title2 className='alt-theme compact' weight='bold'>
					{personalizedText !== ''?personalizedText:('Title 2 (Bold) at compact size')}
				</Title2>
				<Divider />
				<Title2 className='alt-theme medium'>
					{personalizedText !== ''?personalizedText:('Title 2 at medium size')}
				</Title2>
				<Title2 className='alt-theme medium' weight='light'>
					{personalizedText !== ''?personalizedText:('Title 2 (Light) at medium size')}
				</Title2>
				<Title2 className='alt-theme medium' weight='regular'>
					{personalizedText !== ''?personalizedText:('Title 2 (Regular) at medium size')}
				</Title2>
				<Title2 className='alt-theme medium' weight='bold'>
					{personalizedText !== ''?personalizedText:('Title 2 (Bold) at medium size')}
				</Title2>
				<Divider />
				<Title2 className='alt-theme expanded'>
					{personalizedText !== ''?personalizedText:('Title 2 at expanded size')}
				</Title2>
				<Title2 className='alt-theme expanded' weight='light'>
					{personalizedText !== ''?personalizedText:('Title 2 (Light) at expanded size')}
				</Title2>
				<Title2 className='alt-theme expanded' weight='regular'>
					{personalizedText !== ''?personalizedText:('Title 2 (Regular) at expanded size')}
				</Title2>
				<Title2 className='alt-theme expanded' weight='bold'>
					{personalizedText !== ''?personalizedText:('Title 2 (Bold) at expanded size')}
				</Title2>
			</>}
		/>

		<TextStylePanel 
			header='Title 3'
			name='title3'
			children={<>
				<span className='alt-theme compact'>
					<Label label='Personalized text'>
						<Container>
							<Input
								value={personalizedText}
								placeholder='Title 3 at compact size'
								onChange={(e) => {setPersonalizedText(e.target.value)}}
								/>
						</Container>
					</Label>
				</span>
				<Divider />
				<Title3 className='alt-theme compact'>
					{personalizedText !== ''?personalizedText:('Title 3 at compact size')}
				</Title3>
				<Title3 className='alt-theme compact' weight='light'>
					{personalizedText !== ''?personalizedText:('Title 3 (Light) at compact size')}
				</Title3>
				<Title3 className='alt-theme compact' weight='regular'>
					{personalizedText !== ''?personalizedText:('Title 3 (Regular) at compact size')}
				</Title3>
				<Title3 className='alt-theme compact' weight='bold'>
					{personalizedText !== ''?personalizedText:('Title 3 (Bold) at compact size')}
				</Title3>
				<Divider />
				<Title3 className='alt-theme medium'>
					{personalizedText !== ''?personalizedText:('Title 3 at medium size')}
				</Title3>
				<Title3 className='alt-theme medium' weight='light'>
					{personalizedText !== ''?personalizedText:('Title 3 (Light) at medium size')}
				</Title3>
				<Title3 className='alt-theme medium' weight='regular'>
					{personalizedText !== ''?personalizedText:('Title 3 (Regular) at medium size')}
				</Title3>
				<Title3 className='alt-theme medium' weight='bold'>
					{personalizedText !== ''?personalizedText:('Title 3 (Bold) at medium size')}
				</Title3>
				<Divider />
				<Title3 className='alt-theme expanded'>
					{personalizedText !== ''?personalizedText:('Title 3 at expanded size')}
				</Title3>
				<Title3 className='alt-theme expanded' weight='light'>
					{personalizedText !== ''?personalizedText:('Title 3 (Light) at expanded size')}
				</Title3>
				<Title3 className='alt-theme expanded' weight='regular'>
					{personalizedText !== ''?personalizedText:('Title 3 (Regular) at expanded size')}
				</Title3>
				<Title3 className='alt-theme expanded' weight='bold'>
					{personalizedText !== ''?personalizedText:('Title 3 (Bold) at expanded size')}
				</Title3>
			</>}
		/>

		<TextStylePanel 
			header='Headline'
			name='headline'
			children={<>
				<span className='alt-theme compact'>
					<Label label='Personalized text'>
						<Container>
							<Input
								value={personalizedText}
								placeholder='Headline at compact size'
								onChange={(e) => {setPersonalizedText(e.target.value)}}
								/>
						</Container>
					</Label>
				</span>
				<Divider />
				<Headline className='alt-theme compact'>
					{personalizedText !== ''?personalizedText:('Headline at compact size')}
				</Headline>
				<Headline className='alt-theme compact' weight='light'>
					{personalizedText !== ''?personalizedText:('Headline (Light) at compact size')}
				</Headline>
				<Headline className='alt-theme compact' weight='regular'>
					{personalizedText !== ''?personalizedText:('Headline (Regular) at compact size')}
				</Headline>
				<Headline className='alt-theme compact' weight='bold'>
					{personalizedText !== ''?personalizedText:('Headline (Bold) at compact size')}
				</Headline>
				<Divider />
				<Headline className='alt-theme medium'>
					{personalizedText !== ''?personalizedText:('Headline at medium size')}
				</Headline>
				<Headline className='alt-theme medium' weight='light'>
					{personalizedText !== ''?personalizedText:('Headline (Light) at medium size')}
				</Headline>
				<Headline className='alt-theme medium' weight='regular'>
					{personalizedText !== ''?personalizedText:('Headline (Regular) at medium size')}
				</Headline>
				<Headline className='alt-theme medium' weight='bold'>
					{personalizedText !== ''?personalizedText:('Headline (Bold) at medium size')}
				</Headline>
				<Divider />
				<Headline className='alt-theme expanded'>
					{personalizedText !== ''?personalizedText:('Headline at expanded size')}
				</Headline>
				<Headline className='alt-theme expanded' weight='light'>
					{personalizedText !== ''?personalizedText:('Headline (Light) at expanded size')}
				</Headline>
				<Headline className='alt-theme expanded' weight='regular'>
					{personalizedText !== ''?personalizedText:('Headline (Regular) at expanded size')}
				</Headline>
				<Headline className='alt-theme expanded' weight='bold'>
					{personalizedText !== ''?personalizedText:('Headline (Bold) at expanded size')}
				</Headline>
			</>}
		/>
	
		<TextStylePanel 
			header='Body'
			name='body'
			children={<>
				<span className='alt-theme compact'>
					<Label label='Personalized text'>
						<Container>
							<Input
								value={personalizedText}
								placeholder='Body at compact size'
								onChange={(e) => {setPersonalizedText(e.target.value)}}
								/>
						</Container>
					</Label>
				</span>
				<Divider />
				<Body className='alt-theme compact'>
					{personalizedText !== ''?personalizedText:('Body at compact size')}
				</Body>
				<Body className='alt-theme compact' weight='light'>
					{personalizedText !== ''?personalizedText:('Body (Light) at compact size')}
				</Body>
				<Body className='alt-theme compact' weight='regular'>
					{personalizedText !== ''?personalizedText:('Body (Regular) at compact size')}
				</Body>
				<Body className='alt-theme compact' weight='bold'>
					{personalizedText !== ''?personalizedText:('Body (Bold) at compact size')}
				</Body>
				<Divider />
				<Body className='alt-theme medium'>
					{personalizedText !== ''?personalizedText:('Body at medium size')}
				</Body>
				<Body className='alt-theme medium' weight='light'>
					{personalizedText !== ''?personalizedText:('Body (Light) at medium size')}
				</Body>
				<Body className='alt-theme medium' weight='regular'>
					{personalizedText !== ''?personalizedText:('Body (Regular) at medium size')}
				</Body>
				<Body className='alt-theme medium' weight='bold'>
					{personalizedText !== ''?personalizedText:('Body (Bold) at medium size')}
				</Body>
				<Divider />
				<Body className='alt-theme expanded'>
					{personalizedText !== ''?personalizedText:('Body at expanded size')}
				</Body>
				<Body className='alt-theme expanded' weight='light'>
					{personalizedText !== ''?personalizedText:('Body (Light) at expanded size')}
				</Body>
				<Body className='alt-theme expanded' weight='regular'>
					{personalizedText !== ''?personalizedText:('Body (Regular) at expanded size')}
				</Body>
				<Body className='alt-theme expanded' weight='bold'>
					{personalizedText !== ''?personalizedText:('Body (Bold) at expanded size')}
				</Body>
			</>}
		/>
		
		<TextStylePanel 
			header='Subhead'
			name='subhead'
			children={<>
				<span className='alt-theme compact'>
					<Label label='Personalized text'>
						<Container>
							<Input
								value={personalizedText}
								placeholder='Subhead at compact size'
								onChange={(e) => {setPersonalizedText(e.target.value)}}
								/>
						</Container>
					</Label>
				</span>
				<Divider />
				<Subhead className='alt-theme compact'>
					{personalizedText !== ''?personalizedText:('Subhead at compact size')}
				</Subhead>
				<Subhead className='alt-theme compact' weight='light'>
					{personalizedText !== ''?personalizedText:('Subhead (Light) at compact size')}
				</Subhead>
				<Subhead className='alt-theme compact' weight='regular'>
					{personalizedText !== ''?personalizedText:('Subhead (Regular) at compact size')}
				</Subhead>
				<Subhead className='alt-theme compact' weight='bold'>
					{personalizedText !== ''?personalizedText:('Subhead (Bold) at compact size')}
				</Subhead>
				<Divider />
				<Subhead className='alt-theme medium'>
					{personalizedText !== ''?personalizedText:('Subhead at medium size')}
				</Subhead>
				<Subhead className='alt-theme medium' weight='light'>
					{personalizedText !== ''?personalizedText:('Subhead (Light) at medium size')}
				</Subhead>
				<Subhead className='alt-theme medium' weight='regular'>
					{personalizedText !== ''?personalizedText:('Subhead (Regular) at medium size')}
				</Subhead>
				<Subhead className='alt-theme medium' weight='bold'>
					{personalizedText !== ''?personalizedText:('Subhead (Bold) at medium size')}
				</Subhead>
				<Divider />
				<Subhead className='alt-theme expanded'>
					{personalizedText !== ''?personalizedText:('Subhead at expanded size')}
				</Subhead>
				<Subhead className='alt-theme expanded' weight='light'>
					{personalizedText !== ''?personalizedText:('Subhead (Light) at expanded size')}
				</Subhead>
				<Subhead className='alt-theme expanded' weight='regular'>
					{personalizedText !== ''?personalizedText:('Subhead (Regular) at expanded size')}
				</Subhead>
				<Subhead className='alt-theme expanded' weight='bold'>
					{personalizedText !== ''?personalizedText:('Subhead (Bold) at expanded size')}
				</Subhead>
			</>}
		/>
		
		
		
		<TextStylePanel 
			header='Caption 1'
			name='caption1'
			children={<>
				<span className='alt-theme compact'>
					<Label label='Personalized text'>
						<Container>
							<Input
								value={personalizedText}
								placeholder='Caption 1 at compact size'
								onChange={(e) => {setPersonalizedText(e.target.value)}}
								/>
						</Container>
					</Label>
				</span>
				<Divider />
				<Caption1 className='alt-theme compact'>
					{personalizedText !== ''?personalizedText:('Caption 1 at compact size')}
					</Caption1>
				<Caption1 className='alt-theme compact' weight='light'>
					{personalizedText !== ''?personalizedText:('Caption 1 (Light) at compact size')}
					</Caption1>
				<Caption1 className='alt-theme compact' weight='regular'>
					{personalizedText !== ''?personalizedText:('Caption 1 (Regular) at compact size')}
					</Caption1>
				<Caption1 className='alt-theme compact' weight='bold'>
					{personalizedText !== ''?personalizedText:('Caption 1 (Bold) at compact size')}
					</Caption1>
				<Divider />
				<Caption1 className='alt-theme medium'>
					{personalizedText !== ''?personalizedText:('Caption 1 at medium size')}
					</Caption1>
				<Caption1 className='alt-theme medium' weight='light'>
					{personalizedText !== ''?personalizedText:('Caption 1 (Light) at medium size')}
					</Caption1>
				<Caption1 className='alt-theme medium' weight='regular'>
					{personalizedText !== ''?personalizedText:('Caption 1 (Regular) at medium size')}
					</Caption1>
				<Caption1 className='alt-theme medium' weight='bold'>
					{personalizedText !== ''?personalizedText:('Caption 1 (Bold) at medium size')}
					</Caption1>
				<Divider />
				<Caption1 className='alt-theme expanded'>
					{personalizedText !== ''?personalizedText:('Caption 1 at expanded size')}
					</Caption1>
				<Caption1 className='alt-theme expanded' weight='light'>
					{personalizedText !== ''?personalizedText:('Caption 1 (Light) at expanded size')}
					</Caption1>
				<Caption1 className='alt-theme expanded' weight='regular'>
					{personalizedText !== ''?personalizedText:('Caption 1 (Regular) at expanded size')}
					</Caption1>
				<Caption1 className='alt-theme expanded' weight='bold'>
					{personalizedText !== ''?personalizedText:('Caption 1 (Bold) at expanded size')}
					</Caption1>

			</>}
		/>
		
		<TextStylePanel 
			header='Caption 2'
			name='caption2'
			children={<>
				<span className='alt-theme compact'>
					<Label label='Personalized text'>
						<Container>
							<Input
								value={personalizedText}
								placeholder='Caption 2 at compact size'
								onChange={(e) => {setPersonalizedText(e.target.value)}}
								/>
						</Container>
					</Label>
				</span>
				<Divider />
				<Caption2 className='alt-theme compact'>
					{personalizedText !== ''?personalizedText:('Caption 2 at compact size')}
					</Caption2>
				<Caption2 className='alt-theme compact' weight='light'>
					{personalizedText !== ''?personalizedText:('Caption 2 (Light) at compact size')}
					</Caption2>
				<Caption2 className='alt-theme compact' weight='regular'>
					{personalizedText !== ''?personalizedText:('Caption 2 (Regular) at compact size')}
					</Caption2>
				<Caption2 className='alt-theme compact' weight='bold'>
					{personalizedText !== ''?personalizedText:('Caption 2 (Bold) at compact size')}
					</Caption2>
				<Divider />
				<Caption2 className='alt-theme medium'>
					{personalizedText !== ''?personalizedText:('Caption 2 at medium size')}
					</Caption2>
				<Caption2 className='alt-theme medium' weight='light'>
					{personalizedText !== ''?personalizedText:('Caption 2 (Light) at medium size')}
					</Caption2>
				<Caption2 className='alt-theme medium' weight='regular'>
					{personalizedText !== ''?personalizedText:('Caption 2 (Regular) at medium size')}
					</Caption2>
				<Caption2 className='alt-theme medium' weight='bold'>
					{personalizedText !== ''?personalizedText:('Caption 2 (Bold) at medium size')}
					</Caption2>
				<Divider />
				<Caption2 className='alt-theme expanded'>
					{personalizedText !== ''?personalizedText:('Caption 2 at expanded size')}
					</Caption2>
				<Caption2 className='alt-theme expanded' weight='light'>
					{personalizedText !== ''?personalizedText:('Caption 2 (Light) at expanded size')}
					</Caption2>
				<Caption2 className='alt-theme expanded' weight='regular'>
					{personalizedText !== ''?personalizedText:('Caption 2 (Regular) at expanded size')}
					</Caption2>
				<Caption2 className='alt-theme expanded' weight='bold'>
					{personalizedText !== ''?personalizedText:('Caption 2 (Bold) at expanded size')}
					</Caption2>

			</>}
		/>
		

		
		
		

	</VStack>

</>)
}

export default TextStyles
