import { Container, HStack, Input, Label } from '@themeable/react-components'
import React, { useEffect } from 'react'
import { useContextValue } from '../AppContext';

function InputColor({ label, name }) {

  const { context, cColor, setCColor } = useContextValue();
  const color = context?.systemList[context?.selectedSystem]?.foundations?.color[context?.colorMode]


  const handleColorChange = (event) => {
    const { name, value } = event.target;
    const validColorRegex = /^[0-9A-Za-z]{0,6}$/;
    if (value.match(validColorRegex)) {
      setCColor(prev => {
        const oppositeColorMode = context.colorMode === 'light' ? 'dark' : 'light';
        return {
          ...prev,
          [context.colorMode]: {
            ...prev[context.colorMode],
            [name]: value ? `#${value.toUpperCase()}` : '' // Conditional setting
          },
          [oppositeColorMode]: {
            [name]: '',
            ...prev[oppositeColorMode],
          }
        };
      });
    }
  };

  const trimColor = (hex) => {
    if (hex !== undefined) {
      return hex.substring(1).toUpperCase();
    } else return ''
  }

  return (
    <Label label={label}>
      <Container variant='fill'>
        <HStack align='middle left'>
          <Input
            leadingIconColor='text-secondary'
            leadingIconName='Number'
            onChange={handleColorChange}
            placeholder={trimColor(color[name])}
            name={name}
            value={trimColor(cColor[context.colorMode][name])}
          />
          <div
            style={{
              width: '24px',
              height: '24px',
              background: (
                cColor[context.colorMode][name] === undefined || // Check if undefined
                  cColor[context.colorMode][name] === null || // Check if null
                  cColor[context.colorMode][name] === '#' || // Check if null
                  cColor[context.colorMode][name] === '' // Check if empty string
                  ? color[name]
                  : cColor[context.colorMode][name] || color[name]
              ),
              borderRadius: '4px',
              margin: '0 var(--space-h)',
              border: '1px solid rgba(var(--ts-color-border), var(--ts-color-border-alpha))'
            }}
          />


        </HStack>
      </Container>
    </Label>
  )
}

export default InputColor









