import React, { useEffect, useState, useContext, useRef } from "react";
import { Body, Button, CodeSnippet, Container, Divider, Footnote, Headline, HStack, Icon, Input, Label, Media, Section, Space, Subhead, Switch, ThemeableProvider, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";
import { useContextValue } from "../../../AppContext";

function ComponentSnippet({ thumbnail = false }) {
  const { context, setContext } = useContextValue();

  const [systemId, setSystemId] = useState(null);
  const iframeRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [svgPath, setSvgPath] = useState('../icons.svg')

  useEffect(() => {
    setSystemId(context.selectedSystem)
  }, [context.selectedSystem])

  const Technical = (
    <>
      <TextInput
        label="System Id"
        prop={systemId}
        setProp={(e) => setSystemId(e.target.value)}
      />
      <TextInput
        label="Icon file path"
        placeholder="../icons.svg"
        prop={svgPath} setProp={(e) => setSvgPath(e.target.value)} />
    </>
  );

  const SnippetComponent = (
    <CodeSnippet language="jsx" code={`
<ThemeableProvider 
  systemId="${systemId}" 
  svgPath="${svgPath}"
>
  <Button variant='primary' text1='Action' />
</ThemeableProvider>
    `} />
  );

  return (
    <>
      {thumbnail ? (
        <CodeSnippet code={`
<ThemeableProvider>
</ThemeableProvider>
`} language='jsx' />
      ) : (
        <TemplateVertical
          component={SnippetComponent}
          controls={[
            {
              name: 'Technical',
              controls: Technical,
            },
          ]}
        />
      )}
    </>
  );
}

export default ComponentSnippet;
