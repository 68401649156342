import React, { useState } from "react";
import { Body, Button, Container, Divider, Footnote, Headline, HStack, Input, Section, Space, Subhead, Title3, VStack } from "@themeable/react-components";
import { ChipRow, PropCallout, Selector, SwitchRow, TemplateHorizontal, TemplateVertical, TextInput } from "../components";
import { toCamel } from "../../Utilities/UtilityFunctions.js";

function ComponentSnippet({ thumbnail = false }) {
  const [variant, setVariant] = useState('Content');


  const Design = (
    <>
      <Selector
        label='Variant'
        textColor={null}
        prop={variant} setProp={setVariant}
        options={['Content', 'Grow', 'New Section', 'None']} />
    </>
  );

  const Content = (
    <>
      {/* <SwitchRow */}
      {/*   label='Show Demo Content' */}
      {/*   textColor={null} */}
      {/*   prop={showDemo} setProp={setShowDemo} */}
      {/*   showDivider={false} */}
      {/* /> */}
      {/* {showDemo && */}
      {/*   <> */}
      {/*     <Divider /> */}
      {/*     <HStack> */}
      {/*       <TextInput */}
      {/*         prop={demoTitle} */}
      {/*         setProp={(e) => setDemoTitle(e.target.value)} */}
      {/*         placeholder='Enter text' */}
      {/*         label='Example title' /> */}
      {/*       <TextInput */}
      {/*         prop={demoBody} */}
      {/*         setProp={(e) => setDemoBody(e.target.value)} */}
      {/*         placeholder='Enter text' */}
      {/*         label='Example body' /> */}
      {/*     </HStack> */}
      {/*     <Divider /> */}
      {/*     <SwitchRow */}
      {/*       textColor={null} */}
      {/*       label='Show Input' */}
      {/*       showDivider={false} */}
      {/*       prop={input} setProp={setInput} /> */}
      {/*     <Divider /> */}
      {/*     <SwitchRow */}
      {/*       textColor={null} */}
      {/*       label='Show Button' */}
      {/*       showDivider={false} */}
      {/*       prop={button} setProp={setButton} /> */}
      {/*   </> */}
      {/* } */}
    </>
  );


  // const Technical = (
  //   <>
  //     <ChipRow
  //       label='Interactive'
  //       prop={fun}
  //       setProp={setFun}
  //       options={["Auto", "True", "False"]}
  //     />
  //     {fun === 'link' &&
  //       <Selector
  //         label='Link Behavior'
  //         prop={target}
  //         setProp={setTarget}
  //         options={["Default", "_blank", "_top", "_parent"]}
  //       />
  //     }
  //     <Divider />
  //     <Space.Content />
  //     <Subhead> In addition to these props, we also offer: </Subhead>
  //     <Space.Content />
  //     <HStack wrap>
  //       <PropCallout propName='className' propType='string' />
  //       <PropCallout propName='key' propType='number' />
  //     </HStack>
  //   </>
  // );

  const Component = (
    <>
      <VStack width="hug" height="fill" align="middle group">
        <Body>Text</Body>
        {variant === 'Content' ? <Space.Content /> : null}
        {variant === 'Grow' ? <Space.Grow /> : null}
        {variant === 'New Section' ? <Space.NewSection /> : null}
        <Body>Text</Body>
      </VStack>
    </>
  );

  return (
    <>
      {thumbnail ? Component :
        <TemplateVertical
          component={Component}
          controls={[
            {
              name: 'Design',
              controls: Design
            },
          ]}
        />
      }
    </>
  );
}

export default ComponentSnippet;

