import { Body, Button, Caption1, Cell, CodeSnippet, Container, Footnote, Headline, HStack, Input, Media, RichText, Section, Sheet, Space, Subhead, Tab, Title1, Title2, Title3, VStack } from "@themeable/react-components";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContextValue } from "../../../AppContext";
import { collection, doc, updateDoc, Timestamp, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from '../../../services/firebase'
import { ComponentsNav } from '../ComponentsNav'
import { CalculateChanges, SaveData } from "../../Utilities/UtilityFunctions";
import { getRelativeTime } from "../../Utilities/getRelativeTime";
import { v4 as uuidv4 } from 'uuid';
import './styles.css'
import { AddBlock } from "./AddBlock";
import { ImageSettingsButton } from "./ImageSettingsButton";

// Snippets
import ButtonSnippet from "../componentSnippets/Button";
import InputSnippet from "../componentSnippets/Input";
import TabSnippet from "../componentSnippets/Tab";
import ContainerSnippet from "../componentSnippets/Container";
import SectionSnippet from "../componentSnippets/Section";
import IconSnippet from "../componentSnippets/Icon";
import MediaSnippet from "../componentSnippets/Media";
import TextSnippet from "../componentSnippets/Text";
import SwitchSnippet from "../componentSnippets/Switch";
import StackSnippet from "../componentSnippets/Stack";
import SpaceSnippet from '../componentSnippets/Space'
import AccordionSnippet from '../componentSnippets/Accordion'
import LabelSnippet from '../componentSnippets/Label'
import ThemeableProviderSnippet from '../componentSnippets/ThemeableProvider'
import { handlePaymentCheckout } from "../../Home/Overview";



// Later...
// import SheetSnippet from "../componentSnippets/Sheet";

function ComponentDoc({ componentList }) {
  const navigate = useNavigate();
  const [overviewUpdate, setOverviewUpdate] = useState();
  const overviewFieldRef = useRef();
  const [saveTimestamp, setSaveTimestamp] = useState();
  const [docData, setDocData] = useState(false);
  const [bodyUpdate, setBodyUpdate] = useState(); // Array to hold RichText and other body elements
  const { token } = useParams();
  const { context } = useContextValue();
  const [notPremiumModal, setNotPremiumModal] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);

  // Load document data
  const loadDocData = async (name) => {
    try {
      const systemDocRef = doc(db, 'systems', context.selectedSystem);
      const componentsCollectionRef = collection(systemDocRef, 'components');
      const componentQuery = query(componentsCollectionRef, where('name', '==', name));
      const querySnapshot = await getDocs(componentQuery);

      if (!querySnapshot.empty) {
        const componentDoc = querySnapshot.docs[0].data();
        setDocData(componentDoc);
        setOverviewUpdate(undefined)

        // Set bodyUpdate to match the loaded body
        setBodyUpdate(componentDoc.body || []);
      } else {
        console.warn("No component document found.");
        setDocData(null);
      }
    } catch (err) {
      console.error("Error loading component document:", err);
      // setError(err);
    } finally {
    }
  };


  // Load data on component mount
  useEffect(() => {
    const params = token.split('&');
    params.forEach((param) => {
      const [key, value] = param.split('=');
      if (key === 'component') {
        loadDocData(value);
      }
    });
  }, [token]);


  const handleDone = async (updatedBody) => {
    if (context?.user?.subscription === 'premium') {

      // Yay! is premium and a good person!
      const finalBody = updatedBody || bodyUpdate;
      setSaveTimestamp(Timestamp.now());
      try {
        const systemDocRef = doc(db, "systems", context.selectedSystem);
        const componentsCollectionRef = collection(systemDocRef, "components");

        // Directly reference the document using the id from docData
        const componentDocRef = doc(componentsCollectionRef, docData.id);

        // Update the document
        await updateDoc(componentDocRef, {
          overview: overviewUpdate || docData.overview || "",
          body: finalBody || docData.body,
          lastModified: Timestamp.now(),
        });

        // Update the system's lastModified timestamp
        await updateDoc(systemDocRef, {
          lastModified: Timestamp.now(),
        });
      } catch (error) {
        console.error("Error saving component:", error);
        throw error;
      }

    } else {
      // is not premium....
      setNotPremiumModal(true)
    }
  };

  // Update the RichText content in `bodyUpdate`
  const handleRichTextUpdate = (index, newData) => {
    setBodyUpdate((prevState) => {
      const updatedBody = [...prevState];
      updatedBody[index] = { ...updatedBody[index], data: newData };
      return updatedBody;
    });
  };

  const insertBlock = (index, type, position = "below", blockData) => {
    // Determine the default data based on the type
    let data;
    if (type === "RichText") {
      data = [
        {
          blockStyle: "body",
          text: [
            {
              weight: "",
              characters: "",
              color: "",
            },
          ],
        },
      ];
    } else if (type === "Image") {
      data = {
        url: blockData.url,
        caption: blockData.caption,
      };
    }
    const newElement = {
      id: uuidv4(), // Generate a unique ID
      type,
      data,
    };

    setBodyUpdate((prevState) => {
      const updatedState = [...prevState];
      const insertIndex = position === "above" ? index : index + 1; // Determine where to insert
      updatedState.splice(insertIndex, 0, newElement); // Insert at the calculated index
      handleDone(updatedState)
      return updatedState;
    });

  };

  const deleteBlock = (indexToDelete) => {
    setBodyUpdate((prevState) => {
      const updatedState = prevState.filter((_, index) => index !== indexToDelete);
      handleDone(updatedState);
      return updatedState;
    });
  };

  // Render content
  if (docData && bodyUpdate) {
    return (
      <>
        <Button
          small
          width="hug"
          variant="tertiary"
          text1="Components"
          icon1="ChevronLeft"
          onClick={() => navigate(`../systemid=${context.selectedSystem}&page=components`)}
        />

        <HStack>
          <Title1>{docData?.name}</Title1>
          {saveTimestamp && (
            <Footnote align="right" color="text-secondary">
              Synced updates {getRelativeTime(saveTimestamp.seconds)}
            </Footnote>
          )}
        </HStack>
        <Space.NewSection />
        <HStack className="">
          <ComponentsNav componentList={componentList} />
          <Space.NewSection />
          <VStack>
            <div id="overview" className="h-stack width-fill">
              <Container variant="blank">
                <Input
                  ref={overviewFieldRef}
                  onBlur={() => handleDone()}
                  multiLine
                  value={overviewUpdate ?? docData.overview}
                  onChange={(e) => setOverviewUpdate(e.target.value)}
                />
              </Container>
            </div>

            {docData.name === 'Button' && <ButtonSnippet />}
            {docData.name === 'Input' && <InputSnippet />}
            {docData.name === 'Tab' && <TabSnippet />}
            {docData.name === 'Container' && <ContainerSnippet />}
            {docData.name === 'Section' && <SectionSnippet />}
            {docData.name === 'Icon' && <IconSnippet />}
            {docData.name === 'Media' && <MediaSnippet />}
            {docData.name === 'Text' && <TextSnippet />}
            {docData.name === 'Switch' && <SwitchSnippet />}
            {docData.name === 'Stack' && <StackSnippet />}
            {docData.name === 'Sheet' && <SwitchSnippet />}
            {docData.name === 'Space' && <SpaceSnippet />}
            {docData.name === 'Accordion' && <AccordionSnippet />}
            {docData.name === 'Label' && <LabelSnippet />}
            {docData.name === 'Provider' && <ThemeableProviderSnippet />}


            <AddBlock
              insertBlock={insertBlock}
              index={-1}
              position='below' />
            {bodyUpdate.map((element, index) => {
              if (element.type === "RichText") {
                return (
                  <>
                    <VStack key={element.id} className='block'> {/* Use unique ID as key */}
                      <VStack
                        align='group'
                        className="block-controls"
                      >
                        <Button
                          onClick={() => deleteBlock(index)}
                          small
                          icon1="Trash"
                          variant="quaternary"
                          width="hug"
                        />
                      </VStack>
                      <Container
                        variant="blank"
                        style={{
                          marginTop: '0px',
                          marginBottom: '0px'
                        }}>
                        <RichText
                          initialState={element.data}
                          outputRaw={(newData) => {
                            handleRichTextUpdate(index, newData);
                          }}
                          onBlur={() => handleDone()}
                        />
                      </Container>
                    </VStack>
                    <AddBlock
                      insertBlock={insertBlock}
                      position='below'
                      index={index}
                      element={element}
                    />
                  </>
                );
              } else if (element.type === "Image") {
                return (
                  <VStack key={`image-${index}`} className='block'>
                    <ImageSettingsButton
                      deleteBlock={deleteBlock}
                      handleDone={handleDone}
                      setBodyUpdate={setBodyUpdate}
                      element={element}
                      index={index}
                    />
                    <Media
                      imageSrc={element.data.url}
                      caption={element.data.caption}
                      size="fill"
                      aspectRatio=""
                    />
                    <AddBlock
                      position='below'
                      index={index}
                      insertBlock={insertBlock}
                    />
                  </VStack>
                );
              }
            })}
            <Space.NewSection />
            <Space.NewSection />
          </VStack>
        </HStack >


        <Sheet
          active={notPremiumModal}
          size='medium'
          height="hug"
          onDismiss={() => { setNotPremiumModal(false) }}
        >
          <Section>
            <HStack align='right middle' width='fill'>
              <Headline>Publish System</Headline>
              <Button icon1='Close' width='hug' variant='secondary' small onClick={() => { setNotPremiumModal(false) }} />
            </HStack>
            <Space.Content />
            <Title3 color='primary'>Time to publish your<br /> documentation 📖📕</Title3>
            <Space.Content />
            <Body>To get custom documentation and live token updates to your components, you must first subscribe. Want to try it out for a month? Use this promo code at checkout:</Body>
            <Space.NewSection />
            <Container variant='outline'>
              <Title3 color='accent' align='center'>1MONTH</Title3>
            </Container>
            <Space.NewSection />
            <HStack>
              <Button text1='Learn more' variant='secondary' url='https://getthemeable.com/' target='_blank' />
              <Button
                text1={'Try Themeable 🎉'}
                loading={loadingStripe}
                icon2='ArrowRight'
                variant='primary'
                onClick={() => {
                  handlePaymentCheckout('price_1R1DBxHUEMXMwifTyyaQvkxD');
                  setLoadingStripe(true);
                }}
              />
            </HStack>
          </Section>
        </Sheet>
      </>
    );
  } else {
    return <Title3>Loading...</Title3>;
  }
} export default ComponentDoc
