import { Body, Button, Caption1, CodeSnippet, Container, Footnote, Headline, HStack, Section, Space, Subhead, Tab, Title1, Title2, Title3, VStack } from "@themeable/react-components";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContextValue } from "../../AppContext";
import ComponentDoc from "./ComponentDoc";
import Overview from "./Overview";
import { db } from "../../services/firebase";
import { getDocs, collection, doc, query } from "firebase/firestore";

function Components() {

  const [componentName, setComponentName] = useState();
  const { context } = useContextValue();
  const { token } = useParams();
  const [components, setComponents] = useState();

  useEffect(() => {
    if (token) {
      const params = new URLSearchParams(token);
      if (params.has('component')) {
        setComponentName(decodeURIComponent(params.get('component')))
      } else {
        setComponentName();
      }
    }
  }, [token]);

  const getComponentList = async () => {
    try {
      const systemDocRef = doc(db, 'systems', context.selectedSystem);
      const componentsCollectionRef = collection(systemDocRef, 'components');
      const querySnapshot = await getDocs(componentsCollectionRef);

      // Extract only the 'name' field from each document
      const firebaseComponents = querySnapshot.docs.map(doc => doc.data());

      // Sort the components alphabetically by the 'name' field
      const sortedComponents = firebaseComponents.sort((a, b) => {
        if (a.name < b.name) return -1; // Sort in ascending order
        if (a.name > b.name) return 1;
        return 0;
      });

      setComponents(sortedComponents);

    } catch (err) {
      console.error("Error loading component names:", err);
    } finally {
    }
  };

  useEffect(() => {
    getComponentList()
  }, [])

  useEffect(() => {
    // setComponentData(components.find((n) => n.name.toLowerCase() === componentName))
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [componentName]);

  return <>
    <Section>
      {componentName ? (
        <>
          <ComponentDoc
            componentList={components}
            componentName={componentName} />
        </>
      ) : (
        <>
          <Subhead color="notice">Edit Documentation</Subhead>
          <Title1>Components</Title1>
          <Space.Content />
          {!componentName &&
            <Overview
              components={components}
              setComponentName={setComponentName} />}
        </>
      )}
    </Section>
  </>
}

export { Components }
