import React, { useState, useEffect, useContext } from 'react';
import { useContextValue } from '../../AppContext';
import { Section, HStack, VStack, Headline, Title1, Title2, Title3, Button, Space, Caption1, Caption2, Label, Container, Body, Sheet, Divider, Input, Footnote, Switch, Subhead, ThemeableProvider, CodeSnippet } from '@themeable/react-components';
import { doc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db, auth } from '../../services/firebase';
import { CreateNewSystem, DeleteSystem, PublishUpdate, timestampToDate } from '../Utilities/UtilityFunctions';
import { handleCheckout } from '../../services/stripe';
import { useNavigate } from 'react-router-dom';

const handlePaymentCheckout = async (priceId) => {
  const error = await handleCheckout(priceId);
  if (error) {
    alert(error);
  }
}

function Overview() {

  const [renameModal, setRenameModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { context, setContext, cSpace, cColor, cText, cRadius, cSize } = useContextValue();
  const [publishState, setPublishState] = useState('');
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const navigate = useNavigate();

  const [newSystemName, setNewSystemName] = useState('');
  const [updateSystemButton, setUpdateSystemButton] = useState(false);

  useEffect(() => {
    if (publishState === 'published') { window.location.reload() }
  }, [publishState])


  const renameSystem = () => {
    setNewSystemName(context?.systemList[context?.selectedSystem].name)
    setUpdateSystemButton(false);
    setErrorMessage('');
    setRenameModal(true);
  }

  const [errorMessage, setErrorMessage] = useState('');

  const [deleteSystemButton, setDeleteSystemButton] = useState(false)
  const handleDeleteSystem = (input) => {

    if (input === context.systemList[context.selectedSystem].name) {
      setDeleteSystemButton(true)
    } else {
      setDeleteSystemButton(false)
    }

  }



  const sendNewSystemName = async () => {
    const regex = /^[a-zA-Z0-9_()-\s]+$/;
    if (!newSystemName.match(regex)) {
      setErrorMessage("System name can only contain letters, numbers, parenthesis, hyphens, or underscores.");
      return; // cancel rest of function
    }

    try {
      // Update the name field of the document in the "systems" collection
      const systemDocRef = doc(db, 'systems', context.selectedSystem);
      await updateDoc(systemDocRef, {
        name: newSystemName,
        lastModified: Timestamp.now()  // Use Timestamp.now() for lastModified field
      });

      // Update the subscribed systems array in the user's document
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, {
        'systems.subscribed': arrayUnion(context.selectedSystem),
      });

      window.location.reload();

    } catch (error) {
      console.error('Error updating system name:', error);
      // Handle the error accordingly
      throw error; // Propagate the error further if needed
    }
  };

  if (context?.systemList[context?.selectedSystem]?.name === undefined) {
    return null
  }

  return (<>


    <Sheet
      active={subscriptionModal}
      size='medium'
      height="hug"
      onDismiss={() => { setSubscriptionModal(false) }}
    >
      <Section>
        <HStack align='right middle' width='fill'>
          <Headline>Publish System</Headline>
          <Button icon1='Close' width='hug' variant='secondary' small onClick={() => { setSubscriptionModal(false) }} />
        </HStack>
        <Space.Content />
        <Title3 color='primary'>Time to publish your<br /> first system 🔥🔥🔥</Title3>
        <Space.Content />
        <Body>To get the most out of Themeable, you must first subscribe. Want to try it out for a month? Use this promo code at checkout:</Body>
        <Space.NewSection />
        <Container variant='outline'>
          <Title3 color='accent' align='center'>1MONTH</Title3>
        </Container>
        <Space.NewSection />
        <HStack>
          <Button text1='Learn more' variant='secondary' url='https://getthemeable.com/' target='_blank' />
          <Button
            text1={'Try Themeable 🎉'}
            loading={loadingStripe}
            icon2='ArrowRight'
            variant='primary'
            onClick={() => {
              handlePaymentCheckout('price_1R1DBxHUEMXMwifTyyaQvkxD');
              setLoadingStripe(true);
            }}
          />
        </HStack>
      </Section>
    </Sheet>

    <Sheet
      active={renameModal}
      size='compact'
      onDismiss={() => setRenameModal(false)}
    >
      <Section>
        <HStack align='middle'>
          <Headline width='fill' align='right'>Rename System</Headline>
          <Button
            icon1='Close'
            width='hug'
            small
            onClick={() => { setRenameModal(false) }}
          />
        </HStack>

        <Label>
          <Container variant='fill'>
            <Input
              value={newSystemName}
              onChange={(e) => { setNewSystemName(e.target.value); setUpdateSystemButton(true); }}
            />
          </Container>
          <Footnote color='negative'>{errorMessage}</Footnote>
        </Label>
        <Space.Content />


        {updateSystemButton && (
          <>
            <Button text1='Update' variant='primary' onClick={() => { sendNewSystemName() }} />
          </>
        )}

      </Section>
    </Sheet>




    <Section>
      <VStack>
        <Headline color='text-secondarry'>
          {context.systemList?.length > 0 ? ('') : (<>Welcome back, {context?.user?.name} 👋 </>)}
        </Headline>
        <HStack align='middle left group' wrap>
          <Title1 width='hug'>
            {context?.systemList[context?.selectedSystem]?.name}
          </Title1>
          <Button
            small
            width='hug'
            text1='Rename'
            onClick={() => { renameSystem() }}
          />
        </HStack>
      </VStack>

      {/* Future "push update here???" */}



      <Container style={{ height: '250px', overflow: 'hidden' }} variant='outline'>
        <div className='alt-theme' style={{ background: 'rgb(var(--ts-color-background-flat))', position: 'relative', height: '250px', height: '500px' }}>
          <div style={{ background: '', width: '100%', minWidth: '1420px', left: '0%', top: '0%', position: 'absolute', transform: 'rotate(-10deg) translate(-0%, -30%) scale(100%)' }}>
            <HStack align='top'>
              <VStack align='group'>
                <Button variant='primary' text1='Primary' />
                <Button variant='accent' text1='Accent' />
                <Button variant='secondary' text1='Secondary' />
                <Button variant='tertiary' text1='Tertiary' />
                <Button variant='quaternary' text1='Quaternary' />
                <Button variant='destructive' text1='Destructive' />
                <Button small variant='primary' text1='Primary' />
                <Button small variant='accent' text1='Accent' />
                <Button small variant='secondary' text1='Secondary' />
                <Button small variant='tertiary' text1='Tertiary' />
                <Button small variant='quaternary' text1='Quaternary' />
                <Button small variant='destructive' text1='Destructive' />
              </VStack>
              <VStack width='hug' align='left group'>
                <Button width='hug' small variant='primary' text1='Primary' />
                <Button width='hug' small variant='accent' text1='Accent' />
                <Button width='hug' small variant='secondary' text1='Secondary' />
                <Button width='hug' small variant='tertiary' text1='Tertiary' />
                <Button width='hug' small variant='quaternary' text1='Quaternary' />
                <Button width='hug' small variant='destructive' text1='Destructive' />
                <Button width='hug' variant='primary' text1='Primary' />
                <Button width='hug' variant='accent' text1='Accent' />
                <Button width='hug' variant='secondary' text1='Secondary' />
                <Button width='hug' variant='tertiary' text1='Tertiary' />
                <Button width='hug' variant='quaternary' text1='Quaternary' />
                <Button width='hug' variant='destructive' text1='Destructive' />
              </VStack>
              <VStack align='top group'>
                <HStack align='left'>
                  <Switch checked onChange={() => { console.log('') }} onClick={() => { console.log() }} />
                  <Switch onChange={() => { console.log('') }} onClick={() => { console.log() }} />
                </HStack>
                <Space.Content />
                <Container variant='fill'>
                  <Input placeholder='Your name' />
                </Container>
                <Space.Content />
                <Space.Content />
                <Label label='First name' inset>

                  <Space.Content />
                  <Container variant='outline'>
                    <Input placeholder='John' />
                  </Container>
                </Label>

                <Space.Content />
                <Space.Content />
                <Label label='Last name' inset>

                  <Space.Content />
                  <Container variant='default'>
                    <Input placeholder='Smith' />
                  </Container>
                </Label>
                <VStack align='ungroup' >
                  <Divider />
                  <Footnote>Footnote</Footnote>
                  <Caption1>Caption 1</Caption1>
                  <Caption2>Caption 2</Caption2>
                </VStack>

              </VStack>
              <VStack align='middle'>

                <Container variant='fill'>
                  <Title1>Title 1</Title1>
                  <Title2>Title 2</Title2>
                  <Title3>Title 3</Title3>
                </Container>
                <Container variant='default'>
                  <Headline>Headline</Headline>
                  <Body>Body</Body>
                  <Subhead>Subhead</Subhead>
                  <Footnote>Footnote</Footnote>
                  <Caption1>Caption 1</Caption1>
                  <Caption2>Caption 2</Caption2>
                </Container>
              </VStack>
            </HStack>
          </div>
        </div>
      </Container>

      <HStack>
        {context.systemList[context.selectedSystem].createdOn && (

          <Caption2 color='text-secondary' width='hug'>
            Created: <span style={{ color: 'rgba(var(--color-text-primary))' }}>{context.systemList[context.selectedSystem] && (timestampToDate(context.systemList[context.selectedSystem].createdOn))}</span>
          </Caption2>
        )}
        <Caption2 color='text-secondary'>
          Last updated: <span style={{ color: 'rgba(var(--color-text-primary))' }}>{context.systemList[context.selectedSystem] && (timestampToDate(context.systemList[context.selectedSystem].lastModified))}</span>
        </Caption2>
      </HStack>
    </Section>

    <Section>
      <Title2>Add React Components</Title2>
      <Container variant='fill'>
        <Section>
          <VStack maxWidth='600px'>
            <Body>Start by wrapping  your application with the ThemeableProvider. This gives your app access to live token updates, cached for blazingly fast delivery.</Body>
          </VStack>
          <Container variant='fill'>
            <CodeSnippet language='jsx' code={`
import { ThemeableProvider, Button } from "@themeable/react";

<ThemeableProvider systemId="${context.selectedSystem}">
    <Button variant="primary" text1="Hello World" />
</ThemeableProvider>
`} />
          </Container>
          {context.systemList[context.selectedSystem].css ? (
            <>
              <Button width='hug' text1='View All Components' icon2='ChevronRight' onClick={() => navigate(`../systemid=${context.selectedSystem}&page=components`)} />
            </>
          ) : (
            <VStack align='left'>
              <VStack maxWidth='600px'>
                <Body>By default, your system will appear grayscale. To start sending updates to your app, publish your system.</Body>
              </VStack>
              <Space.Content />
              {context.user.subscription === 'premium' ? (
                <Button
                  text1='Publish System'
                  variant='primary'
                  width='hug'
                  onClick={() => { PublishUpdate(setContext, context, cColor, cRadius, cSpace, cSize, cText, setPublishState); }}
                  loading={publishState === 'publishing' ? true : false}
                />
              ) : (<>
                <HStack group>
                  <Button
                    text1='Publish System'
                    width='hug'
                    variant='primary'
                    onClick={() => { setSubscriptionModal(true) }}
                  />
                  <Button
                    width='hug'
                    text1='View All Components'
                    icon1='React'
                    onClick={() => navigate(`../systemId=${context.selectedSystem}&page=components`)}
                  />
                </HStack>
              </>)}
            </VStack>
          )}
        </Section>
      </Container>
    </Section>

    <Section>
      <HStack align='middle left group'>
        <Title2 width='hug'>Colors</Title2>
        <Button
          variant='secondary'
          text1='Edit'
          width='hug'
          small
          onClick={() => navigate(`../systemid=${context.selectedSystem}&page=foundations&subpage=color&density=compact&colorMode=light&l3=`)}
        />
      </HStack>
      <Space.Content />

      <HStack gridExpanded={[1, 1, 1, 1, 1, 1, 1, 1]} gridMedium={[1, 1, 1, 1, 1, 1]} gridCompact={[1, 1, 1, 1]}>
        {[
          {
            name: 'Primary',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.primary,
            alpha: 1
          },
          {
            name: 'Accent',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.accent,
            alpha: 1
          },
          {
            name: 'Text Primary',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.textPrimary,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.textPrimaryAlpha
          },
          {
            name: 'Text Secondary',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.textSecondary,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.textSecondaryAlpha
          },
          {
            name: 'Background (Flat)',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.backgroundFlat,
            alpha: 1
          },
          {
            name: 'Background (Elevated)',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.backgroundElevated,
            alpha: 1
          },
          {
            name: 'Border',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.border,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.borderAlpha
          },
          {
            name: 'Fill (Container)',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.fillContainer,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.fillContainerAlpha
          },
          {
            name: 'Fill (Control)',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.fillControl,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.fillControlAlpha
          },
          {
            name: 'Read only',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.readOnly,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.readOnlyAlpha
          },
          {
            name: 'Divider',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.divider,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.dividerAlpha
          },
          {
            name: 'Positive',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.positive,
            alpha: 1
          },
          {
            name: 'Negative',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.negative,
            alpha: 1
          },
          {
            name: 'Notice',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.notice,
            alpha: 1
          },
          {
            name: 'Informative',
            value: context?.systemList[context?.selectedSystem].foundations.color.light.informative,
            alpha: 1
          },
        ].map((color, index) => (
          <>
            <div key={index}>
              <VStack align='group'>
                <div style={{ margin: 'var(--space-v-unify) var(--space-h-unify)', aspectRatio: '1 / 1', opacity: color.alpha, borderRadius: 'var(--radius-large-control)', background: color.value, border: '1px solid rgba(var(--color-border), var(--color-border-alpha))', width: '100%' }} />
                <Space.Content />
                <Space.Content />
                <Caption1 align='left'>{color.name}</Caption1>
                <Space.Content />
                <Caption2 color='text-secondary' weight='regular' align='left'>{color.value.toUpperCase()}</Caption2>
                <Space.Content />
              </VStack>
            </div>
          </>
        ))}
      </HStack>

    </Section>

    <Section>
      <HStack align='middle left group'>
        <Title2 width='hug'>Typography</Title2>
        <Button
          variant='secondary'
          width='hug'
          text1='Edit'
          small
          onClick={() => navigate(`../systemid=${context.selectedSystem}&page=foundations&subpage=text&density=compact&colorMode=light&l3=styles`)}
        />
      </HStack>

      <Space.Content />
      <Subhead>
        {context?.systemList[context?.selectedSystem]?.foundations?.text?.family?.titleName}
      </Subhead>
      <Divider />
      <table className='alt-theme'>

        <tr style={{ verticalAlign: 'baseline' }}>
          <th style={{ whiteSpace: 'nowrap' }}>
            <HStack>
              <Title1 color='text-secondary'>Title 1</Title1>
              <Space.Content />
              <Space.Content />
            </HStack>
          </th>
          <th>
            <Title1>The quick brown fox jumps over the lazy dog</Title1>
          </th>
        </tr>

        <tr style={{ verticalAlign: 'baseline' }}>
          <th>
            <Title2 color='text-secondary'>Title 2</Title2>
          </th>
          <th>
            <Title2>The quick brown fox jumps over the lazy dog</Title2>
          </th>
        </tr>

        <tr style={{ verticalAlign: 'baseline' }}>
          <th>
            <Title3 color='text-secondary'>Title 3</Title3>
          </th>
          <th>
            <Title3>The quick brown fox jumps over the lazy dog</Title3>
          </th>
        </tr>
      </table>
      <Space.NewSection />
      <Subhead>
        {context.systemList[context.selectedSystem]?.foundations?.text?.family?.name}
      </Subhead>
      <Divider />
      <table className='alt-theme'>
        <tr style={{ verticalAlign: 'baseline' }}>
          <th>
            <Headline color='text-secondary'>Headline</Headline>
          </th>
          <th>
            <Headline>The quick brown fox jumps over the lazy dog</Headline>
          </th>
        </tr>

        <tr style={{ verticalAlign: 'baseline' }}>
          <th>
            <Body color='text-secondary'>Body</Body>
          </th>
          <th>
            <Body>The quick brown fox jumps over the lazy dog</Body>
          </th>
        </tr>

        <tr style={{ verticalAlign: 'baseline' }}>
          <th>
            <Subhead color='text-secondary'>Subhead</Subhead>
          </th>
          <th>
            <Subhead>The quick brown fox jumps over the lazy dog</Subhead>
          </th>
        </tr>

        <tr style={{ verticalAlign: 'baseline' }}>
          <th>
            <Footnote color='text-secondary'>Footnote</Footnote>
          </th>
          <th>
            <Footnote>The quick brown fox jumps over the lazy dog</Footnote>
          </th>
        </tr>

        <tr style={{ verticalAlign: 'baseline' }}>
          <th>
            <Caption1 color='text-secondary'>Caption 1</Caption1>
          </th>
          <th>
            <Caption1>The quick brown fox jumps over the lazy dog</Caption1>
          </th>
        </tr>

        <tr style={{ verticalAlign: 'baseline' }}>
          <th>
            <Caption2 color='text-secondary'>Caption 2</Caption2>
          </th>
          <th>
            <Caption2>The quick brown fox jumps over the lazy dog</Caption2>
          </th>
        </tr>

      </table>
    </Section>

    <Section>
      <VStack align='left'>
        <Title2>System Options</Title2>
        <Space.Content />
        <Button width='hug' variant='destructive' text1='Delete System' onClick={() => { setDeleteModal(true) }} />
        <Button width='hug' variant='tertiary' text1='Duplicate System' onClick={() => { CreateNewSystem(context.selectedSystem, context) }} />
      </VStack>
    </Section>

    <Sheet
      active={deleteModal}
      size='medium'
      height='hug'
      onDismiss={() => setDeleteModal(false)}
    >
      <Section>
        <HStack align='right middle'>
          <Space.Grow />
          <Headline align='center' color='negative'>Delete System?</Headline>
          <HStack align='right'>
            <Button
              text1='Cancel'
              variant='quaternary'
              width='hug'
              small
              onClick={() => { setDeleteModal(false) }}
            />
          </HStack>
        </HStack>
        <Body align='center'>Are you sure you want to delete this system? This cannot be undone. <br />To confirm, type "{context.systemList[context.selectedSystem].name}" in the box below</Body>
        <Label>
          <Container variant='fill'>
            <Input
              onChange={(e) => { handleDeleteSystem(e.target.value); }}
              placeholder={context.systemList[context.selectedSystem].name}
              align='center'
            />
          </Container>
        </Label>
        <Space.Content />


        {deleteSystemButton && (
          <>
            <Button text1='Confirm Delete System' variant='destructive' onClick={() => { DeleteSystem(context) }} />
          </>
        )}

      </Section>
    </Sheet>


  </>)
}

export default Overview;
export { handlePaymentCheckout };
